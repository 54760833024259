import React from 'react';
import moment from 'moment-timezone';
import { useLoadResourceOptions } from '../hooks/apphooks'
import { FeedPostSchema } from '../data/types';
import { ReactComponent as PostLike } from '../icons/PostLike.svg';
import { ReactComponent as PostComment } from '../icons/PostComment.svg';
import { ReactComponent as PostShare } from '../icons/PostShare.svg';

type FeedPostCardProps = {
  postData: FeedPostSchema;
};

const FeedPostCard: React.FC<FeedPostCardProps> = ({ postData }) => {
  const { allOptions } = useLoadResourceOptions();
  const matchingResource = allOptions?.find(option => option.path === postData.resource_type);
  const resourceName = matchingResource?.name || 'Unknown Resource Type';

  return (
    <div className="flex flex-col my-4">
      <div className="flex flex-col w-4/5 h-[250px] bg-white hover:bg-[#fafafa] rounded-xl p-4 cursor-pointer relative">
        
        
        <div className="flex items-center">
          <div className="bg-gray-400 rounded-full w-12 h-12" />
          <div className="ml-4">
            <h1 className="text-black font-raleway">{postData?.username || 'Cant find user'}</h1>
            <h3 className="text-[#4D4D4D] text-sm font-raleway">
              {postData?.last_updated
                ? moment.utc(postData.last_updated).tz('America/New_York').startOf('second').fromNow()
                : 'Can’t find last updated'}
            </h3>
          </div>
        </div>

        
        <div className="bg-[#E6EBED] w-[250px] h-[40px] flex items-center justify-center rounded-md font-raleway font-semibold text-[#013847] text-center text-xs absolute top-5 right-4">
          {resourceName}
        </div>

       
        <p className="mt-4 p-2">{postData?.content || 'Cant find content'}</p>

        
        <div className="flex justify-around items-center mt-12 border-t pt-7">
          <div className="flex items-center ml-4">
            <PostLike className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Like (0)</p>
          </div>

          <div className="flex h-[30px] w-[1px] bg-[#E9E9E9]" />

          <div className="flex items-center">
            <PostComment className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Comment (0)</p>
          </div>

          <div className="flex h-[30px] w-[1px] bg-[#E9E9E9]" />

          <div className="flex items-center">
            <PostShare className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Share (0)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedPostCard;

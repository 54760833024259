// import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { capitalizeString } from '../../utils/helpers';
import { useCompleteSignIn } from '../../hooks/authhooks';
import { FocusGroupFormData, AvailabilityOption, MeetingStyleOption, SessionLengthOption } from '../../data/types';
import { useFocusGroup, useFocusGroupData } from '../../hooks/apphooks';
import { FocusGroupSuccessModal } from '../../molecules/AppModal';
import { USStatesEnum } from '../../utils/enums';
// type RouteParams = { 
//   communityId: string
// };

type FocusGroupProps = {
}

const FocusGroup: React.FC<FocusGroupProps> = () => {
    // const { communityId } = useParams<RouteParams>()
    // const [searchParams] = useSearchParams();
    // // const communityQuery = searchParams.get('community');
    return (
      <>
        <FocusGroupForm />
      </>
    )
}
export default FocusGroup;

type FocusGroupFormProps = {
    communityId?: string
}

export const FocusGroupForm: React.FC<FocusGroupFormProps> = ({ communityId }) => {
    const { user } = useCompleteSignIn();
    const { submitFocusGroup, isLoading: isSubmitting, isSuccess, error } = useFocusGroup(communityId);
    const { focusGroupData, isLoading: isLoadingData } = useFocusGroupData(user?.username || '', communityId);
    
    const [formData, setFormData] = useState<FocusGroupFormData>({
        first_name: user?.first_name ? user.first_name : '',
        last_name: user?.last_name ? user.last_name : '',
        email: user?.email ? user.email : '',
        availability: [],
        meeting_style: '',
        session_length: 0,
        about: '',
        ideas: '',
        terms_accepted: false
    });

    // Update form data when focus group data is loaded
    useEffect(() => {
        if (focusGroupData) {
            setFormData(prevData => ({
                ...prevData,
                ...focusGroupData,
                first_name: focusGroupData.first_name || user?.first_name || '',
                last_name: focusGroupData.last_name || user?.last_name || '',
                email: focusGroupData.email || user?.email || '',
            }));
            setTermsAccepted(focusGroupData.terms_accepted || false);
        }
    }, [focusGroupData, user]);

    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(isSuccess);
    const [formError, setFormError] = useState<string>(error?.message || "");
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        // Validate required fields
        if (!formData.first_name || !formData.last_name || !formData.email || 
            formData.availability.length === 0 || !formData.meeting_style || 
            formData.session_length === 0 || formData.about === '' || formData.ideas === '') {
            setFormError("Please fill out all fields");
            return;
        }

        if (!termsAccepted) {
            setFormError("Please accept the terms and conditions");
            return;
        }

        try {
            const success = await submitFocusGroup(formData);
            
            if (success) {
                setFormError("");
                setIsSuccessOpen(true);
            } else {
                setFormError("Failed to submit form. Please try again.");
            }
        } catch (error) {
            setFormError("An error occurred while submitting the form. Please try again.");
            console.error('Form submission error:', error);
        }
    };

    const handleAvailabilityChange = (value: string) => {
        setFormData(prev => {
            const currentAvailability = prev.availability;
            if (currentAvailability.includes(value)) {
                return {
                    ...prev,
                    availability: currentAvailability.filter(item => item !== value)
                };
            } else {
                return {
                    ...prev,
                    availability: [...currentAvailability, value]
                };
            }
        });
    };

    const [showAvailabilityDropdown, setShowAvailabilityDropdown] = useState<boolean>(false);
    const [showMeetingStyleDropdown, setShowMeetingStyleDropdown] = useState<boolean>(false);
    const [showSessionLengthDropdown, setShowSessionLengthDropdown] = useState<boolean>(false);

    const availabilityOptions: AvailabilityOption[] = [
        { value: 'weekday-morning', label: 'Weekdays, Morning (9 AM - 12 PM)' },
        { value: 'weekday-afternoon', label: 'Weekdays, Afternoon (12 PM - 3 PM)' },
        { value: 'weekday-evening', label: 'Weekdays, Evening (5 PM - 8 PM)' },
        { value: 'weekend-morning', label: 'Weekends, Morning (9 AM - 12 PM)' },
        { value: 'weekend-afternoon', label: 'Weekends, Afternoon (12 PM - 3 PM)' },
        { value: 'weekend-evening', label: 'Weekends, Evening (5 PM - 8 PM)' }
    ];

    const meetingStyleOptions: MeetingStyleOption[] = [
        { value: 'inPerson', label: 'In Person' },
        { value: 'virtual', label: 'Virtual' },
        { value: 'hybrid', label: 'Hybrid' }
    ];

    const sessionLengthOptions: SessionLengthOption[] = [
        { value: 30, label: '30 minutes' },
        { value: 45, label: '45 minutes' },
        { value: 60, label: '60 minutes' }
    ];

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        setFormError("");
        document.title = `HomeCook | Join Focus Group`
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
        
    }, []);
    
    const isMobile = width < 1024;

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const accepted = e.target.checked;
        setTermsAccepted(accepted);
        setFormData(prev => ({
            ...prev,
            terms_accepted: accepted
        }));
    };

    if (isLoadingData || isSubmitting) {
        return (
            <div
                role="status"
                className="grid items-center justify-center h-screen md:p-[1%] w-full bg-back-400 pt-16 md:pt-16"
            >
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primaryDark-100"></div>
            </div>
        );
    }

    if (isMobile){
        return (
            <>
                <div className="max-w-xl mx-auto px-4 py-6 mt-0">
                    <div className="mb-6 flex flex-col w-full relative items-start mt-[180px]">
                        <h1 className="text-[20px] font-bold mb-2 font-raleway text-black">Food Business Focus Group</h1>
                        <p className="font-open-sans text-[#4D4D4D] text-[16px]">Help us make our focus groups better! Take this quick survey—if you don't have an account, we'll create one with your email.</p>
                    </div>

                    {formError && (
                        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md">
                            <p className="text-red-600 font-open-sans text-[14px]">{formError}</p>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="firstName" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                placeholder="Enter your first name"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.first_name}
                                disabled={!!user?.first_name}
                                onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="lastName" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                placeholder="Enter your last name"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.last_name}
                                disabled={!!user?.last_name}
                                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.email}
                                disabled={!!user?.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                        </div>

                        <div className="relative">
                            <label htmlFor="availability" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Your Availability</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowAvailabilityDropdown(!showAvailabilityDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.availability.length === 0 ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.availability.length === 0 
                                            ? 'Select your availability' 
                                            : `${formData.availability.length} time slot${formData.availability.length > 1 ? 's' : ''} selected`}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showAvailabilityDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showAvailabilityDropdown && (
                                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center px-4">
                                    <div className="bg-white w-full max-w-sm rounded-lg shadow-lg">
                                        <div className="p-4 border-b border-[#E9E9E9]">
                                            <div className="flex justify-between items-center">
                                                <h3 className="font-raleway font-bold text-[16px]">Select Availability</h3>
                                                <button 
                                                    onClick={() => setShowAvailabilityDropdown(false)}
                                                    className="text-gray-400 hover:text-gray-500"
                                                >
                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="py-2">
                                            {availabilityOptions.map((option) => (
                                                <div
                                                    key={option.value}
                                                    className="flex items-center px-4 py-3 hover:bg-gray-50"
                                                    onClick={() => handleAvailabilityChange(option.value)}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option.value}
                                                        checked={formData.availability.includes(option.value)}
                                                        onChange={() => handleAvailabilityChange(option.value)}
                                                        className="h-5 w-5 text-[#006666] focus:ring-[#006666] border-[#E9E9E9] rounded"
                                                        onClick={(e) => e.stopPropagation()}
                                                    />
                                                    <label
                                                        htmlFor={option.value}
                                                        className="ml-3 font-open-sans text-[16px] text-[#4D4D4D]"
                                                    >
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="p-4 border-t border-[#E9E9E9]">
                                            <button
                                                onClick={() => setShowAvailabilityDropdown(false)}
                                                className="w-full bg-[#006666] text-white py-2 px-4 rounded-md font-raleway font-bold"
                                            >
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="relative">
                            <label htmlFor="meetingStyle" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Meeting Style</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowMeetingStyleDropdown(!showMeetingStyleDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.meeting_style === '' ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.meeting_style === '' 
                                            ? 'Select your meeting style'
                                            : meetingStyleOptions.find(option => option.value === formData.meeting_style)?.label}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showMeetingStyleDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showMeetingStyleDropdown && (
                                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center px-4">
                                    <div className="bg-white w-full max-w-sm rounded-lg shadow-lg">
                                        <div className="p-4 border-b border-[#E9E9E9]">
                                            <div className="flex justify-between items-center">
                                                <h3 className="font-raleway font-bold text-[16px]">Select Meeting Style</h3>
                                                <button 
                                                    onClick={() => setShowMeetingStyleDropdown(false)}
                                                    className="text-gray-400 hover:text-gray-500"
                                                >
                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="py-2">
                                            {meetingStyleOptions.map((option) => (
                                                <div
                                                    key={option.value}
                                                    className="px-4 py-3 hover:bg-gray-50"
                                                    onClick={() => {
                                                        setFormData({...formData, meeting_style: option.value});
                                                        setShowMeetingStyleDropdown(false);
                                                    }}
                                                >
                                                    <span className="font-open-sans text-[16px] text-[#4D4D4D]">
                                                        {option.label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="relative">
                            <label htmlFor="sessionLength" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Session Length</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowSessionLengthDropdown(!showSessionLengthDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.session_length === 0 ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.session_length === 0 
                                            ? 'Choose session length'
                                            : `${formData.session_length} minutes`}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showSessionLengthDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showSessionLengthDropdown && (
                                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center px-4">
                                    <div className="bg-white w-full max-w-sm rounded-lg shadow-lg">
                                        <div className="p-4 border-b border-[#E9E9E9]">
                                            <div className="flex justify-between items-center">
                                                <h3 className="font-raleway font-bold text-[16px]">Select Session Length</h3>
                                                <button 
                                                    onClick={() => setShowSessionLengthDropdown(false)}
                                                    className="text-gray-400 hover:text-gray-500"
                                                >
                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="py-2">
                                            {sessionLengthOptions.map((option) => (
                                                <div
                                                    key={option.value}
                                                    className="px-4 py-3 hover:bg-gray-50"
                                                    onClick={() => {
                                                        setFormData({...formData, session_length: option.value});
                                                        setShowSessionLengthDropdown(false);
                                                    }}
                                                >
                                                    <span className="font-open-sans text-[16px] text-[#4D4D4D]">
                                                        {option.label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            <label htmlFor="about" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">A little about you</label>
                            <textarea
                                id="about"
                                placeholder="Tell us about your business, goals, and challenges."
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] min-h-[100px] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.about}
                                onChange={(e) => setFormData({...formData, about: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="ideas" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Your Ideas</label>
                            <textarea
                                id="ideas"
                                placeholder="Share your ideas or special requests!"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] min-h-[100px] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.ideas}
                                onChange={(e) => setFormData({...formData, ideas: e.target.value})}
                            />
                        </div>

                        <div className="border border-[#E9E9E9] rounded-md p-4 space-y-4">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    checked={termsAccepted}
                                    onChange={handleTermsChange}
                                    className="h-5 w-5 text-[#006666] focus:ring-[#006666] border-[#E9E9E9] rounded"
                                />
                                <label htmlFor="terms" className="text-[14px] text-[#4D4D4D] font-open-sans">
                                    I agree to the{' '}
                                    <a href="/terms" className="text-[#006666] hover:underline">terms</a>
                                    {' '}and{' '}
                                    <a href="/conditions" className="text-[#006666] hover:underline">conditions</a>
                                </label>
                            </div>
                            <button
                                type="submit"
                                disabled={!termsAccepted}
                                className={`w-full font-raleway font-bold text-[16px] h-[50px] border-0 ${
                                    termsAccepted 
                                    ? 'bg-primaryDark-100 hover:bg-[#005555]' 
                                    : 'bg-gray-300 cursor-not-allowed'
                                } text-white py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#006666] focus:ring-offset-2`}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                <FocusGroupSuccessModal isOpen={isSuccessOpen} communityId={communityId} closeAppModal={() => setIsSuccessOpen(false)} />
            </>
        )
    } 
    else {
        return (
            <>
                <div className="max-w-xl mx-auto p-6 mt-0">
                    <div className="mb-8 mt-[52px]">
                        <h1 className="text-[20px] font-bold mb-2 font-raleway text-black">Focus Group <span className="text-primaryDark-400 font-semibold text-[16px]">{ user?.state ? USStatesEnum[user?.state]?.fullName : 'United States'}</span></h1>
                        <p className="font-open-sans text-[#4D4D4D] text-[16px]">Help us serve you better! Take this quick survey—if you don't have an account, we'll create one with your email.</p>
                    </div>

                    {formError && (
                        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md">
                            <p className="text-red-600 font-open-sans text-[14px]">{formError}</p>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="firstName" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                placeholder="Enter your first name"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.first_name}
                                disabled={!!user?.first_name}
                                onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="lastName" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                placeholder="Enter your last name"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.last_name}
                                disabled={!!user?.last_name}
                                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.email}
                                disabled={!!user?.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                        </div>

                        <div className="relative">
                            <label htmlFor="availability" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Your Availability</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowAvailabilityDropdown(!showAvailabilityDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.availability.length === 0 ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.availability.length === 0 
                                            ? 'Select your availability' 
                                            : `${formData.availability.length} time slot${formData.availability.length > 1 ? 's' : ''} selected`}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showAvailabilityDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showAvailabilityDropdown && (
                                <div className="absolute z-10 w-full mt-1 bg-white border border-[#E9E9E9] rounded-md shadow-lg">
                                    {availabilityOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            className="flex items-center px-3 py-2 hover:bg-gray-50 cursor-pointer"
                                            onClick={() => handleAvailabilityChange(option.value)}
                                        >
                                            <input
                                                type="checkbox"
                                                id={option.value}
                                                checked={formData.availability.includes(option.value)}
                                                onChange={() => handleAvailabilityChange(option.value)}
                                                className="h-4 w-4 text-[#006666] focus:ring-[#006666] border-[#E9E9E9] rounded cursor-pointer"
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                            <label
                                                htmlFor={option.value}
                                                className="ml-2 font-open-sans text-[16px] text-[#4D4D4D] cursor-pointer"
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="relative">
                            <label htmlFor="meetingStyle" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Meeting Style</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowMeetingStyleDropdown(!showMeetingStyleDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.meeting_style === '' ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.meeting_style === '' 
                                            ? 'Select your meeting style'
                                            : meetingStyleOptions.find(option => option.value === formData.meeting_style)?.label}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showMeetingStyleDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showMeetingStyleDropdown && (
                                <div className="absolute z-10 w-full mt-1 bg-white border border-[#E9E9E9] rounded-md shadow-lg">
                                    {meetingStyleOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            className="px-3 py-2 hover:bg-gray-50 cursor-pointer font-open-sans text-[16px] text-[#4D4D4D]"
                                            onClick={() => {
                                                setFormData({...formData, meeting_style: option.value});
                                                setShowMeetingStyleDropdown(false);
                                            }}
                                        >
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="relative">
                            <label htmlFor="sessionLength" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Session Length</label>
                            <div 
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-[#006666] cursor-pointer"
                                onClick={() => setShowSessionLengthDropdown(!showSessionLengthDropdown)}
                            >
                                <div className="flex justify-between items-center">
                                    <span className={`font-open-sans text-[16px] ${formData.session_length === 0 ? 'text-[#808080]' : 'text-black'}`}>
                                        {formData.session_length === 0 
                                            ? 'Choose session length'
                                            : `${formData.session_length} minutes`}
                                    </span>
                                    <svg className={`w-5 h-5 text-gray-400 transition-transform ${showSessionLengthDropdown ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            
                            {showSessionLengthDropdown && (
                                <div className="absolute z-10 w-full mt-1 bg-white border border-[#E9E9E9] rounded-md shadow-lg">
                                    {sessionLengthOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            className="px-3 py-2 hover:bg-gray-50 cursor-pointer font-open-sans text-[16px] text-[#4D4D4D]"
                                            onClick={() => {
                                                setFormData({...formData, session_length: option.value});
                                                setShowSessionLengthDropdown(false);
                                            }}
                                        >
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div>
                            <label htmlFor="about" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">A little about you</label>
                            <textarea
                                id="about"
                                placeholder="Our team would like to meet you and learn about your business, motivations, goals, and challenges."
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] min-h-[100px] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.about}
                                onChange={(e) => setFormData({...formData, about: e.target.value})}
                            />
                        </div>

                        <div>
                            <label htmlFor="ideas" className="font-raleway block text-[16px] font-bold text-gray-700 mb-1">Your Ideas</label>
                            <textarea
                                id="ideas"
                                placeholder="Got any awesome ideas or special requests? We're all ears!"
                                className="w-full px-3 py-2 border border-[#E9E9E9] rounded-md placeholder-[#808080] focus:outline-none focus:ring-2 focus:ring-[#006666] min-h-[100px] font-open-sans text-[16px] placeholder:font-open-sans placeholder:text-[16px]"
                                value={formData.ideas}
                                onChange={(e) => setFormData({...formData, ideas: e.target.value})}
                            />
                        </div>

                        <div className="flex justify-between space-x-2 border border-[#E9E9E9] rounded-md p-4">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    checked={termsAccepted}
                                    onChange={handleTermsChange}
                                    className="h-4 w-4 text-[#006666] focus:ring-[#006666] border-[#E9E9E9] rounded"
                                />
                                <label htmlFor="terms" className="text-[14px] text-[#4D4D4D] font-open-sans">
                                    I agree to the{' '}
                                    <a href="/terms" className="text-[#006666] hover:underline">terms</a>
                                    {' '}and{' '}
                                    <a href="/conditions" className="text-[#006666] hover:underline">conditions</a>
                                </label>
                            </div>
                            <button
                                type="submit"
                                disabled={!termsAccepted}
                                className={`font-raleway font-bold text-[16px] h-[50px] w-[120px] border-0 ${
                                    termsAccepted 
                                    ? 'bg-primaryDark-100 hover:bg-[#005555]' 
                                    : 'bg-gray-300 cursor-not-allowed'
                                } text-white py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#006666] focus:ring-offset-2`}
                            >
                                Submit
                            </button>
                        </div>

    
                    </form>
                </div>

                <FocusGroupSuccessModal isOpen={isSuccessOpen} communityId={communityId} closeAppModal={() => setIsSuccessOpen(false)} />
            </>
        );
    }
};




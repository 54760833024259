import { useState, useMemo } from 'react';

interface PaginationReturn<T> {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  paginatedItems: T[];
  itemsPerPage: number;
  startIndex: number;
  endIndex: number;
  totalItems: number;
}

export const usePagination = <T extends unknown>(items: T[], itemsPerPage: number = 6): PaginationReturn<T> => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = items.length;

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    return {
      paginatedItems: items.slice(startIndex, endIndex),
      startIndex,
      endIndex
    };
  }, [items, currentPage, itemsPerPage, totalItems]);

  return {
    currentPage,
    setCurrentPage,
    paginatedItems: paginatedData.paginatedItems,
    itemsPerPage,
    startIndex: paginatedData.startIndex,
    endIndex: paginatedData.endIndex,
    totalItems
  };
};

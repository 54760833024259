import React from 'react'
import { ReactComponent as PostExit } from "../icons/PostModalExit.svg"
import { ReactComponent as UploadImage } from "../icons/feedUploadImage.svg"

type NewFeedPostProps = {
  isOpen: boolean
  togglePostModal: () => void
  newPost: { resource_type?: string; content?: string }
  handleChangeInput: (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSubmitPost: () => void
  allOptions: { path: string; name: string }[]
};

const NewFeedPost: React.FC<NewFeedPostProps> = ({
  isOpen,
  togglePostModal,
  newPost,
  handleChangeInput,
  handleSubmitPost,
  allOptions,
}) => {
  if (!isOpen) return null
  
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg w-[478px] h-[700px] p-8 relative">
          <h2 className="text-lg font-black mb-4 font-raleway mt-[20px]">Create a Post</h2>
          <h3 className="text-sm font-light mb-2 font-open-sans text-[#333333] mt-[-10px]">Fill out the details below to create a post.</h3>

          <PostExit
            onClick={togglePostModal}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer"
          />
          
          <div>
            <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Resource Category</h3>
            <select name="resource_type" value={newPost.resource_type || ""} onChange={handleChangeInput} className="w-full h-[40px] mb-[-10px] p-2 border font-open-sans text-sm rounded-md border-[#E9E9E9]">
              <option value="" className="font-open-sans p-3 text-sm text-[#E9E9E9]">Select a resource category</option>
              {
                allOptions.map((resourceOption, i) => (
                  <option key={i} value={resourceOption.path} className="font-open-sans p-3 text-sm text-[#4D4D4D]">
                    {resourceOption.name}
                  </option>
                ))
              }
            </select>
            <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Post Description</h3>
            <textarea
              name="content"
              value={newPost.content || ""}
              placeholder="Enter your post title"
              className="w-full h-[120px] mb-[-10px] p-3 border font-open-sans text-sm rounded-md border-[#E9E9E9]"
              onChange = {handleChangeInput}
            />
            <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Post Image (Optional)</h3>
            <div className="flex space-x-0">
              <div
                className="w-1/2 h-[150px] border-2 rounded-md border-dotted border-[#E9E9E9] bg-[#f5f3f387] mb-5 flex flex-col items-center justify-center"
              >
                <UploadImage className="w-[64px] h-[64px] mt-2" />
                <h1 className="font-raleway font-semibold text-md mt-2 text-center">
                  Drag and Drop or tap to Browse
                </h1>
              </div>
              <div className="w-1/2 h-[150px] rounded-md border-dotted mb-5 flex flex-col items-start mt-2 pl-5">
                <h1 className="font-open-sans text-md mt-2 text-left">
                  No image added
                </h1>
                <button className="w-3/4 font-raleway font-black text-sm mt-2 bg-[#013847] text-white py-2 rounded">Upload Image</button>
              </div>
            </div>
            <div className="w-full bg-[#E9E9E9] h-[1px] mb-6"></div>
            <button
              type="submit"
              onClick={handleSubmitPost}
              className="w-full font-raleway font-black font-md bg-[#013847] text-white py-2 rounded"
            >

              Create Post
            </button>
          </div>
        </div>
      </div>
    )
}

export default NewFeedPost;

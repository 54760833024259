import {useEffect, useState} from 'react'
import { ReactComponent as CreatePostCircle } from "../../icons/createPostCircle.svg";
import { useFeedPosts, useCompleteSignIn, useAddPost } from '../../hooks/authhooks'
import FeedPostCard from '../../components/FeedPostCard'
import NewFeedPost from '../../components/NewFeedPost'
import { useLoadResourceOptions } from '../../hooks/apphooks'
import { FeedPostSchema } from '../../data/types';

const Feed = () => {
  const { user } = useCompleteSignIn()
  const { allOptions } = useLoadResourceOptions()
  const loggedinUsername = user && user?.username ? user.username : "";
  const { feedPosts } = useFeedPosts();
  const { addNewPost } = useAddPost(loggedinUsername);

  const [width, setWidth] = useState(window.innerWidth);
  const [isPostModalOpen, setPostModal] = useState(false);
  const [newPost, setNewPost] = useState<FeedPostSchema>({content:'', resource_type:"kitchen"});
  const [selectedResourceType, setSelectedResourceType] = useState<string>("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | Feed`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const togglePostModal = () => {
    setPostModal((prev) => !prev);
  };

  const handleSubmitPost = () => {
    addNewPost(newPost);
    setPostModal(false);
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    const { name , value } = e.target;
    setNewPost(
      prev => (
        {
          ...prev,
          [name]:value
        }
      )
    );

  }

  const filterPost = feedPosts?.filter(post => !selectedResourceType || post.resource_type === selectedResourceType);
  const sortedPosts = filterPost?.sort((a, b) => new Date(b.created_at || 0).getTime() - new Date(a.created_at || 0).getTime());
  

  const isMobile = width < 1024;

  if (isMobile){
    return (
      <>
      <div className={`w-full left-0 background-flex font-open-sans h-screen overflow-y-scroll relative top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
          <div className="flex flex-col mx-auto sm:h-screen w-1/5 bg-[#FFFFFF] rounded-xl mt-24">
            
          </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative top-0 bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-[325px] h-[600px] left-32 top-20 relative bg-white rounded-lg`}>
          <div className="flex justify-center">
          <button
            onClick={togglePostModal}
            className="w-5/6 mt-5 h-[44px] flex items-center justify-center gap-2 font-raleway rounded-md font-bold text-white bg-[#013847]"
          >
            <CreatePostCircle className="w-[20px] h-[20px]" />
            Create Post
          </button>
          </div>
          <hr className="h-px mt-5 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <h1 className="font-raleway text-[#808080] text-[16px] ml-6 mt-5 mb-2">Resource Categories</h1>
          
          <div className="flex flex-col items-center">
            <button onClick={() => setSelectedResourceType("")}
              className={`w-5/6 mt-1.5 h-[55px] pl-4 text-sm flex items-center justify-start text-left font-raleway rounded-md font-medium text-[#4D4D4D] border-2 border-[#E9E9E9] ${
                selectedResourceType === "" ? "bg-[#E6EBED]" : ""
              }`}
            >
              All
            </button>
            {allOptions.map((resourceOption) => (
                  <button
                    key={resourceOption.path}
                    onClick={() => setSelectedResourceType(resourceOption.path)}
                    className={`w-5/6 mt-1.5 h-[55px] pl-4 text-sm flex items-center justify-start text-left font-raleway rounded-md font-medium text-[#4D4D4D] border-2 border-[#E9E9E9] ${
                      selectedResourceType === resourceOption.path ? "bg-[#E6EBED]" : ""
                    }`}
                  >
                    {resourceOption.name}
                  </button>
                ))}
          </div>
        </div>

        {isPostModalOpen && (
          <NewFeedPost
            isOpen={isPostModalOpen}
            togglePostModal={togglePostModal}
            newPost={newPost}
            handleChangeInput={handleChangeInput}
            handleSubmitPost={handleSubmitPost}
            allOptions={allOptions}
          />
      )}
  

        <div className='flex flex-col w-full'>
          <div className="ml-36 mt-16"> 
          {
            (sortedPosts && sortedPosts?.length > 0) ? (
              sortedPosts.map((post, i) => (
                  <FeedPostCard key={i} postData={post} />
                ))
            ) : (
              <div className='font-open-sans text-[14px] text-[#aaa] p-4'>No posts</div>
            )
          }
          </div>

        </div>


      </div>
      </>
    )
  }
}


export default Feed;
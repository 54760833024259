import React from 'react';
import { ReactComponent as VisaCardIcon } from '../icons/visaCardIcon.svg';
import { ReactComponent as MasterCardIcon } from '../icons/masterCardIcon.svg';
import { ReactComponent as AmexCardIcon } from '../icons/amexCardIcon.svg';
import { ReactComponent as DiscoverCardIcon } from '../icons/discoverCard.svg';
import { ReactComponent as CashappIcon } from '../icons/cashappIcon.svg';
import { ReactComponent as AmazonPayIcon } from '../icons/AmazonPayIcon.svg';
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeString, formatDateString } from '../utils/helpers';
import { useAddPaySetupIntent } from '../hooks/paymenthooks';

type PayMethodComponentProps = {
  paymentMethod: any
  username: string
}

const PayMethodComponent: React.FC<PayMethodComponentProps> = ({username, paymentMethod}) => {

  const { removePaymentMethod } = useAddPaySetupIntent(username)

  const getCardIcon = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'visa':
        return <VisaCardIcon className='w-8 my-auto' />;
      case 'mastercard':
        return <MasterCardIcon className='w-8 my-auto' />;
      case 'amex':
        return <AmexCardIcon className='w-8 my-auto' />;
      case 'discover':
        return <DiscoverCardIcon className='w-8 my-auto' />
      case 'cashapp':
        return <CashappIcon className='w-8 h-8 my-auto' />
      case 'amazon_pay':
        return <AmazonPayIcon className='w-8 h-6 my-auto' />
      default:
        return <img src="/icons/unknown.svg" alt="Unknown" />;
    }
  };


  const handlePayRemoveMethod = () => {
    removePaymentMethod(paymentMethod.id)
  }

  return (
    <div 
      className='flex flex-row border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#000000] font-normal font-raleway'
    >

      {
        (paymentMethod && paymentMethod.card) ? (
        <>
          <div className='mx-4 my-auto border-[#E9E9E9] border-[1px] p-1 rounded-xl'>
            {getCardIcon(paymentMethod.card.brand)} 
          </div>
          <div className='w-1/4 my-auto'>
            {capitalizeString(paymentMethod.card.brand)}
          </div>
          <div className='w-1/4 my-auto '>
            ****{paymentMethod.card.last4 || ''}
          </div>
          <div className='w-1/4 my-auto'>
            Expires {formatDateString(paymentMethod.card.exp_month, paymentMethod.card.exp_year)}
          </div>
        </>) : (
        <>
          <div className='mx-4 my-auto border-[#E9E9E9] border-[1px] p-1 rounded-xl'>
            {getCardIcon(paymentMethod.type)} 

          </div>
          <div className='w-1/4 my-auto'>
            {capitalizeString(paymentMethod.type)}
          </div>
          <div className='w-1/4 my-auto '>
            {(paymentMethod && paymentMethod?.cashapp && paymentMethod?.cashapp?.cashtag) ? (paymentMethod?.cashapp?.cashtag || '') : ''}
          </div>
          <div className='w-1/4 my-auto'>
            {/* Expires {formatDateString(paymentMethod.card.exp_month, paymentMethod.card.exp_year)} */}
          </div>
        </>)
      }

      <div className='flex flex-row mx-4  my-auto ml-auto'>
        <div className='flex border-[1px] border-[#E9E9E9] hover:bg-slate-100 rounded-lg mr-2 h-[30px] w-[30px]'>
          <DeleteIcon className='text-[#D42620] m-auto cursor-pointer hover:text-red-800 ' onClick={handlePayRemoveMethod} /> 
        </div>
        {/* <div className='flex border-[1px] border-[#E9E9E9)] rounded-lg ml-2 h-[30px] w-[30px] hover:bg-slate-100  cursor-pointer'>
          <EditIcon className='text-primaryDark-0 hover:text-primaryDark-300 m-auto' />
        </div> */}
      </div>

    </div>
  )
}

export default PayMethodComponent;

import React from 'react';
import { ReactComponent as  CheckmarkIcon } from '../icons/CheckMarkIcon.svg';

type CompleteStepSuccessProps ={
  onGoToProfile: () => void;
  communityId?: string;
}

const CompleteStepSuccess: React.FC<CompleteStepSuccessProps> = ({ onGoToProfile, communityId }) => {
  return (
    <div className="flex flex-col items-center justify-center p-6 text-center">
      {/* Checkmark Circle Icon */}
      <div className="w-16 h-16 mb-6 rounded-full bg-primaryDark-400 flex items-center justify-center">
        <CheckmarkIcon />
      </div>

      {/* Success Message */}
      <h1 className="text-2xl font-semibold mb-2 text-[#0A2533]">
        Added to Focus Group
      </h1>
      <p className="text-[#475569] mb-6">
        You have successfully signed up on HomeCook
      </p>

      {/* Go to Profile Button */}
      <button
        onClick={onGoToProfile}
        className="w-full bg-[#0A2533] text-white py-4 px-6 rounded-lg font-bold hover:bg-[#1a3a4f] transition-colors"
      >
        Go to profile
      </button>
    </div>
  );
};

export default CompleteStepSuccess;


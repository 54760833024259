import React from 'react';
import Pagination from './Pagination';
import { usePagination } from '../hooks/usePagination';

interface PaginatedListProps<T> {
  items: T[];
  itemsPerPage?: number;
  renderItem: (item: T, index: number) => React.ReactNode;
  emptyMessage?: string;
}

const PaginatedList = <T extends unknown>({
  items,
  itemsPerPage = 5,
  renderItem,
  emptyMessage = 'No items to display'
}: PaginatedListProps<T>) => {
  const {
    currentPage,
    setCurrentPage,
    paginatedItems,
    startIndex,
    endIndex,
    totalItems
  } = usePagination(items, itemsPerPage);

  if (items.length === 0) {
    return <div className="text-center text-gray-500">{emptyMessage}</div>;
  }

  return (
    <div>
      {paginatedItems.map((item, index) => renderItem(item, index))}
      
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    </div>
  );
};

export default PaginatedList;

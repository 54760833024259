import { useEffect, useState } from 'react'

import { ReactComponent as AddCircleIcon } from '../../icons/AddCircleIcon.svg'
// import { ReactComponent as EditIcon } from '../../icons/EditIcon.svg'
import { useCompleteSignIn } from '../../hooks/authhooks'

import { ReactComponent as WalletIcon } from "../../icons/WalletIcon.svg";

import { useGetPaymentMethods } from '../../hooks/paymenthooks'
import StripePaymentCheckOut from "../../molecules/PaymentCheckOut"
import PaginatedList from '../../components/PaginatedList';
import PayMethodComponent from '../../components/PayMethodComponent';

const Wallet = () => {

  
  // const [isUpdatingPayMethod, setIsUpdatingPayMethod] = useState<boolean>(false)

  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : ''

  var { paymentMethods } = useGetPaymentMethods(loggedin_username)
 
  const [isAddingPayMethod, setIsAddingPayMethod] = useState<boolean>((paymentMethods && paymentMethods.length < 1) ? true : false)

  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : []


  useEffect(() => {
    document.title = `HomeCook | Resource Pantry`
  }, [paymentMethods])

  return (
    <>
    <div className={`'w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
      <div className={`flex flex-col w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 bg-white`}>
          <div className='w-10/12 mx-auto my-5'>
            <p className='font-raleway text-[20px] text-black font-bold my-5'>Wallet</p>
      
            <div className='border-[1px] border-[#E9E9E9] rounded-xl sm:min-h-[520px] my-5 p-10 w-full'>
              
              {
                (isAddingPayMethod && paymentMethods && paymentMethods.length > 0) && (
                  <div 
                    onClick={()=>setIsAddingPayMethod(false)}
                    className='flex flex-row w-full text-gray-300 font-raleway font-bold text-[20px] cursor-pointer'
                  >
                    <p className='ml-auto'>x</p>
                  </div>
                )
              } 
                
              
              {
                (isAddingPayMethod) ? (
                  <div className=''>
                    <p className='text-[18px] font-raleway text-black font-bold'>Add Your Payment Method</p>
                    <p className='text-[14px] font-open-sans text-[#808080] mt-2 mb-4'>
                      Choose your payment method from the available options below.
                    </p>
                    
                    <div className='flex flex-row rounded-md mb-7 sm:h-[40px] w-[90px] bg-primaryDark-0 text-[14px] text-white font-bold font-raleway'>
                      <WalletIcon className='my-auto ml-auto mr-1' />
                      <p className='my-auto mr-auto'>Card</p>
                    </div>

                    <StripePaymentCheckOut username={loggedin_username} mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={isAddingPayMethod} setIsAddingPayMethod={setIsAddingPayMethod} />
                  </div>
                ) : (
                  <div className=''>
                    <p className='text-[18px] font-raleway text-black font-semibold'>Your Payment Methods</p>
                    <div className='flex flex-row'>
                      <p className='text-[14px] font-open-sans text-[#808080] mb-6 mt-2'>
                        Manage your saved payment methods below.
                      </p>

                      <button 
                        onClick={()=>setIsAddingPayMethod(true)} 
                        className='flex flex-row bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 py-[8px] px-[18px] ml-auto my-auto'
                      >
                        <AddCircleIcon className='mr-2 fill-white text-primaryDark-0' /> 
                        Add a Payment Method
                      </button>
                    </div>

                    <PaginatedList
                      items={paymentMethods}
                      itemsPerPage={5}
                      renderItem={(paymentMethod, index) => (
                        <PayMethodComponent
                          key={index}
                          paymentMethod={paymentMethod}
                          username={loggedin_username}
                        />
                      )}
                      emptyMessage="No payment methods available"
                    />
                  </div>
                )
              }
              
            </div>
          </div>
      </div>
    </div>
    </>
  )
}






export default Wallet
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  startIndex: number;
  endIndex: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  startIndex,
  endIndex,
  onPageChange,
  itemsPerPage,
}) => {
  return (
    <div className="flex flex-row w-full text-[#013847] font-raleway text-[14px] font-bold my-auto">
      <div
        className="flex flex-row justify-center my-auto"
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
      >
        <span className={`text-[14px] my-auto ${currentPage > 1 ? "visible cursor-pointer" : "invisible"}`}>
          <ArrowBackIosIcon style={{ fontSize: 10 }} className="my-auto" />
          &nbsp;Previous
        </span>
      </div>

      <span className="flex flex-row justify-center text-[#8A8A8A] text-[14px] font-light text-center my-auto w-1/2 mx-auto">
        {`Showing ${startIndex + 1} - ${endIndex} of ${totalItems}`}
      </span>

      <div
        className="my-auto flex flex-row justify-center"
        onClick={() => 
          currentPage + 1 <= Math.ceil(totalItems / itemsPerPage) && 
          onPageChange(currentPage + 1)
        }
      >
        <span
          className={`text-[14px] my-auto ${
            currentPage + 1 <= Math.ceil(totalItems / itemsPerPage)
              ? "visible cursor-pointer"
              : "invisible"
          }`}
        >
          Next&nbsp;
          <ArrowForwardIosIcon style={{ fontSize: 10 }} className="my-auto" />
        </span>
      </div>
    </div>
  );
};

export default Pagination;
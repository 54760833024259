import { stateCountyCoordinates } from './enums';

export const countyCoordinates: Record<string, Record<string, { lat: number; lng: number }>> = {
  AK: {
    "Anchorage": { lat: 61.2181, lng: -149.9003 },
    "Fairbanks North Star": { lat: 64.8564, lng: -147.8027 },
    "Juneau": { lat: 58.3019, lng: -134.4197 },
    "Kenai Peninsula": { lat: 60.5000, lng: -151.2500 },
    "Matanuska-Susitna": { lat: 61.5000, lng: -149.0000 },
    "Aleutians East": { lat: 55.5000, lng: -162.5000 },
    "Aleutians West": { lat: 52.5000, lng: -174.5000 },
    "Bethel": { lat: 60.7922, lng: -161.7558 },
    "Bristol Bay": { lat: 58.7500, lng: -156.7500 },
    "Denali": { lat: 63.7500, lng: -150.2500 },
    "Dillingham": { lat: 59.0417, lng: -158.4583 },
    "Haines": { lat: 59.2358, lng: -135.4453 },
    "Hoonah-Angoon": { lat: 58.1101, lng: -134.7262 },
    "Ketchikan Gateway": { lat: 55.3422, lng: -131.6461 },
    "Kodiak Island": { lat: 57.7300, lng: -152.5000 },
    "Kusilvak": { lat: 62.0000, lng: -163.0000 },
    "Lake and Peninsula": { lat: 58.0000, lng: -156.0000 },
    "Nome": { lat: 64.5011, lng: -165.4044 },
    "North Slope": { lat: 69.7500, lng: -153.5000 },
    "Northwest Arctic": { lat: 67.0000, lng: -160.0000 },
    "Petersburg": { lat: 56.8125, lng: -132.9556 },
    "Prince of Wales-Hyder": { lat: 55.6233, lng: -132.6233 },
    "Sitka": { lat: 57.0531, lng: -135.3300 },
    "Skagway": { lat: 59.4583, lng: -135.3139 },
    "Southeast Fairbanks": { lat: 63.7500, lng: -143.2500 },
    "Wrangell": { lat: 56.4708, lng: -132.3767 },
    "Yakutat": { lat: 59.5469, lng: -139.7272 },
    "Yukon-Koyukuk": { lat: 65.5000, lng: -151.5000 }
  },
  AL: {
    "Jefferson": { lat: 33.5207, lng: -86.8027 },
    "Madison": { lat: 34.7314, lng: -86.5707 },
    "Mobile": { lat: 30.6954, lng: -88.0399 },
    "Montgomery": { lat: 32.3668, lng: -86.2997 },
    "Shelby": { lat: 33.2644, lng: -86.6607 },
    "Autauga": { lat: 32.5322, lng: -86.6425 },
    "Baldwin": { lat: 30.6594, lng: -87.7461 },
    "Barbour": { lat: 31.8889, lng: -85.3989 },
    "Bibb": { lat: 32.9988, lng: -87.1261 },
    "Blount": { lat: 33.9778, lng: -86.5678 },
    "Bullock": { lat: 32.1006, lng: -85.7156 },
    "Butler": { lat: 31.7522, lng: -86.6800 },
    "Calhoun": { lat: 33.7711, lng: -85.8261 },
    "Chambers": { lat: 32.9139, lng: -85.3922 },
    "Cherokee": { lat: 34.1758, lng: -85.6044 },
    "Chilton": { lat: 32.8489, lng: -86.7189 },
    "Choctaw": { lat: 32.0197, lng: -88.2633 },
    "Clarke": { lat: 31.6767, lng: -87.8308 },
    "Clay": { lat: 33.2694, lng: -85.8611 },
    "Cleburne": { lat: 33.6747, lng: -85.5186 },
    "Coffee": { lat: 31.4028, lng: -85.9889 },
    "Colbert": { lat: 34.7008, lng: -87.8053 },
    "Conecuh": { lat: 31.4297, lng: -86.9889 },
    "Coosa": { lat: 32.9358, lng: -86.3311 },
    "Covington": { lat: 31.2486, lng: -86.4511 },
    "Crenshaw": { lat: 31.7319, lng: -86.3111 },
    "Cullman": { lat: 34.1319, lng: -86.8678 },
    "Dale": { lat: 31.4319, lng: -85.6100 },
    "Dallas": { lat: 32.3258, lng: -87.1064 },
    "DeKalb": { lat: 34.4597, lng: -85.8042 },
    "Elmore": { lat: 32.5967, lng: -86.1492 },
    "Escambia": { lat: 31.1261, lng: -87.1611 },
    "Etowah": { lat: 34.0453, lng: -86.0347 },
    "Fayette": { lat: 33.7214, lng: -87.7389 },
    "Franklin": { lat: 34.4417, lng: -87.8439 },
    "Geneva": { lat: 31.0953, lng: -85.8389 },
    "Greene": { lat: 32.8528, lng: -87.9522 },
    "Hale": { lat: 32.7586, lng: -87.6286 },
    "Henry": { lat: 31.5147, lng: -85.2389 },
    "Houston": { lat: 31.1533, lng: -85.3022 },
    "Jackson": { lat: 34.7794, lng: -85.9994 },
    "Lamar": { lat: 33.7792, lng: -88.0969 },
    "Lauderdale": { lat: 34.9022, lng: -87.6544 },
    "Lawrence": { lat: 34.5214, lng: -87.3111 },
    "Lee": { lat: 32.6011, lng: -85.3553 },
    "Limestone": { lat: 34.8103, lng: -86.9814 },
    "Lowndes": { lat: 32.1547, lng: -86.6497 },
    "Macon": { lat: 32.3858, lng: -85.6928 },
    "Marengo": { lat: 32.2475, lng: -87.7894 },
    "Marion": { lat: 34.1369, lng: -87.8872 },
    "Marshall": { lat: 34.3669, lng: -86.3069 },
    "Monroe": { lat: 31.5708, lng: -87.3658 },
    "Morgan": { lat: 34.4536, lng: -86.8528 },
    "Perry": { lat: 32.6397, lng: -87.2989 },
    "Pickens": { lat: 33.2808, lng: -88.0883 },
    "Pike": { lat: 31.8036, lng: -85.9408 },
    "Randolph": { lat: 33.2944, lng: -85.4592 },
    "Russell": { lat: 32.3503, lng: -85.1994 },
    "St. Clair": { lat: 33.7158, lng: -86.3147 },
    "Sumter": { lat: 32.5694, lng: -88.1989 },
    "Talladega": { lat: 33.3800, lng: -86.1658 },
    "Tallapoosa": { lat: 32.8622, lng: -85.7978 },
    "Tuscaloosa": { lat: 33.2897, lng: -87.5250 },
    "Walker": { lat: 33.8031, lng: -87.2972 },
    "Washington": { lat: 31.4075, lng: -88.2069 },
    "Wilcox": { lat: 31.9892, lng: -87.3083 },
    "Winston": { lat: 34.1492, lng: -87.3736 }
  },
  AR: {
    "Benton": { lat: 36.3387, lng: -94.2733 },
    "Craighead": { lat: 35.8309, lng: -90.6329 },
    "Pulaski": { lat: 34.7694, lng: -92.3119 },
    "Sebastian": { lat: 35.1993, lng: -94.2733 },
    "Washington": { lat: 35.9790, lng: -94.2159 }
  },
  AZ: {
    "Maricopa": { lat: 33.5102, lng: -112.0901 },
    "Pima": { lat: 32.0654, lng: -110.9247 },
    "Pinal": { lat: 33.0000, lng: -111.5000 },
    "Yavapai": { lat: 34.6000, lng: -112.5500 },
    "Mohave": { lat: 35.7000, lng: -113.7500 },
    "Yuma": { lat: 32.7829, lng: -113.7829 },
    "Coconino": { lat: 35.7000, lng: -111.5000 },
    "Navajo": { lat: 35.3000, lng: -110.2500 },
    "Apache": { lat: 35.4000, lng: -109.4000 },
    "Gila": { lat: 33.8000, lng: -110.8000 },
    "Graham": { lat: 32.9000, lng: -109.9000 },
    "La Paz": { lat: 33.7000, lng: -113.9000 },
    "Santa Cruz": { lat: 31.5000, lng: -110.8000 },
    "Greenlee": { lat: 33.2000, lng: -109.2000 },
    "Cochise": { lat: 31.9000, lng: -109.7500 }
  },
  CA: {
    "Los Angeles": { lat: 34.0522, lng: -118.2437 },
    "San Diego": { lat: 32.7157, lng: -117.1611 },
    "Orange": { lat: 33.7175, lng: -117.8311 },
    "Riverside": { lat: 33.9534, lng: -117.3962 },
    "San Bernardino": { lat: 34.1083, lng: -117.2898 },
    "Santa Clara": { lat: 37.3541, lng: -121.9552 },
    "Alameda": { lat: 37.6466, lng: -122.0490 },
    "Sacramento": { lat: 38.5816, lng: -121.4944 },
    "Contra Costa": { lat: 37.9197, lng: -122.3532 },
    "Fresno": { lat: 36.7378, lng: -119.7871 },
    "San Francisco": { lat: 37.7749, lng: -122.4194 },
    "Kern": { lat: 35.3433, lng: -118.7272 },
    "Ventura": { lat: 34.3575, lng: -119.1391 },
    "San Mateo": { lat: 37.5630, lng: -122.3255 },
    "San Joaquin": { lat: 37.9347, lng: -121.2712 },
    "Stanislaus": { lat: 37.5591, lng: -120.9979 },
    "Sonoma": { lat: 38.5274, lng: -122.8860 },
    "Tulare": { lat: 36.2077, lng: -118.7814 },
    "Solano": { lat: 38.2683, lng: -121.9397 },
    "Merced": { lat: 37.1911, lng: -120.7179 },
    "Placer": { lat: 39.0633, lng: -121.0817 },
    "San Luis Obispo": { lat: 35.3102, lng: -120.4358 },
    "Santa Cruz": { lat: 36.9741, lng: -122.0308 },
    "Monterey": { lat: 36.6000, lng: -121.9000 },
    "Butte": { lat: 39.6667, lng: -121.6000 },
    "Santa Barbara": { lat: 34.4221, lng: -119.7027 },
    "Nevada": { lat: 39.3000, lng: -120.7667 },
    "Shasta": { lat: 40.7500, lng: -122.0400 },
    "Yolo": { lat: 38.5500, lng: -121.7400 },
    "El Dorado": { lat: 38.7786, lng: -120.5247 },
    "Humboldt": { lat: 40.7000, lng: -123.9000 },
    "Imperial": { lat: 32.8478, lng: -115.5694 },
    "Kings": { lat: 36.0753, lng: -119.8156 },
    "Lake": { lat: 39.1000, lng: -122.7500 },
    "Lassen": { lat: 40.6500, lng: -120.7300 },
    "Madera": { lat: 37.2167, lng: -119.7667 },
    "Marin": { lat: 38.0344, lng: -122.7397 },
    "Mendocino": { lat: 39.4400, lng: -123.3900 },
    "Modoc": { lat: 41.5900, lng: -120.7200 },
    "Mono": { lat: 37.9400, lng: -118.8800 },
    "Napa": { lat: 38.5065, lng: -122.2655 },
    "Plumas": { lat: 40.0100, lng: -120.8400 },
    "San Benito": { lat: 36.6100, lng: -121.0900 },
    "Sierra": { lat: 39.5800, lng: -120.5000 },
    "Siskiyou": { lat: 41.5900, lng: -122.5400 },
    "Sutter": { lat: 39.0333, lng: -121.6167 },
    "Tehama": { lat: 40.1250, lng: -122.2340 },
    "Trinity": { lat: 40.6500, lng: -123.1100 },
    "Tuolumne": { lat: 38.0270, lng: -120.2326 },
    "Yuba": { lat: 39.2683, lng: -121.3517 },
    "Alpine": { lat: 38.5972, lng: -119.8072 },
    "Amador": { lat: 38.4464, lng: -120.6569 },
    "Calaveras": { lat: 38.2044, lng: -120.5544 },
    "Colusa": { lat: 39.1778, lng: -122.2372 },
    "Del Norte": { lat: 41.7439, lng: -123.8972 },
    "Glenn": { lat: 39.5983, lng: -122.3917 },
    "Inyo": { lat: 36.5800, lng: -117.4100 },
    "Mariposa": { lat: 37.5700, lng: -119.9100 }
  },
  CO: {
    "Denver": { lat: 39.7392, lng: -104.9903 },
    "El Paso": { lat: 38.8339, lng: -104.8214 },
    "Arapahoe": { lat: 39.6333, lng: -104.7667 },
    "Jefferson": { lat: 39.6000, lng: -105.1000 },
    "Adams": { lat: 39.8700, lng: -104.3400 },
    "Larimer": { lat: 40.6667, lng: -105.4500 },
    "Douglas": { lat: 39.3300, lng: -104.9300 },
    "Boulder": { lat: 40.0333, lng: -105.3500 },
    "Weld": { lat: 40.5500, lng: -104.7000 },
    "Pueblo": { lat: 38.1667, lng: -104.5000 },
    "Mesa": { lat: 39.0167, lng: -108.4667 },
    "Garfield": { lat: 39.5500, lng: -107.7500 },
    "Eagle": { lat: 39.6333, lng: -106.7000 },
    "La Plata": { lat: 37.2833, lng: -107.8500 },
    "Summit": { lat: 39.6333, lng: -106.0667 },
    "Pitkin": { lat: 39.2167, lng: -106.9167 },
    "Routt": { lat: 40.4833, lng: -106.8333 },
    "Delta": { lat: 38.8667, lng: -107.8667 },
    "Montrose": { lat: 38.4667, lng: -107.8667 },
    "Archuleta": { lat: 37.2000, lng: -107.0500 },
    "San Miguel": { lat: 38.0167, lng: -108.4167 },
    "Gunnison": { lat: 38.6667, lng: -107.0167 },
    "Rio Blanco": { lat: 39.9667, lng: -108.2000 },
    "Moffat": { lat: 40.6167, lng: -108.5167 },
    "Ouray": { lat: 38.1500, lng: -107.7667 },
    "San Juan": { lat: 37.7667, lng: -107.6667 },
    "Hinsdale": { lat: 37.8167, lng: -107.3000 },
    "Dolores": { lat: 37.7500, lng: -108.5167 },
    "Montezuma": { lat: 37.3333, lng: -108.6000 },
    "Saguache": { lat: 38.0833, lng: -106.2833 },
    "Mineral": { lat: 37.6667, lng: -106.9167 },
    "Lake": { lat: 39.2000, lng: -106.3500 },
    "Chaffee": { lat: 38.7500, lng: -106.1833 },
    "Custer": { lat: 38.1000, lng: -105.3667 },
    "Fremont": { lat: 38.4667, lng: -105.4333 },
    "Teller": { lat: 38.8833, lng: -105.1667 },
    "Park": { lat: 39.1167, lng: -105.7167 },
    "Elbert": { lat: 39.3000, lng: -104.1333 },
    "Clear Creek": { lat: 39.7000, lng: -105.6500 },
    "Gilpin": { lat: 39.8500, lng: -105.5167 },
    "Grand": { lat: 40.1000, lng: -106.1167 },
    "Jackson": { lat: 40.6667, lng: -106.3333 },
    "Logan": { lat: 40.7333, lng: -103.1167 },
    "Morgan": { lat: 40.2667, lng: -103.8000 },
    "Phillips": { lat: 40.6000, lng: -102.3500 },
    "Sedgwick": { lat: 40.8833, lng: -102.3500 },
    "Washington": { lat: 39.9667, lng: -103.2000 },
    "Yuma": { lat: 40.0000, lng: -102.4167 },
    "Baca": { lat: 37.3167, lng: -102.5500 },
    "Bent": { lat: 37.9500, lng: -103.0667 },
    "Cheyenne": { lat: 38.8167, lng: -102.3500 },
    "Crowley": { lat: 38.3167, lng: -103.7833 },
    "Kiowa": { lat: 38.4333, lng: -102.7333 },
    "Kit Carson": { lat: 39.3000, lng: -102.6000 },
    "Las Animas": { lat: 37.3167, lng: -104.0333 },
    "Lincoln": { lat: 38.9833, lng: -103.5167 },
    "Otero": { lat: 37.9000, lng: -103.7167 },
    "Prowers": { lat: 37.9500, lng: -102.4000 }
  },
  CT: {
    "Fairfield": { lat: 41.2278, lng: -73.3711 },
    "New Haven": { lat: 41.3500, lng: -72.9000 },
    "Hartford": { lat: 41.7667, lng: -72.6833 },
    "New London": { lat: 41.4667, lng: -72.1000 },
    "Litchfield": { lat: 41.7500, lng: -73.2500 },
    "Middlesex": { lat: 41.4333, lng: -72.5167 },
    "Tolland": { lat: 41.8500, lng: -72.3333 },
    "Windham": { lat: 41.8333, lng: -71.9833 }
  },
  DC: {
    "Ward 1": { lat: 38.9137, lng: -77.0317 },
    "Ward 2": { lat: 38.8966, lng: -77.0257 },
    "Ward 3": { lat: 38.9212, lng: -77.0427 },
    "Ward 4": { lat: 38.9472, lng: -77.0007 },
    "Ward 5": { lat: 38.9037, lng: -76.9917 },
    "Ward 6": { lat: 38.8797, lng: -77.0097 },
    "Ward 7": { lat: 38.8937, lng: -76.9897 },
    "Ward 8": { lat: 38.8837, lng: -77.0007 }
  },
  DE: {
    "New Castle": { lat: 39.5833, lng: -75.6333 },
    "Sussex": { lat: 38.6833, lng: -75.3833 },
    "Kent": { lat: 39.1000, lng: -75.5000 }
  },
  FL: {
    "Miami-Dade": { lat: 25.7617, lng: -80.1918 },
    "Broward": { lat: 26.1224, lng: -80.1373 },
    "Palm Beach": { lat: 26.7153, lng: -80.0534 },
    "Hillsborough": { lat: 27.9506, lng: -82.4572 },
    "Orange": { lat: 28.5383, lng: -81.3792 },
    "Pinellas": { lat: 27.9108, lng: -82.7321 },
    "Duval": { lat: 30.3322, lng: -81.6557 },
    "Lee": { lat: 26.5517, lng: -81.8722 },
    "Polk": { lat: 28.0417, lng: -81.9500 },
    "Brevard": { lat: 28.2644, lng: -80.6907 },
    "Volusia": { lat: 29.0864, lng: -81.3039 },
    "Seminole": { lat: 28.7117, lng: -81.2083 },
    "Sarasota": { lat: 27.1806, lng: -82.3311 },
    "Manatee": { lat: 27.4797, lng: -82.3450 },
    "Escambia": { lat: 30.6153, lng: -87.3250 },
    "Alachua": { lat: 29.6744, lng: -82.3578 },
    "Bay": { lat: 30.2444, lng: -85.6322 },
    "Charlotte": { lat: 26.9000, lng: -82.0500 },
    "Citrus": { lat: 28.8500, lng: -82.4500 },
    "Clay": { lat: 30.0000, lng: -81.8500 },
    "Collier": { lat: 26.1000, lng: -81.3500 },
    "Columbia": { lat: 30.2167, lng: -82.6167 },
    "DeSoto": { lat: 27.1833, lng: -81.8500 },
    "Dixie": { lat: 29.6000, lng: -83.2167 },
    "Flagler": { lat: 29.4667, lng: -81.3167 },
    "Franklin": { lat: 29.8000, lng: -84.8000 },
    "Gadsden": { lat: 30.5833, lng: -84.6167 },
    "Gilchrist": { lat: 29.7167, lng: -82.8000 },
    "Glades": { lat: 26.9500, lng: -81.1833 },
    "Gulf": { lat: 29.9000, lng: -85.2500 },
    "Hamilton": { lat: 30.4833, lng: -82.9500 },
    "Hardee": { lat: 27.4833, lng: -81.8000 },
    "Hendry": { lat: 26.5500, lng: -81.1667 },
    "Hernando": { lat: 28.5500, lng: -82.4500 },
    "Highlands": { lat: 27.3333, lng: -81.3333 },
    "Holmes": { lat: 30.8667, lng: -85.8167 },
    "Indian River": { lat: 27.7000, lng: -80.5667 },
    "Jackson": { lat: 30.8000, lng: -85.2000 },
    "Jefferson": { lat: 30.4333, lng: -83.9000 },
    "Lafayette": { lat: 29.9833, lng: -83.1833 },
    "Lake": { lat: 28.8000, lng: -81.8000 },
    "Leon": { lat: 30.4500, lng: -84.2833 },
    "Levy": { lat: 29.2833, lng: -82.7833 },
    "Liberty": { lat: 30.2333, lng: -84.8833 },
    "Madison": { lat: 30.4500, lng: -83.4667 },
    "Marion": { lat: 29.2167, lng: -82.0333 },
    "Martin": { lat: 27.0667, lng: -80.4000 },
    "Monroe": { lat: 25.6167, lng: -81.0167 },
    "Nassau": { lat: 30.6167, lng: -81.8000 },
    "Okaloosa": { lat: 30.7000, lng: -86.6000 },
    "Okeechobee": { lat: 27.4000, lng: -80.8833 },
    "Osceola": { lat: 28.0500, lng: -81.1500 },
    "Pasco": { lat: 28.3000, lng: -82.4333 },
    "Putnam": { lat: 29.6167, lng: -81.7500 },
    "Santa Rosa": { lat: 30.7000, lng: -87.0167 },
    "St. Johns": { lat: 29.9000, lng: -81.4000 },
    "St. Lucie": { lat: 27.3667, lng: -80.2500 },
    "Sumter": { lat: 28.7000, lng: -82.0833 },
    "Suwannee": { lat: 30.2000, lng: -82.9500 },
    "Taylor": { lat: 30.0500, lng: -83.6000 },
    "Union": { lat: 30.0500, lng: -82.3500 },
    "Wakulla": { lat: 30.1500, lng: -84.3833 },
    "Walton": { lat: 30.6000, lng: -86.1667 }
  },
  GA: {
    "Appling": { lat: 31.7500, lng: -82.2833 },
    "Atkinson": { lat: 31.3000, lng: -82.8667 },
    "Bacon": { lat: 31.5500, lng: -82.4500 },
    "Baker": { lat: 31.3167, lng: -84.4500 },
    "Baldwin": { lat: 33.0667, lng: -83.2500 },
    "Banks": { lat: 34.3500, lng: -83.5000 },
    "Barrow": { lat: 34.0000, lng: -83.7167 },
    "Bartow": { lat: 34.2500, lng: -84.8333 },
    "Ben Hill": { lat: 31.7500, lng: -83.2167 },
    "Berrien": { lat: 31.2833, lng: -83.2333 },
    "Bibb": { lat: 32.8000, lng: -83.7000 },
    "Bleckley": { lat: 32.4333, lng: -83.3333 },
    "Brantley": { lat: 31.2000, lng: -81.9833 },
    "Brooks": { lat: 30.8500, lng: -83.5667 },
    "Bryan": { lat: 31.9167, lng: -81.4333 },
    "Bulloch": { lat: 32.4500, lng: -81.7500 },
    "Burke": { lat: 33.0667, lng: -82.0000 },
    "Butts": { lat: 33.2833, lng: -84.0000 },
    "Calhoun": { lat: 31.5333, lng: -84.6167 },
    "Camden": { lat: 30.9167, lng: -81.6333 },
    "Candler": { lat: 32.4000, lng: -82.0667 },
    "Carroll": { lat: 33.5833, lng: -85.0833 },
    "Catoosa": { lat: 34.9000, lng: -85.1333 },
    "Charlton": { lat: 30.7833, lng: -82.1333 },
    "Chatham": { lat: 32.0809, lng: -81.0912 },
    "Chattahoochee": { lat: 32.3500, lng: -84.7833 },
    "Chattooga": { lat: 34.4667, lng: -85.3500 },
    "Cherokee": { lat: 34.2500, lng: -84.4667 },
    "Clarke": { lat: 33.9500, lng: -83.3667 },
    "Clay": { lat: 31.6167, lng: -84.9833 },
    "Clayton": { lat: 33.5417, lng: -84.3583 },
    "Clinch": { lat: 30.9167, lng: -82.7000 },
    "Cobb": { lat: 33.9417, lng: -84.5750 },
    "Coffee": { lat: 31.5500, lng: -82.8500 },
    "Colquitt": { lat: 31.1833, lng: -83.7833 },
    "Columbia": { lat: 33.5500, lng: -82.2500 },
    "Cook": { lat: 31.1500, lng: -83.4333 },
    "Coweta": { lat: 33.3500, lng: -84.7667 },
    "Crawford": { lat: 32.7167, lng: -84.0333 },
    "Crisp": { lat: 31.9167, lng: -83.7833 },
    "Dade": { lat: 34.8500, lng: -85.5167 },
    "Dawson": { lat: 34.4333, lng: -84.1167 },
    "Decatur": { lat: 30.9167, lng: -84.5833 },
    "DeKalb": { lat: 33.7500, lng: -84.2250 },
    "Dodge": { lat: 32.1667, lng: -83.1667 },
    "Dooly": { lat: 32.0500, lng: -83.8000 },
    "Dougherty": { lat: 31.5333, lng: -84.2167 },
    "Douglas": { lat: 33.7000, lng: -84.7667 },
    "Early": { lat: 31.3167, lng: -84.9167 },
    "Echols": { lat: 30.7167, lng: -83.0333 },
    "Effingham": { lat: 32.3667, lng: -81.3333 },
    "Elbert": { lat: 34.1167, lng: -82.8667 },
    "Emanuel": { lat: 32.5833, lng: -82.3000 },
    "Evans": { lat: 32.1500, lng: -81.8833 },
    "Fannin": { lat: 34.8667, lng: -84.3167 },
    "Fayette": { lat: 33.4167, lng: -84.4833 },
    "Floyd": { lat: 34.2667, lng: -85.2167 },
    "Forsyth": { lat: 34.2000, lng: -84.1333 },
    "Franklin": { lat: 34.3667, lng: -83.2333 },
    "Fulton": { lat: 33.7900, lng: -84.4700 },
    "Gilmer": { lat: 34.7000, lng: -84.7000 },
    "Glascock": { lat: 33.2333, lng: -82.6000 },
    "Glynn": { lat: 31.2333, lng: -81.5333 },
    "Gordon": { lat: 34.5000, lng: -84.8667 },
    "Grady": { lat: 30.8833, lng: -84.2333 },
    "Greene": { lat: 33.5833, lng: -83.1667 },
    "Gwinnett": { lat: 33.9500, lng: -84.0333 },
    "Habersham": { lat: 34.6333, lng: -83.5333 },
    "Hall": { lat: 34.3167, lng: -83.8167 },
    "Hancock": { lat: 33.2667, lng: -83.0000 },
    "Haralson": { lat: 33.7833, lng: -85.2333 },
    "Harris": { lat: 32.7333, lng: -84.9167 },
    "Hart": { lat: 34.3500, lng: -82.9500 },
    "Heard": { lat: 33.3000, lng: -85.1333 },
    "Henry": { lat: 33.4500, lng: -84.1500 },
    "Houston": { lat: 32.4667, lng: -83.6667 },
    "Irwin": { lat: 31.6000, lng: -83.2667 },
    "Jackson": { lat: 34.1333, lng: -83.5667 },
    "Jasper": { lat: 33.3167, lng: -83.6833 },
    "Jeff Davis": { lat: 31.8167, lng: -82.6333 },
    "Jefferson": { lat: 33.0500, lng: -82.4167 },
    "Jenkins": { lat: 32.7833, lng: -81.9667 },
    "Johnson": { lat: 32.7000, lng: -82.6667 },
    "Jones": { lat: 33.0500, lng: -83.5667 },
    "Lamar": { lat: 33.0667, lng: -84.1333 },
    "Lanier": { lat: 31.0333, lng: -83.0667 },
    "Laurens": { lat: 32.4667, lng: -82.9167 },
    "Lee": { lat: 31.7833, lng: -84.1333 },
    "Liberty": { lat: 31.8000, lng: -81.7500 },
    "Lincoln": { lat: 33.7833, lng: -82.4667 },
    "Long": { lat: 31.7500, lng: -81.7500 },
    "Lowndes": { lat: 30.8333, lng: -83.2667 },
    "Lumpkin": { lat: 34.5333, lng: -84.0000 },
    "McDuffie": { lat: 33.4833, lng: -82.4833 },
    "McIntosh": { lat: 31.4833, lng: -81.4833 },
    "Macon": { lat: 32.3500, lng: -84.0333 },
    "Madison": { lat: 34.1333, lng: -83.2167 },
    "Marion": { lat: 32.3500, lng: -84.5333 },
    "Meriwether": { lat: 33.0333, lng: -84.6833 },
    "Miller": { lat: 31.1667, lng: -84.7333 },
    "Mitchell": { lat: 31.2167, lng: -84.1833 },
    "Monroe": { lat: 33.0167, lng: -83.9167 },
    "Montgomery": { lat: 32.1667, lng: -82.5333 },
    "Morgan": { lat: 33.5833, lng: -83.4833 },
    "Murray": { lat: 34.7833, lng: -84.7500 },
    "Muscogee": { lat: 32.5000, lng: -84.8750 },
    "Newton": { lat: 33.5500, lng: -83.8500 },
    "Oconee": { lat: 33.8333, lng: -83.4333 },
    "Oglethorpe": { lat: 33.8833, lng: -83.0833 },
    "Paulding": { lat: 33.9167, lng: -84.8667 },
    "Peach": { lat: 32.5667, lng: -83.9167 },
    "Pickens": { lat: 34.4667, lng: -84.4667 },
    "Pierce": { lat: 31.3500, lng: -82.2000 },
    "Pike": { lat: 33.1000, lng: -84.3833 },
    "Polk": { lat: 34.0000, lng: -85.1833 },
    "Pulaski": { lat: 32.2333, lng: -83.4667 },
    "Putnam": { lat: 33.3167, lng: -83.3667 },
    "Quitman": { lat: 31.8667, lng: -85.0167 },
    "Rabun": { lat: 34.8833, lng: -83.4000 },
    "Randolph": { lat: 31.7667, lng: -84.7500 },
    "Richmond": { lat: 33.3583, lng: -82.0750 },
    "Rockdale": { lat: 33.6500, lng: -84.0333 },
    "Schley": { lat: 32.2667, lng: -84.3167 },
    "Screven": { lat: 32.7500, lng: -81.6167 },
    "Seminole": { lat: 30.9333, lng: -84.8667 },
    "Spalding": { lat: 33.2667, lng: -84.2833 },
    "Stephens": { lat: 34.5500, lng: -83.3333 },
    "Stewart": { lat: 32.0667, lng: -84.8333 },
    "Sumter": { lat: 32.0500, lng: -84.2000 },
    "Talbot": { lat: 32.7000, lng: -84.5333 },
    "Taliaferro": { lat: 33.5667, lng: -82.9167 },
    "Tattnall": { lat: 32.0500, lng: -82.0667 },
    "Taylor": { lat: 32.5500, lng: -84.2500 },
    "Telfair": { lat: 31.9333, lng: -82.9000 },
    "Terrell": { lat: 31.7833, lng: -84.4333 },
    "Thomas": { lat: 30.8667, lng: -83.9167 },
    "Tift": { lat: 31.4500, lng: -83.5167 },
    "Toombs": { lat: 32.1167, lng: -82.3333 },
    "Towns": { lat: 34.9167, lng: -83.7333 },
    "Treutlen": { lat: 32.4000, lng: -82.5667 },
    "Troup": { lat: 33.0333, lng: -85.0333 },
    "Turner": { lat: 31.7167, lng: -83.6167 },
    "Twiggs": { lat: 32.6667, lng: -83.4333 },
    "Union": { lat: 34.8333, lng: -83.9833 },
    "Upson": { lat: 32.8833, lng: -84.3000 },
    "Walker": { lat: 34.7333, lng: -85.3000 },
    "Walton": { lat: 33.7833, lng: -83.7333 },
    "Ware": { lat: 31.0500, lng: -82.4500 },
    "Warren": { lat: 33.4000, lng: -82.6000 },
    "Washington": { lat: 32.9667, lng: -82.8000 },
    "Wayne": { lat: 31.5500, lng: -81.9167 },
    "Webster": { lat: 32.0500, lng: -84.5500 },
    "Wheeler": { lat: 32.1167, lng: -82.7167 },
    "White": { lat: 34.6500, lng: -83.7500 },
    "Whitfield": { lat: 34.8000, lng: -84.9667 },
    "Wilcox": { lat: 31.9667, lng: -83.4333 },
    "Wilkes": { lat: 33.7833, lng: -82.7333 },
    "Wilkinson": { lat: 32.8000, lng: -83.1667 },
    "Worth": { lat: 31.5500, lng: -83.8500 }
  },
  HI: {
    "Hawaii": { lat: 19.5429, lng: -155.6659 },
    "Honolulu": { lat: 21.3069, lng: -157.8583 },
    "Kalawao": { lat: 21.1897, lng: -156.9750 },
    "Kauai": { lat: 22.0964, lng: -159.5261 },
    "Maui": { lat: 20.7984, lng: -156.3319 }
  },
  IA: {
    "Adair": { lat: 41.3333, lng: -94.4667 },
    "Adams": { lat: 41.0333, lng: -94.7000 },
    "Allamakee": { lat: 43.2833, lng: -91.3833 },
    "Appanoose": { lat: 40.7500, lng: -92.8667 },
    "Audubon": { lat: 41.6833, lng: -94.9000 },
    "Benton": { lat: 42.0833, lng: -92.0667 },
    "Black Hawk": { lat: 42.4667, lng: -92.3000 },
    "Boone": { lat: 42.0333, lng: -93.9333 },
    "Bremer": { lat: 42.7667, lng: -92.3167 },
    "Buchanan": { lat: 42.4667, lng: -91.8333 },
    "Buena Vista": { lat: 42.7333, lng: -95.1500 },
    "Butler": { lat: 42.7333, lng: -92.7900 },
    "Calhoun": { lat: 42.3833, lng: -94.6333 },
    "Carroll": { lat: 42.0333, lng: -94.8667 },
    "Cass": { lat: 41.3333, lng: -94.9333 },
    "Cedar": { lat: 41.7833, lng: -91.1333 },
    "Cerro Gordo": { lat: 43.0833, lng: -93.2500 },
    "Cherokee": { lat: 42.7333, lng: -95.6333 },
    "Chickasaw": { lat: 43.0500, lng: -92.3167 },
    "Clarke": { lat: 41.0333, lng: -93.7833 },
    "Clay": { lat: 43.0833, lng: -95.1500 },
    "Clayton": { lat: 42.8400, lng: -91.3400 },
    "Clinton": { lat: 41.8900, lng: -90.5300 },
    "Crawford": { lat: 42.0333, lng: -95.3833 },
    "Dallas": { lat: 41.6833, lng: -94.0400 },
    "Davis": { lat: 40.7500, lng: -92.4100 },
    "Decatur": { lat: 40.7333, lng: -93.7833 },
    "Delaware": { lat: 42.4667, lng: -91.3667 },
    "Des Moines": { lat: 40.8167, lng: -91.1833 },
    "Dickinson": { lat: 43.3833, lng: -95.1500 },
    "Dubuque": { lat: 42.4667, lng: -90.8833 },
    "Emmet": { lat: 43.3833, lng: -94.6667 },
    "Fayette": { lat: 42.8667, lng: -91.8500 },
    "Floyd": { lat: 43.0500, lng: -92.7833 },
    "Franklin": { lat: 42.7333, lng: -93.2667 },
    "Fremont": { lat: 40.7500, lng: -95.6000 },
    "Greene": { lat: 42.0333, lng: -94.4000 },
    "Grundy": { lat: 42.4000, lng: -92.7833 },
    "Guthrie": { lat: 41.6833, lng: -94.5000 },
    "Hamilton": { lat: 42.3833, lng: -93.7000 },
    "Hancock": { lat: 43.0833, lng: -93.7333 },
    "Hardin": { lat: 42.3833, lng: -93.2333 },
    "Harrison": { lat: 41.6833, lng: -95.8333 },
    "Henry": { lat: 40.9833, lng: -91.5500 },
    "Howard": { lat: 43.3833, lng: -92.3167 },
    "Humboldt": { lat: 42.7833, lng: -94.2000 },
    "Ida": { lat: 42.3833, lng: -95.5167 },
    "Iowa": { lat: 41.6833, lng: -92.0667 },
    "Jackson": { lat: 42.1667, lng: -90.5700 },
    "Jasper": { lat: 41.6833, lng: -93.0500 },
    "Jefferson": { lat: 41.0333, lng: -91.9500 },
    "Johnson": { lat: 41.6667, lng: -91.5833 },
    "Jones": { lat: 42.1200, lng: -91.1300 },
    "Keokuk": { lat: 41.3333, lng: -92.1667 },
    "Kossuth": { lat: 43.0833, lng: -94.2000 },
    "Lee": { lat: 40.6400, lng: -91.4800 },
    "Linn": { lat: 42.0833, lng: -91.5997 },
    "Louisa": { lat: 41.2167, lng: -91.2567 },
    "Lucas": { lat: 41.0333, lng: -93.3167 },
    "Lyon": { lat: 43.3833, lng: -96.2167 },
    "Madison": { lat: 41.3333, lng: -94.0167 },
    "Mahaska": { lat: 41.3333, lng: -92.6400 },
    "Marion": { lat: 41.3333, lng: -93.0933 },
    "Marshall": { lat: 42.0333, lng: -92.9833 },
    "Mills": { lat: 41.0333, lng: -95.6167 },
    "Mitchell": { lat: 43.3833, lng: -92.7833 },
    "Monona": { lat: 42.0500, lng: -95.9500 },
    "Monroe": { lat: 41.0333, lng: -92.8667 },
    "Montgomery": { lat: 41.0333, lng: -95.1500 },
    "Muscatine": { lat: 41.4833, lng: -91.0833 },
    "O'Brien": { lat: 43.0833, lng: -95.6333 },
    "Osceola": { lat: 43.3833, lng: -95.6333 },
    "Page": { lat: 40.7333, lng: -95.1500 },
    "Palo Alto": { lat: 43.0833, lng: -94.6667 },
    "Plymouth": { lat: 42.7333, lng: -96.2167 },
    "Pocahontas": { lat: 42.7333, lng: -94.6667 },
    "Polk": { lat: 41.6833, lng: -93.5700 },
    "Pottawattamie": { lat: 41.3333, lng: -95.5300 },
    "Poweshiek": { lat: 41.6833, lng: -92.5300 },
    "Ringgold": { lat: 40.7333, lng: -94.2400 },
    "Sac": { lat: 42.3833, lng: -95.1000 },
    "Scott": { lat: 41.6333, lng: -90.6167 },
    "Shelby": { lat: 41.6833, lng: -95.3167 },
    "Sioux": { lat: 43.0833, lng: -96.1767 },
    "Story": { lat: 42.0333, lng: -93.5300 },
    "Tama": { lat: 42.0747, lng: -92.5300 },
    "Taylor": { lat: 40.7333, lng: -94.6900 },
    "Union": { lat: 41.0333, lng: -94.2400 },
    "Van Buren": { lat: 40.7500, lng: -91.9500 },
    "Wapello": { lat: 41.0333, lng: -92.4100 },
    "Warren": { lat: 41.3333, lng: -93.5700 },
    "Washington": { lat: 41.3333, lng: -91.7167 },
    "Wayne": { lat: 40.7333, lng: -93.3167 },
    "Webster": { lat: 42.4333, lng: -94.1767 },
    "Winnebago": { lat: 43.3833, lng: -93.7333 },
    "Winneshiek": { lat: 43.2833, lng: -91.8500 },
    "Woodbury": { lat: 42.3833, lng: -96.0500 },
    "Worth": { lat: 43.3833, lng: -93.2500 },
    "Wright": { lat: 42.7333, lng: -93.7333 }
  },
  ID: {
    "Ada": { lat: 43.4527, lng: -116.2419 },
    "Adams": { lat: 44.8900, lng: -116.4500 },
    "Bannock": { lat: 42.6667, lng: -112.2333 },
    "Bear Lake": { lat: 42.2833, lng: -111.3333 },
    "Benewah": { lat: 47.2167, lng: -116.6333 },
    "Bingham": { lat: 43.2167, lng: -112.3833 },
    "Blaine": { lat: 43.3833, lng: -114.0000 },
    "Boise": { lat: 44.0000, lng: -115.7333 },
    "Bonner": { lat: 48.2900, lng: -116.6000 },
    "Bonneville": { lat: 43.3833, lng: -111.6167 },
    "Boundary": { lat: 48.7667, lng: -116.4667 },
    "Butte": { lat: 43.6167, lng: -113.1667 },
    "Camas": { lat: 43.4667, lng: -114.8167 },
    "Canyon": { lat: 43.6167, lng: -116.7000 },
    "Caribou": { lat: 42.7667, lng: -111.6000 },
    "Cassia": { lat: 42.2833, lng: -113.6167 },
    "Clark": { lat: 44.2833, lng: -112.3500 },
    "Clearwater": { lat: 46.6733, lng: -115.6617 },
    "Custer": { lat: 44.2500, lng: -114.2333 },
    "Elmore": { lat: 43.3333, lng: -115.4667 },
    "Franklin": { lat: 42.1833, lng: -111.8167 },
    "Fremont": { lat: 44.2167, lng: -111.4833 },
    "Gem": { lat: 44.0667, lng: -116.4000 },
    "Gooding": { lat: 42.9167, lng: -114.8500 },
    "Idaho": { lat: 45.8167, lng: -115.4667 },
    "Jefferson": { lat: 43.8167, lng: -112.3167 },
    "Jerome": { lat: 42.6833, lng: -114.2667 },
    "Kootenai": { lat: 47.6733, lng: -116.6933 },
    "Latah": { lat: 46.8167, lng: -116.7500 },
    "Lemhi": { lat: 44.9167, lng: -113.9333 },
    "Lewis": { lat: 46.2333, lng: -116.4167 },
    "Lincoln": { lat: 43.0000, lng: -114.1333 },
    "Madison": { lat: 43.7833, lng: -111.6500 },
    "Minidoka": { lat: 42.8500, lng: -113.6333 },
    "Nez Perce": { lat: 46.3333, lng: -116.7500 },
    "Oneida": { lat: 42.1500, lng: -112.5167 },
    "Owyhee": { lat: 42.5833, lng: -116.1333 },
    "Payette": { lat: 44.0000, lng: -116.7500 },
    "Power": { lat: 42.6900, lng: -112.8300 },
    "Shoshone": { lat: 47.3500, lng: -115.8900 },
    "Teton": { lat: 43.7500, lng: -111.2000 },
    "Twin Falls": { lat: 42.5833, lng: -114.4667 },
    "Valley": { lat: 44.7500, lng: -115.5667 },
    "Washington": { lat: 44.4500, lng: -116.7833 }
  },
  IL: {
    "Adams": { lat: 39.9867, lng: -91.1944 },
    "Alexander": { lat: 37.1833, lng: -89.3333 },
    "Bond": { lat: 38.8833, lng: -89.4333 },
    "Boone": { lat: 42.3167, lng: -88.8167 },
    "Brown": { lat: 39.9500, lng: -90.7500 },
    "Bureau": { lat: 41.4000, lng: -89.5167 },
    "Calhoun": { lat: 39.1667, lng: -90.6667 },
    "Carroll": { lat: 42.0667, lng: -89.9333 },
    "Cass": { lat: 39.9667, lng: -90.2333 },
    "Champaign": { lat: 40.1333, lng: -88.2000 },
    "Christian": { lat: 39.5333, lng: -89.2667 },
    "Clark": { lat: 39.3333, lng: -87.7833 },
    "Clay": { lat: 38.7500, lng: -88.4833 },
    "Clinton": { lat: 38.6000, lng: -89.4167 },
    "Coles": { lat: 39.5167, lng: -88.2167 },
    "Cook": { lat: 41.8333, lng: -87.8167 },
    "Crawford": { lat: 39.0000, lng: -87.7500 },
    "Cumberland": { lat: 39.2667, lng: -88.2333 },
    "DeKalb": { lat: 41.8833, lng: -88.7667 },
    "De Witt": { lat: 40.1667, lng: -88.9000 },
    "Douglas": { lat: 39.7667, lng: -88.2167 },
    "DuPage": { lat: 41.8500, lng: -88.0833 },
    "Edgar": { lat: 39.6833, lng: -87.7500 },
    "Edwards": { lat: 38.4167, lng: -88.0500 },
    "Effingham": { lat: 39.0500, lng: -88.5833 },
    "Fayette": { lat: 39.0000, lng: -89.0167 },
    "Ford": { lat: 40.5833, lng: -88.2167 },
    "Franklin": { lat: 37.9900, lng: -88.9200 },
    "Fulton": { lat: 40.4667, lng: -90.2000 },
    "Gallatin": { lat: 37.7667, lng: -88.2333 },
    "Greene": { lat: 39.3500, lng: -90.3833 },
    "Grundy": { lat: 41.2833, lng: -88.4167 },
    "Hamilton": { lat: 38.0833, lng: -88.5333 },
    "Hancock": { lat: 40.4000, lng: -91.1667 },
    "Hardin": { lat: 37.5167, lng: -88.2667 },
    "Henderson": { lat: 40.8167, lng: -90.9333 },
    "Henry": { lat: 41.3500, lng: -90.1333 },
    "Iroquois": { lat: 40.7500, lng: -87.8167 },
    "Jackson": { lat: 37.7833, lng: -89.3833 },
    "Jasper": { lat: 39.0000, lng: -88.1500 },
    "Jefferson": { lat: 38.3000, lng: -88.9167 },
    "Jersey": { lat: 39.0833, lng: -90.3500 },
    "Jo Daviess": { lat: 42.3667, lng: -90.2167 },
    "Johnson": { lat: 37.4500, lng: -88.8833 },
    "Kane": { lat: 41.9333, lng: -88.4333 },
    "Kankakee": { lat: 41.1333, lng: -87.8667 },
    "Kendall": { lat: 41.5833, lng: -88.4333 },
    "Knox": { lat: 40.9333, lng: -90.2167 },
    "Lake": { lat: 42.3333, lng: -87.8333 },
    "LaSalle": { lat: 41.3333, lng: -88.8833 },
    "Lawrence": { lat: 38.7167, lng: -87.7333 },
    "Lee": { lat: 41.7500, lng: -89.3000 },
    "Livingston": { lat: 40.8833, lng: -88.5500 },
    "Logan": { lat: 40.1333, lng: -89.3667 },
    "McDonough": { lat: 40.4500, lng: -90.6833 },
    "McHenry": { lat: 42.3167, lng: -88.4500 },
    "McLean": { lat: 40.4833, lng: -88.8500 },
    "Macon": { lat: 39.8500, lng: -88.9667 },
    "Macoupin": { lat: 39.2667, lng: -89.9167 },
    "Madison": { lat: 38.8333, lng: -89.9000 },
    "Marion": { lat: 38.6500, lng: -89.0000 },
    "Marshall": { lat: 41.0333, lng: -89.3333 },
    "Mason": { lat: 40.2333, lng: -89.9167 },
    "Massac": { lat: 37.2167, lng: -88.7000 },
    "Menard": { lat: 40.0167, lng: -89.8000 },
    "Mercer": { lat: 41.2000, lng: -90.7333 },
    "Monroe": { lat: 38.2833, lng: -90.1833 },
    "Montgomery": { lat: 39.2333, lng: -89.4833 },
    "Morgan": { lat: 39.7167, lng: -90.2000 },
    "Moultrie": { lat: 39.6333, lng: -88.6167 },
    "Ogle": { lat: 42.0333, lng: -89.3167 },
    "Peoria": { lat: 40.7833, lng: -89.7667 },
    "Perry": { lat: 38.0833, lng: -89.3667 },
    "Piatt": { lat: 40.0167, lng: -88.5900 },
    "Pike": { lat: 39.6167, lng: -90.8833 },
    "Pope": { lat: 37.4167, lng: -88.5667 },
    "Pulaski": { lat: 37.2167, lng: -89.1333 },
    "Putnam": { lat: 41.2000, lng: -89.2833 },
    "Randolph": { lat: 38.0500, lng: -89.8333 },
    "Richland": { lat: 38.7167, lng: -88.0833 },
    "Rock Island": { lat: 41.4667, lng: -90.5667 },
    "St. Clair": { lat: 38.4700, lng: -89.9283 },
    "Saline": { lat: 37.7500, lng: -88.5400 },
    "Sangamon": { lat: 39.7500, lng: -89.6667 },
    "Schuyler": { lat: 40.1667, lng: -90.6167 },
    "Scott": { lat: 39.6500, lng: -90.4667 },
    "Shelby": { lat: 39.3833, lng: -88.8000 },
    "Stark": { lat: 41.0933, lng: -89.7933 },
    "Stephenson": { lat: 42.3500, lng: -89.6667 },
    "Tazewell": { lat: 40.5000, lng: -89.5167 },
    "Union": { lat: 37.4667, lng: -89.2500 },
    "Vermilion": { lat: 40.1833, lng: -87.7333 },
    "Wabash": { lat: 38.4333, lng: -87.8333 },
    "Warren": { lat: 40.8500, lng: -90.6167 },
    "Washington": { lat: 38.3500, lng: -89.4167 },
    "Wayne": { lat: 38.4333, lng: -88.4167 },
    "White": { lat: 38.0833, lng: -88.1667 },
    "Whiteside": { lat: 41.7500, lng: -89.9167 },
    "Will": { lat: 41.4400, lng: -87.9283 },
    "Williamson": { lat: 37.7333, lng: -88.9333 },
    "Winnebago": { lat: 42.3333, lng: -89.1667 },
    "Woodford": { lat: 40.7833, lng: -89.2167 }
  },
  IN: {
    "Adams": { lat: 40.7467, lng: -84.9367 },
    "Allen": { lat: 41.0907, lng: -85.1437 },
    "Bartholomew": { lat: 39.2000, lng: -85.9000 },
    "Benton": { lat: 40.6000, lng: -87.3167 },
    "Blackford": { lat: 40.4733, lng: -85.3233 },
    "Boone": { lat: 40.0500, lng: -86.4667 },
    "Brown": { lat: 39.2000, lng: -86.2333 },
    "Carroll": { lat: 40.5833, lng: -86.5667 },
    "Cass": { lat: 40.7500, lng: -86.3500 },
    "Clark": { lat: 38.4833, lng: -85.7000 },
    "Clay": { lat: 39.4000, lng: -87.1167 },
    "Clinton": { lat: 40.2833, lng: -86.5167 },
    "Crawford": { lat: 38.2833, lng: -86.4500 },
    "Daviess": { lat: 38.7000, lng: -87.1333 },
    "Dearborn": { lat: 39.1500, lng: -84.9733 },
    "Decatur": { lat: 39.3000, lng: -85.5000 },
    "DeKalb": { lat: 41.3933, lng: -85.0000 },
    "Delaware": { lat: 40.2000, lng: -85.4000 },
    "Dubois": { lat: 38.3667, lng: -86.8667 },
    "Elkhart": { lat: 41.5933, lng: -85.8500 },
    "Fayette": { lat: 39.6333, lng: -85.1733 },
    "Floyd": { lat: 38.3167, lng: -85.9000 },
    "Fountain": { lat: 40.1167, lng: -87.2500 },
    "Franklin": { lat: 39.4167, lng: -85.0667 },
    "Fulton": { lat: 41.0667, lng: -86.2500 },
    "Gibson": { lat: 38.3500, lng: -87.5833 },
    "Grant": { lat: 40.5500, lng: -85.6500 },
    "Greene": { lat: 39.0333, lng: -87.0000 },
    "Hamilton": { lat: 40.0726, lng: -86.0552 },
    "Hancock": { lat: 39.8167, lng: -85.7733 },
    "Harrison": { lat: 38.1933, lng: -86.1167 },
    "Hendricks": { lat: 39.8167, lng: -86.5000 },
    "Henry": { lat: 39.9333, lng: -85.3933 },
    "Howard": { lat: 40.4833, lng: -86.1333 },
    "Huntington": { lat: 40.8333, lng: -85.5000 },
    "Jackson": { lat: 38.9000, lng: -86.0333 },
    "Jasper": { lat: 41.0167, lng: -87.1167 },
    "Jay": { lat: 40.4333, lng: -85.0000 },
    "Jefferson": { lat: 38.7833, lng: -85.4333 },
    "Jennings": { lat: 39.0000, lng: -85.6333 },
    "Johnson": { lat: 39.4833, lng: -86.1000 },
    "Knox": { lat: 38.6833, lng: -87.4167 },
    "Kosciusko": { lat: 41.2433, lng: -85.8633 },
    "LaGrange": { lat: 41.6433, lng: -85.4167 },
    "Lake": { lat: 41.4731, lng: -87.3461 },
    "LaPorte": { lat: 41.5433, lng: -86.7433 },
    "Lawrence": { lat: 38.8500, lng: -86.4833 },
    "Madison": { lat: 40.1667, lng: -85.7167 },
    "Marion": { lat: 39.7684, lng: -86.1581 },
    "Marshall": { lat: 41.3333, lng: -86.2667 },
    "Martin": { lat: 38.7000, lng: -86.8000 },
    "Miami": { lat: 40.7500, lng: -86.0333 },
    "Monroe": { lat: 39.1667, lng: -86.5333 },
    "Montgomery": { lat: 40.0333, lng: -86.8833 },
    "Morgan": { lat: 39.4833, lng: -86.4500 },
    "Newton": { lat: 40.9500, lng: -87.4000 },
    "Noble": { lat: 41.4000, lng: -85.4167 },
    "Ohio": { lat: 38.9500, lng: -84.9667 },
    "Orange": { lat: 38.5333, lng: -86.5000 },
    "Owen": { lat: 39.3167, lng: -86.8333 },
    "Parke": { lat: 39.7667, lng: -87.2167 },
    "Perry": { lat: 38.0167, lng: -86.7833 },
    "Pike": { lat: 38.4000, lng: -87.2333 },
    "Porter": { lat: 41.4833, lng: -87.0667 },
    "Posey": { lat: 38.0167, lng: -87.8667 },
    "Pulaski": { lat: 41.0433, lng: -86.6933 },
    "Putnam": { lat: 39.6667, lng: -86.8500 },
    "Randolph": { lat: 40.1500, lng: -85.0167 },
    "Ripley": { lat: 39.1500, lng: -85.2667 },
    "Rush": { lat: 39.6167, lng: -85.4667 },
    "St. Joseph": { lat: 41.6184, lng: -86.2897 },
    "Scott": { lat: 38.6833, lng: -85.7500 },
    "Shelby": { lat: 39.5167, lng: -85.7833 },
    "Spencer": { lat: 38.0167, lng: -87.0000 },
    "Starke": { lat: 41.2833, lng: -86.6500 },
    "Steuben": { lat: 41.6333, lng: -85.0000 },
    "Sullivan": { lat: 39.0833, lng: -87.4167 },
    "Switzerland": { lat: 38.8167, lng: -85.0333 },
    "Tippecanoe": { lat: 40.3833, lng: -86.8833 },
    "Tipton": { lat: 40.3167, lng: -86.0500 },
    "Union": { lat: 39.6333, lng: -84.9167 },
    "Vanderburgh": { lat: 38.0167, lng: -87.5833 },
    "Vermillion": { lat: 39.8500, lng: -87.4667 },
    "Vigo": { lat: 39.4500, lng: -87.3833 },
    "Wabash": { lat: 40.8433, lng: -85.7933 },
    "Warren": { lat: 40.3433, lng: -87.3567 },
    "Warrick": { lat: 38.0833, lng: -87.2667 },
    "Washington": { lat: 38.6000, lng: -86.1000 },
    "Wayne": { lat: 39.8333, lng: -85.0167 },
    "Wells": { lat: 40.7333, lng: -85.2167 },
    "White": { lat: 40.7500, lng: -86.8667 },
    "Whitley": { lat: 41.1333, lng: -85.5000 }
  },
  KS: {
    "Allen": { lat: 37.8833, lng: -95.3000 },
    "Anderson": { lat: 38.2167, lng: -95.2833 },
    "Atchison": { lat: 39.5333, lng: -95.3167 },
    "Barber": { lat: 37.2333, lng: -98.6833 },
    "Barton": { lat: 38.4667, lng: -98.7667 },
    "Bourbon": { lat: 37.8500, lng: -94.8500 },
    "Brown": { lat: 39.8333, lng: -95.5667 },
    "Butler": { lat: 37.7833, lng: -96.8333 },
    "Chase": { lat: 38.3000, lng: -96.5833 },
    "Chautauqua": { lat: 37.1500, lng: -96.2500 },
    "Cherokee": { lat: 37.1667, lng: -94.8500 },
    "Cheyenne": { lat: 39.7833, lng: -101.7833 },
    "Clark": { lat: 37.2333, lng: -99.8167 },
    "Clay": { lat: 39.3500, lng: -97.1667 },
    "Cloud": { lat: 39.4833, lng: -97.6500 },
    "Coffey": { lat: 38.2333, lng: -95.7333 },
    "Comanche": { lat: 37.1833, lng: -99.2833 },
    "Cowley": { lat: 37.2333, lng: -96.8333 },
    "Crawford": { lat: 37.5000, lng: -94.8500 },
    "Decatur": { lat: 39.7833, lng: -100.4500 },
    "Dickinson": { lat: 38.8667, lng: -97.1500 },
    "Doniphan": { lat: 39.7833, lng: -95.0833 },
    "Douglas": { lat: 38.8856, lng: -95.2656 },
    "Edwards": { lat: 37.8833, lng: -99.3000 },
    "Elk": { lat: 37.4500, lng: -96.2500 },
    "Ellis": { lat: 38.9167, lng: -99.3167 },
    "Ellsworth": { lat: 38.7000, lng: -98.2000 },
    "Finney": { lat: 38.0000, lng: -100.7333 },
    "Ford": { lat: 37.7000, lng: -99.8833 },
    "Franklin": { lat: 38.5833, lng: -95.2833 },
    "Geary": { lat: 39.0000, lng: -96.7500 },
    "Gove": { lat: 38.9167, lng: -100.4833 },
    "Graham": { lat: 39.3500, lng: -99.8833 },
    "Grant": { lat: 37.5833, lng: -101.3000 },
    "Gray": { lat: 37.7333, lng: -100.4500 },
    "Greeley": { lat: 38.4667, lng: -101.8000 },
    "Greenwood": { lat: 37.8833, lng: -96.2333 },
    "Hamilton": { lat: 37.9833, lng: -101.7833 },
    "Harper": { lat: 37.2000, lng: -98.0667 },
    "Harvey": { lat: 38.0500, lng: -97.3333 },
    "Haskell": { lat: 37.5500, lng: -100.8833 },
    "Hodgeman": { lat: 38.0833, lng: -99.8833 },
    "Jackson": { lat: 39.4167, lng: -95.7833 },
    "Jefferson": { lat: 39.2333, lng: -95.3833 },
    "Jewell": { lat: 39.7833, lng: -98.2167 },
    "Johnson": { lat: 38.8839, lng: -94.8225 },
    "Kearny": { lat: 37.9833, lng: -101.3167 },
    "Kingman": { lat: 37.5500, lng: -98.1333 },
    "Kiowa": { lat: 37.5500, lng: -99.2833 },
    "Labette": { lat: 37.2000, lng: -95.2833 },
    "Lane": { lat: 38.4667, lng: -100.4667 },
    "Leavenworth": { lat: 39.2000, lng: -95.0333 },
    "Lincoln": { lat: 39.0500, lng: -98.1500 },
    "Linn": { lat: 38.2167, lng: -94.8500 },
    "Logan": { lat: 39.0000, lng: -101.1000 },
    "Lyon": { lat: 38.4500, lng: -96.1500 },
    "Marion": { lat: 38.3500, lng: -97.1000 },
    "Marshall": { lat: 39.7833, lng: -96.5333 },
    "McPherson": { lat: 38.3500, lng: -97.6667 },
    "Meade": { lat: 37.2333, lng: -100.3500 },
    "Miami": { lat: 38.5833, lng: -94.8500 },
    "Mitchell": { lat: 39.4167, lng: -98.2000 },
    "Montgomery": { lat: 37.1900, lng: -95.7400 },
    "Morris": { lat: 38.6833, lng: -96.6500 },
    "Morton": { lat: 37.1900, lng: -101.8167 },
    "Nemaha": { lat: 39.7833, lng: -96.0667 },
    "Neosho": { lat: 37.5500, lng: -95.3000 },
    "Ness": { lat: 38.4667, lng: -99.9000 },
    "Norton": { lat: 39.7833, lng: -99.8833 },
    "Osage": { lat: 38.6500, lng: -95.7333 },
    "Osborne": { lat: 39.3500, lng: -98.7667 },
    "Ottawa": { lat: 39.1333, lng: -97.6667 },
    "Pawnee": { lat: 38.1833, lng: -99.2333 },
    "Phillips": { lat: 39.7833, lng: -99.3417 },
    "Pottawatomie": { lat: 39.3333, lng: -96.3333 },
    "Pratt": { lat: 37.6500, lng: -98.7333 },
    "Rawlins": { lat: 39.7833, lng: -101.0833 },
    "Reno": { lat: 37.9500, lng: -98.0833 },
    "Sedgwick": { lat: 37.6846, lng: -97.4611 },
    "Shawnee": { lat: 39.0417, lng: -95.7556 },
    "Wyandotte": { lat: 39.1147, lng: -94.7647 }
  },
  KY: {
    "Adair": { lat: 37.1000, lng: -85.2833 },
    "Allen": { lat: 36.7500, lng: -86.1833 },
    "Anderson": { lat: 38.0000, lng: -84.9833 },
    "Ballard": { lat: 37.0500, lng: -89.0000 },
    "Barren": { lat: 36.9667, lng: -85.9333 },
    "Bath": { lat: 38.1500, lng: -83.7333 },
    "Bell": { lat: 36.7333, lng: -83.6667 },
    "Boone": { lat: 38.9700, lng: -84.7300 },
    "Bourbon": { lat: 38.2000, lng: -84.2167 },
    "Boyd": { lat: 38.3833, lng: -82.6833 },
    "Boyle": { lat: 37.6167, lng: -84.8667 },
    "Bracken": { lat: 38.6833, lng: -84.0833 },
    "Breathitt": { lat: 37.5167, lng: -83.3333 },
    "Breckinridge": { lat: 37.7667, lng: -86.4333 },
    "Bullitt": { lat: 37.9667, lng: -85.7000 },
    "Butler": { lat: 37.2000, lng: -86.6833 },
    "Caldwell": { lat: 37.1500, lng: -87.8667 },
    "Calloway": { lat: 36.6167, lng: -88.2667 },
    "Campbell": { lat: 38.9500, lng: -84.3833 },
    "Carlisle": { lat: 36.8500, lng: -88.9667 },
    "Carroll": { lat: 38.6667, lng: -85.1167 },
    "Carter": { lat: 38.3167, lng: -83.0500 },
    "Casey": { lat: 37.3167, lng: -84.9333 },
    "Christian": { lat: 36.8667, lng: -87.4833 },
    "Clark": { lat: 37.9667, lng: -84.1433 },
    "Clay": { lat: 37.1667, lng: -83.7167 },
    "Clinton": { lat: 36.7167, lng: -85.1333 },
    "Crittenden": { lat: 37.3333, lng: -88.1000 },
    "Cumberland": { lat: 36.7833, lng: -85.3833 },
    "Daviess": { lat: 37.7333, lng: -87.0833 },
    "Edmonson": { lat: 37.2167, lng: -86.2500 },
    "Elliott": { lat: 38.1167, lng: -83.1000 },
    "Estill": { lat: 37.6833, lng: -83.9500 },
    "Fayette": { lat: 38.0406, lng: -84.5033 },
    "Fleming": { lat: 38.3667, lng: -83.7333 },
    "Floyd": { lat: 37.5667, lng: -82.7333 },
    "Franklin": { lat: 38.2333, lng: -84.8667 },
    "Fulton": { lat: 36.5500, lng: -89.2000 },
    "Gallatin": { lat: 38.7500, lng: -84.8667 },
    "Garrard": { lat: 37.6333, lng: -84.5833 },
    "Grant": { lat: 38.6500, lng: -84.6167 },
    "Graves": { lat: 36.7167, lng: -88.6500 },
    "Grayson": { lat: 37.4667, lng: -86.3333 },
    "Green": { lat: 37.2667, lng: -85.5500 },
    "Greenup": { lat: 38.5667, lng: -82.9333 },
    "Hancock": { lat: 37.8333, lng: -86.7833 },
    "Hardin": { lat: 37.7000, lng: -85.9667 },
    "Harlan": { lat: 36.8500, lng: -83.2167 },
    "Harrison": { lat: 38.4333, lng: -84.3333 },
    "Hart": { lat: 37.2833, lng: -85.8833 },
    "Henderson": { lat: 37.7833, lng: -87.5667 },
    "Henry": { lat: 38.4500, lng: -85.1333 },
    "Hickman": { lat: 36.6667, lng: -89.1833 },
    "Hopkins": { lat: 37.3000, lng: -87.5333 },
    "Jackson": { lat: 37.4167, lng: -84.0000 },
    "Jefferson": { lat: 38.1889, lng: -85.6768 },
    "Jessamine": { lat: 37.8833, lng: -84.5833 },
    "Johnson": { lat: 37.8500, lng: -82.8333 },
    "Kenton": { lat: 38.9333, lng: -84.5333 },
    "Knott": { lat: 37.3500, lng: -82.9500 },
    "Knox": { lat: 36.8833, lng: -83.8500 },
    "LaRue": { lat: 37.5500, lng: -85.7000 },
    "Laurel": { lat: 37.1000, lng: -84.1167 },
    "Lawrence": { lat: 38.0667, lng: -82.7333 },
    "Lee": { lat: 37.5833, lng: -83.7167 },
    "Leslie": { lat: 37.0833, lng: -83.3833 },
    "Letcher": { lat: 37.1167, lng: -82.8500 },
    "Lewis": { lat: 38.5333, lng: -83.3667 },
    "Lincoln": { lat: 37.4500, lng: -84.6500 },
    "Livingston": { lat: 37.2167, lng: -88.3500 },
    "Logan": { lat: 36.8500, lng: -86.8833 },
    "Lyon": { lat: 37.0333, lng: -88.0833 },
    "McCracken": { lat: 37.0833, lng: -88.7167 },
    "McCreary": { lat: 36.7333, lng: -84.4833 },
    "McLean": { lat: 37.5333, lng: -87.2667 },
    "Madison": { lat: 37.7167, lng: -84.2833 },
    "Magoffin": { lat: 37.7000, lng: -83.0667 },
    "Marion": { lat: 37.5500, lng: -85.2667 },
    "Marshall": { lat: 36.8833, lng: -88.3333 },
    "Martin": { lat: 37.8167, lng: -82.5167 },
    "Mason": { lat: 38.6000, lng: -83.8167 },
    "Meade": { lat: 37.9667, lng: -86.2000 },
    "Menifee": { lat: 37.9500, lng: -83.6000 },
    "Mercer": { lat: 37.8167, lng: -84.8667 },
    "Metcalfe": { lat: 36.9833, lng: -85.6333 },
    "Monroe": { lat: 36.7167, lng: -85.7167 },
    "Montgomery": { lat: 38.0333, lng: -83.9000 },
    "Morgan": { lat: 37.9167, lng: -83.2500 },
    "Muhlenberg": { lat: 37.2000, lng: -87.1333 },
    "Nelson": { lat: 37.8000, lng: -85.4667 },
    "Nicholas": { lat: 38.3333, lng: -84.0167 },
    "Ohio": { lat: 37.4667, lng: -86.8500 },
    "Oldham": { lat: 38.4000, lng: -85.4333 },
    "Owen": { lat: 38.5167, lng: -84.8333 },
    "Owsley": { lat: 37.4167, lng: -83.6833 },
    "Pendleton": { lat: 38.7000, lng: -84.3500 },
    "Perry": { lat: 37.2500, lng: -83.2167 },
    "Pike": { lat: 37.4667, lng: -82.3933 },
    "Powell": { lat: 37.8333, lng: -83.8333 },
    "Pulaski": { lat: 37.1000, lng: -84.5833 },
    "Robertson": { lat: 38.5167, lng: -84.0500 },
    "Rockcastle": { lat: 37.3667, lng: -84.3167 },
    "Rowan": { lat: 38.1833, lng: -83.4333 },
    "Russell": { lat: 36.9833, lng: -85.0667 },
    "Scott": { lat: 38.2833, lng: -84.5833 },
    "Shelby": { lat: 38.2167, lng: -85.2333 },
    "Simpson": { lat: 36.7333, lng: -86.5833 },
    "Spencer": { lat: 38.0333, lng: -85.3167 },
    "Taylor": { lat: 37.3667, lng: -85.3333 },
    "Todd": { lat: 36.8333, lng: -87.1667 },
    "Trigg": { lat: 36.8000, lng: -87.8667 },
    "Trimble": { lat: 38.6167, lng: -85.3500 },
    "Union": { lat: 37.6500, lng: -87.9500 },
    "Warren": { lat: 36.9933, lng: -86.4233 },
    "Washington": { lat: 37.7500, lng: -85.1833 },
    "Wayne": { lat: 36.8000, lng: -84.8333 },
    "Webster": { lat: 37.5167, lng: -87.6833 },
    "Whitley": { lat: 36.7500, lng: -84.1500 },
    "Wolfe": { lat: 37.7500, lng: -83.4833 },
    "Woodford": { lat: 38.0500, lng: -84.7333 }
  },
  LA: {
    "Acadia": { lat: 30.2833, lng: -92.4167 },
    "Allen": { lat: 30.6500, lng: -92.8167 },
    "Ascension": { lat: 30.2000, lng: -90.9167 },
    "Assumption": { lat: 29.9000, lng: -91.0500 },
    "Avoyelles": { lat: 31.0667, lng: -92.0000 },
    "Beauregard": { lat: 30.7333, lng: -93.3333 },
    "Bienville": { lat: 32.3500, lng: -93.0500 },
    "Bossier": { lat: 32.6667, lng: -93.6000 },
    "Caddo": { lat: 32.5833, lng: -93.8833 },
    "Calcasieu": { lat: 30.2333, lng: -93.3667 },
    "Caldwell": { lat: 32.0833, lng: -92.1167 },
    "Cameron": { lat: 29.8000, lng: -93.1933 },
    "Catahoula": { lat: 31.6667, lng: -91.8500 },
    "Claiborne": { lat: 32.8167, lng: -92.9833 },
    "Concordia": { lat: 31.4500, lng: -91.6333 },
    "De Soto": { lat: 32.0500, lng: -93.7333 },
    "East Baton Rouge": { lat: 30.4507, lng: -91.1547 },
    "East Carroll": { lat: 32.7333, lng: -91.2333 },
    "East Feliciana": { lat: 30.8333, lng: -91.0500 },
    "Evangeline": { lat: 30.7333, lng: -92.4000 },
    "Franklin": { lat: 32.1333, lng: -91.7000 },
    "Grant": { lat: 31.5833, lng: -92.5667 },
    "Iberia": { lat: 29.9833, lng: -91.7500 },
    "Iberville": { lat: 30.2500, lng: -91.3500 },
    "Jackson": { lat: 32.3000, lng: -92.5500 },
    "Jefferson": { lat: 29.7367, lng: -90.0977 },
    "Jefferson Davis": { lat: 30.2667, lng: -92.8167 },
    "Lafayette": { lat: 30.2241, lng: -92.0198 },
    "Lafourche": { lat: 29.4833, lng: -90.4000 },
    "LaSalle": { lat: 31.6667, lng: -92.1667 },
    "Lincoln": { lat: 32.6000, lng: -92.6667 },
    "Livingston": { lat: 30.4333, lng: -90.7333 },
    "Madison": { lat: 32.3667, lng: -91.2333 },
    "Morehouse": { lat: 32.8167, lng: -91.8000 },
    "Natchitoches": { lat: 31.7333, lng: -93.0833 },
    "Orleans": { lat: 29.9511, lng: -90.0715 },
    "Ouachita": { lat: 32.4833, lng: -92.1500 },
    "Plaquemines": { lat: 29.3500, lng: -89.4667 },
    "Pointe Coupee": { lat: 30.7000, lng: -91.6000 },
    "Rapides": { lat: 31.1933, lng: -92.5333 },
    "Red River": { lat: 32.0833, lng: -93.3333 },
    "Richland": { lat: 32.4167, lng: -91.7667 },
    "Sabine": { lat: 31.5667, lng: -93.5500 },
    "St. Bernard": { lat: 29.8667, lng: -89.8500 },
    "St. Charles": { lat: 29.9000, lng: -90.3500 },
    "St. Helena": { lat: 30.8167, lng: -90.8167 },
    "St. James": { lat: 30.0333, lng: -90.7833 },
    "St. John the Baptist": { lat: 30.1167, lng: -90.4833 },
    "St. Landry": { lat: 30.5833, lng: -92.0667 },
    "St. Martin": { lat: 30.1333, lng: -91.6167 },
    "St. Mary": { lat: 29.6833, lng: -91.4333 },
    "St. Tammany": { lat: 30.4107, lng: -89.9567 },
    "Tangipahoa": { lat: 30.6167, lng: -90.4000 },
    "Tensas": { lat: 32.0000, lng: -91.3333 },
    "Terrebonne": { lat: 29.2333, lng: -90.8500 },
    "Union": { lat: 32.8333, lng: -92.3667 },
    "Vermilion": { lat: 29.8500, lng: -92.2833 },
    "Vernon": { lat: 31.1167, lng: -93.1833 },
    "Washington": { lat: 30.8500, lng: -90.0333 },
    "Webster": { lat: 32.7167, lng: -93.3333 },
    "West Baton Rouge": { lat: 30.4667, lng: -91.3167 },
    "West Carroll": { lat: 32.7833, lng: -91.4500 },
    "West Feliciana": { lat: 30.8667, lng: -91.4167 },
    "Winn": { lat: 31.9333, lng: -92.6333 }
  },
  MA: {
    "Essex": { lat: 42.6327, lng: -70.9166 },
    "Middlesex": { lat: 42.4862, lng: -71.3905 },
    "Norfolk": { lat: 42.1609, lng: -71.2000 },
    "Suffolk": { lat: 42.3601, lng: -71.0589 },
    "Worcester": { lat: 42.2626, lng: -71.8023 },
    "Barnstable": { lat: 41.7000, lng: -70.3000 },
    "Berkshire": { lat: 42.3667, lng: -73.2000 },
    "Bristol": { lat: 41.7833, lng: -71.1000 },
    "Dukes": { lat: 41.4000, lng: -70.6167 },
    "Franklin": { lat: 42.5833, lng: -72.5833 },
    "Hampden": { lat: 42.1333, lng: -72.6333 },
    "Hampshire": { lat: 42.3333, lng: -72.6667 },
    "Nantucket": { lat: 41.2833, lng: -70.1000 },
    "Plymouth": { lat: 41.9500, lng: -70.8000 }
  },
  MD: {
    "Allegany": { lat: 39.6217, lng: -78.6987 },
    "Anne Arundel": { lat: 39.0007, lng: -76.6077 },
    "Baltimore": { lat: 39.4027, lng: -76.6017 },
    "Baltimore City": { lat: 39.2907, lng: -76.6127 },
    "Calvert": { lat: 38.5347, lng: -76.5317 },
    "Caroline": { lat: 38.8717, lng: -75.8317 },
    "Carroll": { lat: 39.5637, lng: -77.0247 },
    "Cecil": { lat: 39.6047, lng: -75.9477 },
    "Charles": { lat: 38.4717, lng: -77.0167 },
    "Dorchester": { lat: 38.5917, lng: -76.0817 },
    "Frederick": { lat: 39.4147, lng: -77.4117 },
    "Garrett": { lat: 39.3017, lng: -79.3667 },
    "Harford": { lat: 39.5367, lng: -76.2977 },
    "Howard": { lat: 39.2517, lng: -76.9317 },
    "Kent": { lat: 39.2017, lng: -76.0967 },
    "Montgomery": { lat: 39.1367, lng: -77.2047 },
    "Prince George's": { lat: 38.8297, lng: -76.8477 },
    "Queen Anne's": { lat: 39.0367, lng: -76.0967 },
    "Somerset": { lat: 38.0817, lng: -75.8517 },
    "St. Mary's": { lat: 38.3017, lng: -76.6067 },
    "Talbot": { lat: 38.7517, lng: -76.0967 },
    "Washington": { lat: 39.6017, lng: -77.8117 },
    "Wicomico": { lat: 38.3717, lng: -75.6317 },
    "Worcester": { lat: 38.2117, lng: -75.3817 }
  },
  ME: {
    "Androscoggin": { lat: 44.1657, lng: -70.2037 },
    "Aroostook": { lat: 46.6500, lng: -68.5900 },
    "Cumberland": { lat: 43.8487, lng: -70.2587 },
    "Franklin": { lat: 44.9667, lng: -70.4333 },
    "Hancock": { lat: 44.5500, lng: -68.3667 },
    "Kennebec": { lat: 44.3217, lng: -69.7677 },
    "Knox": { lat: 44.0333, lng: -69.1167 },
    "Lincoln": { lat: 44.0500, lng: -69.5333 },
    "Oxford": { lat: 44.4833, lng: -70.7500 },
    "Penobscot": { lat: 44.9477, lng: -68.7277 },
    "Piscataquis": { lat: 45.5000, lng: -69.2333 },
    "Sagadahoc": { lat: 43.9500, lng: -69.8167 },
    "Somerset": { lat: 45.1167, lng: -69.9500 },
    "Waldo": { lat: 44.4833, lng: -69.1167 },
    "Washington": { lat: 45.0167, lng: -67.3333 },
    "York": { lat: 43.4787, lng: -70.7107 }
  },
  MI: {
    "Alcona": { lat: 44.6833, lng: -83.5833 },
    "Alger": { lat: 46.4167, lng: -86.6000 },
    "Allegan": { lat: 42.5833, lng: -85.8500 },
    "Alpena": { lat: 45.0333, lng: -83.6167 },
    "Antrim": { lat: 45.0000, lng: -85.1500 },
    "Arenac": { lat: 44.0333, lng: -83.8833 },
    "Baraga": { lat: 46.6833, lng: -88.3500 },
    "Barry": { lat: 42.6000, lng: -85.3167 },
    "Bay": { lat: 43.7333, lng: -83.9833 },
    "Benzie": { lat: 44.6333, lng: -86.0000 },
    "Berrien": { lat: 41.9500, lng: -86.4167 },
    "Branch": { lat: 41.9167, lng: -85.0667 },
    "Calhoun": { lat: 42.2500, lng: -85.0000 },
    "Cass": { lat: 41.9167, lng: -86.0000 },
    "Charlevoix": { lat: 45.3167, lng: -85.2500 },
    "Cheboygan": { lat: 45.5000, lng: -84.5000 },
    "Chippewa": { lat: 46.3167, lng: -84.5000 },
    "Clare": { lat: 43.9833, lng: -84.8500 },
    "Clinton": { lat: 42.9500, lng: -84.6000 },
    "Crawford": { lat: 44.6833, lng: -84.6167 },
    "Delta": { lat: 45.9167, lng: -86.9167 },
    "Dickinson": { lat: 46.0000, lng: -87.8667 },
    "Eaton": { lat: 42.5833, lng: -84.8333 },
    "Emmet": { lat: 45.5333, lng: -84.9833 },
    "Gladwin": { lat: 43.9833, lng: -84.3833 },
    "Gogebic": { lat: 46.4167, lng: -89.7000 },
    "Grand Traverse": { lat: 44.7500, lng: -85.5500 },
    "Gratiot": { lat: 43.2917, lng: -84.6000 },
    "Hillsdale": { lat: 41.9000, lng: -84.5833 },
    "Houghton": { lat: 47.0833, lng: -88.5500 },
    "Huron": { lat: 43.8333, lng: -83.0333 },
    "Ingham": { lat: 42.6000, lng: -84.3667 },
    "Ionia": { lat: 42.9417, lng: -85.0667 },
    "Iosco": { lat: 44.3333, lng: -83.6167 },
    "Iron": { lat: 46.0833, lng: -88.5333 },
    "Isabella": { lat: 43.6333, lng: -84.8333 },
    "Jackson": { lat: 42.2500, lng: -84.4167 },
    "Kalamazoo": { lat: 42.2833, lng: -85.5333 },
    "Kalkaska": { lat: 44.6833, lng: -85.0833 },
    "Keweenaw": { lat: 47.4167, lng: -88.1500 },
    "Lake": { lat: 43.9917, lng: -85.8000 },
    "Lapeer": { lat: 43.0917, lng: -83.2200 },
    "Leelanau": { lat: 45.0000, lng: -85.8500 },
    "Lenawee": { lat: 41.8917, lng: -84.0667 },
    "Livingston": { lat: 42.6000, lng: -83.9167 },
    "Luce": { lat: 46.4750, lng: -85.5417 },
    "Mackinac": { lat: 46.0833, lng: -84.7500 },
    "Macomb": { lat: 42.7169, lng: -82.9640 },
    "Manistee": { lat: 44.3333, lng: -86.0833 },
    "Marquette": { lat: 46.4333, lng: -87.6167 },
    "Mason": { lat: 43.9917, lng: -86.2500 },
    "Mecosta": { lat: 43.6417, lng: -85.3167 },
    "Menominee": { lat: 45.5833, lng: -87.5500 },
    "Midland": { lat: 43.6417, lng: -84.3833 },
    "Missaukee": { lat: 44.3333, lng: -85.0833 },
    "Monroe": { lat: 41.9167, lng: -83.5000 },
    "Montcalm": { lat: 43.3167, lng: -85.1500 },
    "Montmorency": { lat: 45.0333, lng: -84.1333 },
    "Muskegon": { lat: 43.2917, lng: -86.1500 },
    "Newaygo": { lat: 43.5500, lng: -85.8000 },
    "Oakland": { lat: 42.6598, lng: -83.3850 },
    "Oceana": { lat: 43.6417, lng: -86.3333 },
    "Ogemaw": { lat: 44.3333, lng: -84.1333 },
    "Ontonagon": { lat: 46.6667, lng: -89.3167 },
    "Osceola": { lat: 43.9917, lng: -85.3167 },
    "Oscoda": { lat: 44.6833, lng: -84.1333 },
    "Otsego": { lat: 45.0333, lng: -84.6167 },
    "Ottawa": { lat: 42.9500, lng: -86.0000 },
    "Presque Isle": { lat: 45.3333, lng: -83.9167 },
    "Roscommon": { lat: 44.3333, lng: -84.6167 },
    "Saginaw": { lat: 43.4200, lng: -84.0300 },
    "St. Clair": { lat: 42.9200, lng: -82.6700 },
    "St. Joseph": { lat: 41.9167, lng: -85.5333 },
    "Sanilac": { lat: 43.4200, lng: -82.8200 },
    "Schoolcraft": { lat: 46.1917, lng: -86.1917 },
    "Shiawassee": { lat: 42.9500, lng: -84.1333 },
    "Tuscola": { lat: 43.4700, lng: -83.4500 },
    "Van Buren": { lat: 42.2833, lng: -86.0833 },
    "Washtenaw": { lat: 42.2500, lng: -83.8333 },
    "Wayne": { lat: 42.3314, lng: -83.0458 }
  },
  MN: {
    "Anoka": { lat: 45.2734, lng: -93.2477 },
    "Dakota": { lat: 44.6713, lng: -93.0625 },
    "Hennepin": { lat: 45.0075, lng: -93.4076 },
    "Ramsey": { lat: 45.0166, lng: -93.0919 },
    "Washington": { lat: 45.0382, lng: -92.8859 },
    "Aitkin": { lat: 46.5333, lng: -93.6333 },
    "Becker": { lat: 46.9333, lng: -95.6667 },
    "Beltrami": { lat: 47.5500, lng: -94.9333 },
    "Benton": { lat: 45.7000, lng: -94.2667 },
    "Big Stone": { lat: 45.4167, lng: -96.4167 },
    "Blue Earth": { lat: 44.0333, lng: -94.0667 },
    "Brown": { lat: 44.2333, lng: -94.7167 },
    "Carlton": { lat: 46.5833, lng: -92.5333 },
    "Carver": { lat: 44.7833, lng: -93.6167 },
    "Cass": { lat: 46.9500, lng: -94.3333 },
    "Chippewa": { lat: 45.0167, lng: -95.5667 },
    "Chisago": { lat: 45.5000, lng: -92.9000 },
    "Clay": { lat: 46.8833, lng: -96.4833 },
    "Clearwater": { lat: 47.5833, lng: -95.4167 },
    "Cook": { lat: 47.8500, lng: -90.5500 },
    "Cottonwood": { lat: 44.0000, lng: -95.1667 },
    "Crow Wing": { lat: 46.4833, lng: -94.0667 },
    "Dodge": { lat: 44.0333, lng: -92.8500 },
    "Douglas": { lat: 45.9333, lng: -95.4500 },
    "Faribault": { lat: 43.6667, lng: -93.9500 },
    "Fillmore": { lat: 43.6667, lng: -92.0833 },
    "Freeborn": { lat: 43.6667, lng: -93.3500 },
    "Goodhue": { lat: 44.5000, lng: -92.7167 },
    "Grant": { lat: 45.9333, lng: -96.0167 },
    "Houston": { lat: 43.6667, lng: -91.5000 },
    "Hubbard": { lat: 47.1167, lng: -94.9167 },
    "Isanti": { lat: 45.5667, lng: -93.3000 },
    "Itasca": { lat: 47.5000, lng: -93.6333 },
    "Jackson": { lat: 43.6667, lng: -95.0000 },
    "Kanabec": { lat: 45.9500, lng: -93.3000 },
    "Kandiyohi": { lat: 45.1500, lng: -95.0000 },
    "Kittson": { lat: 48.7833, lng: -96.7833 },
    "Koochiching": { lat: 48.2500, lng: -93.7833 },
    "Lac qui Parle": { lat: 45.0000, lng: -96.1667 },
    "Lake": { lat: 47.1667, lng: -91.4000 },
    "Lake of the Woods": { lat: 48.7667, lng: -94.9000 },
    "Le Sueur": { lat: 44.3667, lng: -93.7333 },
    "Lincoln": { lat: 44.4167, lng: -96.2667 },
    "Lyon": { lat: 44.4167, lng: -95.8333 },
    "McLeod": { lat: 44.8333, lng: -94.2667 },
    "Mahnomen": { lat: 47.3167, lng: -95.8167 },
    "Marshall": { lat: 48.3500, lng: -96.3667 },
    "Martin": { lat: 43.6667, lng: -94.5500 },
    "Meeker": { lat: 45.1167, lng: -94.5167 },
    "Mille Lacs": { lat: 45.9333, lng: -93.6333 },
    "Morrison": { lat: 46.0167, lng: -94.2667 },
    "Mower": { lat: 43.6667, lng: -92.7500 },
    "Murray": { lat: 44.0167, lng: -95.7667 },
    "Nicollet": { lat: 44.3333, lng: -94.2500 },
    "Nobles": { lat: 43.6667, lng: -95.7667 },
    "Norman": { lat: 47.3333, lng: -96.4667 },
    "Olmsted": { lat: 44.0000, lng: -92.4000 },
    "Otter Tail": { lat: 46.4167, lng: -95.7000 },
    "Pennington": { lat: 48.0667, lng: -96.0333 },
    "Pine": { lat: 46.1167, lng: -92.7500 },
    "Pipestone": { lat: 44.0000, lng: -96.2667 },
    "Polk": { lat: 47.7667, lng: -96.4000 },
    "Pope": { lat: 45.5833, lng: -95.4500 },
    "Red Lake": { lat: 47.8667, lng: -96.1000 },
    "Redwood": { lat: 44.4000, lng: -95.2500 },
    "Renville": { lat: 44.7833, lng: -95.2167 },
    "Rice": { lat: 44.3500, lng: -93.3000 },
    "Rock": { lat: 43.6667, lng: -96.2500 },
    "Roseau": { lat: 48.7667, lng: -95.7667 },
    "St. Louis": { lat: 47.6000, lng: -92.4667 },
    "Scott": { lat: 44.6500, lng: -93.5333 },
    "Sherburne": { lat: 45.4333, lng: -93.7667 },
    "Sibley": { lat: 44.5833, lng: -94.2333 },
    "Stearns": { lat: 45.5500, lng: -94.6167 },
    "Steele": { lat: 44.0167, lng: -93.2333 },
    "Stevens": { lat: 45.6000, lng: -96.0000 },
    "Swift": { lat: 45.2833, lng: -95.6833 },
    "Todd": { lat: 46.0667, lng: -94.9000 },
    "Traverse": { lat: 45.7667, lng: -96.4667 },
    "Wabasha": { lat: 44.2500, lng: -92.2333 },
    "Wadena": { lat: 46.5833, lng: -94.9667 },
    "Waseca": { lat: 44.0667, lng: -93.5833 },
    "Watonwan": { lat: 43.9833, lng: -94.6167 },
    "Wilkin": { lat: 46.3500, lng: -96.4667 },
    "Winona": { lat: 44.0000, lng: -91.7833 },
    "Wright": { lat: 45.1667, lng: -93.9667 },
    "Yellow Medicine": { lat: 44.7167, lng: -95.8667 }
  },
  MO: {
    "Adair": { lat: 40.1917, lng: -92.6000 },
    "Andrew": { lat: 39.9833, lng: -94.8000 },
    "Atchison": { lat: 40.4333, lng: -95.4167 },
    "Clay": { lat: 39.3105, lng: -94.4240 },
    "Jackson": { lat: 39.0086, lng: -94.3461 },
    "St. Charles": { lat: 38.7789, lng: -90.6667 },
    "St. Louis": { lat: 38.6270, lng: -90.1994 },
    "St. Louis City": { lat: 38.6270, lng: -90.1994 },
    "Audrain": { lat: 39.2167, lng: -91.8500 },
    "Barry": { lat: 36.7167, lng: -93.8333 },
    "Barton": { lat: 37.5000, lng: -94.3333 },
    "Bates": { lat: 38.2500, lng: -94.3333 },
    "Benton": { lat: 38.3000, lng: -93.2833 },
    "Bollinger": { lat: 37.3167, lng: -90.0333 },
    "Boone": { lat: 38.9917, lng: -92.3333 },
    "Buchanan": { lat: 39.6500, lng: -94.8500 },
    "Butler": { lat: 36.7167, lng: -90.4667 },
    "Caldwell": { lat: 39.6500, lng: -93.9833 },
    "Callaway": { lat: 38.8333, lng: -91.9167 },
    "Camden": { lat: 38.0333, lng: -92.7667 },
    "Cape Girardeau": { lat: 37.3833, lng: -89.6833 },
    "Carroll": { lat: 39.4167, lng: -93.5000 },
    "Carter": { lat: 36.9500, lng: -90.9667 },
    "Cass": { lat: 38.6500, lng: -94.3500 },
    "Cedar": { lat: 37.7167, lng: -93.8667 },
    "Chariton": { lat: 39.5167, lng: -93.0167 },
    "Christian": { lat: 36.9667, lng: -93.1833 },
    "Clark": { lat: 40.4167, lng: -91.7333 },
    "Clinton": { lat: 39.6000, lng: -94.4000 },
    "Cole": { lat: 38.5000, lng: -92.1667 },
    "Cooper": { lat: 38.8500, lng: -92.8000 },
    "Crawford": { lat: 37.9833, lng: -91.3000 },
    "Dade": { lat: 37.4333, lng: -93.8500 },
    "Dallas": { lat: 37.6833, lng: -93.0167 },
    "Daviess": { lat: 39.9667, lng: -93.9833 },
    "DeKalb": { lat: 39.9000, lng: -94.4000 },
    "Dent": { lat: 37.6000, lng: -91.5000 },
    "Douglas": { lat: 36.9333, lng: -92.5000 },
    "Dunklin": { lat: 36.2667, lng: -90.1000 },
    "Franklin": { lat: 38.4167, lng: -91.0667 },
    "Gasconade": { lat: 38.4333, lng: -91.5000 },
    "Gentry": { lat: 40.2167, lng: -94.4000 },
    "Greene": { lat: 37.2500, lng: -93.3500 },
    "Grundy": { lat: 40.1000, lng: -93.5667 },
    "Harrison": { lat: 40.3500, lng: -93.9833 },
    "Henry": { lat: 41.3500, lng: -90.1333 },
    "Hickory": { lat: 37.9333, lng: -93.3167 },
    "Holt": { lat: 40.1000, lng: -95.2167 },
    "Howard": { lat: 39.1500, lng: -92.7000 },
    "Howell": { lat: 36.7667, lng: -91.8833 },
    "Iron": { lat: 37.5500, lng: -90.7833 },
    "Jasper": { lat: 37.2000, lng: -94.3500 },
    "Jefferson": { lat: 38.2500, lng: -90.5333 },
    "Johnson": { lat: 38.7500, lng: -93.8000 },
    "Knox": { lat: 40.1333, lng: -92.1500 },
    "Laclede": { lat: 37.6500, lng: -92.5833 },
    "Lafayette": { lat: 39.0667, lng: -93.7833 },
    "Lawrence": { lat: 37.1167, lng: -93.8333 },
    "Lewis": { lat: 40.1000, lng: -91.7167 },
    "Lincoln": { lat: 39.0500, lng: -90.9500 },
    "Linn": { lat: 39.8667, lng: -93.1167 },
    "Livingston": { lat: 39.7833, lng: -93.5500 },
    "McDonald": { lat: 36.6167, lng: -94.3500 },
    "Macon": { lat: 39.8333, lng: -92.5667 },
    "Madison": { lat: 37.4667, lng: -90.3500 },
    "Maries": { lat: 38.1667, lng: -91.9167 },
    "Marion": { lat: 39.8000, lng: -91.6333 },
    "Mercer": { lat: 40.4167, lng: -93.5333 },
    "Miller": { lat: 38.2167, lng: -92.4333 },
    "Mississippi": { lat: 36.8167, lng: -89.2833 },
    "Moniteau": { lat: 38.6333, lng: -92.5833 },
    "Monroe": { lat: 39.5000, lng: -92.0000 },
    "Montgomery": { lat: 38.9833, lng: -91.4667 },
    "Morgan": { lat: 38.4167, lng: -92.8833 },
    "New Madrid": { lat: 36.6000, lng: -89.6500 },
    "Newton": { lat: 36.9000, lng: -94.3333 },
    "Nodaway": { lat: 40.3500, lng: -94.8833 },
    "Oregon": { lat: 36.6833, lng: -91.4000 },
    "Osage": { lat: 38.4667, lng: -91.8667 },
    "Ozark": { lat: 36.6500, lng: -92.4500 },
    "Pemiscot": { lat: 36.2167, lng: -89.7833 },
    "Perry": { lat: 37.7167, lng: -89.8333 },
    "Pettis": { lat: 38.7000, lng: -93.2833 },
    "Phelps": { lat: 37.8667, lng: -91.8000 },
    "Pike": { lat: 39.3500, lng: -91.1667 },
    "Platte": { lat: 39.3833, lng: -94.7667 },
    "Polk": { lat: 37.6167, lng: -93.4000 },
    "Pulaski": { lat: 37.8167, lng: -92.2000 },
    "Putnam": { lat: 40.4833, lng: -93.0167 },
    "Ralls": { lat: 39.5333, lng: -91.5167 },
    "Randolph": { lat: 39.4333, lng: -92.5000 },
    "Ray": { lat: 39.3500, lng: -93.9833 },
    "Reynolds": { lat: 37.3667, lng: -90.9667 },
    "Ripley": { lat: 36.6500, lng: -90.8500 },
    "Saline": { lat: 39.1333, lng: -93.2000 },
    "Schuyler": { lat: 40.4667, lng: -92.5167 },
    "Scotland": { lat: 40.4500, lng: -92.1500 },
    "Scott": { lat: 37.2167, lng: -89.5667 },
    "Shannon": { lat: 37.1667, lng: -91.4000 },
    "Shelby": { lat: 39.8000, lng: -92.0667 },
    "St. Clair": { lat: 38.0333, lng: -93.7667 },
    "St. Francois": { lat: 37.8000, lng: -90.4667 },
    "Ste. Genevieve": { lat: 37.9000, lng: -90.2000 },
    "Stoddard": { lat: 36.8500, lng: -89.9500 },
    "Stone": { lat: 36.7500, lng: -93.4667 },
    "Sullivan": { lat: 40.2167, lng: -93.1167 },
    "Taney": { lat: 36.6500, lng: -93.0333 },
    "Texas": { lat: 37.3167, lng: -91.9667 },
    "Vernon": { lat: 37.8500, lng: -94.3500 },
    "Warren": { lat: 38.7667, lng: -91.1667 },
    "Washington": { lat: 38.0500, lng: -90.8833 },
    "Wayne": { lat: 37.1167, lng: -90.4667 },
    "Webster": { lat: 37.2667, lng: -92.8667 },
    "Worth": { lat: 40.4833, lng: -94.4167 },
    "Wright": { lat: 37.2667, lng: -92.4667 }
  },
  MS: {
    "Adams": { lat: 31.4833, lng: -91.3500 },
    "Alcorn": { lat: 34.8833, lng: -88.5833 },
    "Amite": { lat: 31.2500, lng: -90.8333 },
    "Attala": { lat: 33.0833, lng: -89.5833 },
    "Benton": { lat: 34.8083, lng: -89.2000 },
    "Bolivar": { lat: 33.7917, lng: -90.8750 },
    "Calhoun": { lat: 33.9333, lng: -89.3167 },
    "Carroll": { lat: 33.4417, lng: -89.9167 },
    "Chickasaw": { lat: 33.9167, lng: -88.9500 },
    "Choctaw": { lat: 33.3500, lng: -89.2500 },
    "Claiborne": { lat: 31.9667, lng: -90.9167 },
    "Clarke": { lat: 32.0417, lng: -88.6833 },
    "Clay": { lat: 33.6500, lng: -88.7667 },
    "Coahoma": { lat: 34.2250, lng: -90.6000 },
    "Copiah": { lat: 31.8583, lng: -90.4417 },
    "Covington": { lat: 31.6333, lng: -89.5500 },
    "DeSoto": { lat: 34.8750, lng: -89.9917 },
    "Forrest": { lat: 31.1917, lng: -89.2583 },
    "Franklin": { lat: 31.4750, lng: -90.8917 },
    "George": { lat: 30.8583, lng: -88.6417 },
    "Greene": { lat: 31.2167, lng: -88.6417 },
    "Grenada": { lat: 33.7750, lng: -89.8083 },
    "Hancock": { lat: 30.3917, lng: -89.4750 },
    "Harrison": { lat: 30.4167, lng: -89.0833 },
    "Hinds": { lat: 32.2667, lng: -90.1833 },
    "Holmes": { lat: 33.1250, lng: -90.0833 },
    "Humphreys": { lat: 33.1250, lng: -90.5250 },
    "Issaquena": { lat: 32.7417, lng: -90.9917 },
    "Itawamba": { lat: 34.2833, lng: -88.3583 },
    "Jackson": { lat: 30.4250, lng: -88.6250 },
    "Jasper": { lat: 32.0167, lng: -89.1167 },
    "Jefferson": { lat: 31.7333, lng: -91.0333 },
    "Jefferson Davis": { lat: 31.5583, lng: -89.8250 },
    "Jones": { lat: 31.6750, lng: -89.1667 },
    "Kemper": { lat: 32.7583, lng: -88.6417 },
    "Lafayette": { lat: 34.3583, lng: -89.4833 },
    "Lamar": { lat: 31.2083, lng: -89.5167 },
    "Lauderdale": { lat: 32.4000, lng: -88.6833 },
    "Lawrence": { lat: 31.5500, lng: -90.1000 },
    "Leake": { lat: 32.7500, lng: -89.5250 },
    "Lee": { lat: 34.2667, lng: -88.7000 },
    "Leflore": { lat: 33.5500, lng: -90.2917 },
    "Lincoln": { lat: 31.5333, lng: -90.4500 },
    "Lowndes": { lat: 33.4750, lng: -88.4417 },
    "Madison": { lat: 32.6333, lng: -90.0333 },
    "Marion": { lat: 31.2333, lng: -89.8167 },
    "Marshall": { lat: 34.7667, lng: -89.5000 },
    "Monroe": { lat: 33.8917, lng: -88.4833 },
    "Montgomery": { lat: 33.4917, lng: -89.6417 },
    "Neshoba": { lat: 32.7500, lng: -89.1167 },
    "Newton": { lat: 32.4000, lng: -89.1167 },
    "Noxubee": { lat: 33.1083, lng: -88.5583 },
    "Oktibbeha": { lat: 33.4250, lng: -88.8750 },
    "Panola": { lat: 34.3583, lng: -89.9583 },
    "Pearl River": { lat: 30.7583, lng: -89.5833 },
    "Perry": { lat: 31.1667, lng: -88.9917 },
    "Pike": { lat: 31.1750, lng: -90.4000 },
    "Pontotoc": { lat: 34.2417, lng: -89.0333 },
    "Prentiss": { lat: 34.6167, lng: -88.5167 },
    "Quitman": { lat: 34.2500, lng: -90.2917 },
    "Rankin": { lat: 32.2667, lng: -89.9500 },
    "Scott": { lat: 32.4083, lng: -89.5333 },
    "Sharkey": { lat: 32.9000, lng: -90.8500 },
    "Simpson": { lat: 31.9083, lng: -89.9167 },
    "Smith": { lat: 32.0167, lng: -89.5000 },
    "Stone": { lat: 30.7917, lng: -89.1167 },
    "Sunflower": { lat: 33.5917, lng: -90.5833 },
    "Tallahatchie": { lat: 33.9500, lng: -90.1667 },
    "Tate": { lat: 34.6500, lng: -89.9417 },
    "Tippah": { lat: 34.7667, lng: -88.9167 },
    "Tishomingo": { lat: 34.7417, lng: -88.2333 },
    "Tunica": { lat: 34.6500, lng: -90.3750 },
    "Union": { lat: 34.4917, lng: -89.0000 },
    "Walthall": { lat: 31.1500, lng: -90.1000 },
    "Warren": { lat: 32.3500, lng: -90.8500 },
    "Washington": { lat: 33.2833, lng: -90.9417 },
    "Wayne": { lat: 31.6417, lng: -88.6667 },
    "Webster": { lat: 33.6167, lng: -89.2833 },
    "Wilkinson": { lat: 31.1583, lng: -91.3083 },
    "Winston": { lat: 33.0833, lng: -89.0333 },
    "Yalobusha": { lat: 34.0250, lng: -89.7167 },
    "Yazoo": { lat: 32.7750, lng: -90.3917 }
  },
  MT: {
    "Beaverhead": { lat: 45.1333, lng: -122.9000 },
    "Big Horn": { lat: 45.4167, lng: -107.4833 },
    "Blaine": { lat: 48.4333, lng: -108.9500 },
    "Broadwater": { lat: 46.3333, lng: -111.5000 },
    "Carbon": { lat: 45.2333, lng: -109.0167 },
    "Carter": { lat: 45.5167, lng: -104.5167 },
    "Cascade": { lat: 47.3000, lng: -111.3500 },
    "Chouteau": { lat: 47.8833, lng: -110.4333 },
    "Custer": { lat: 46.2500, lng: -105.5667 },
    "Daniels": { lat: 48.7833, lng: -105.2500 },
    "Dawson": { lat: 47.2667, lng: -104.9000 },
    "Deer Lodge": { lat: 46.1167, lng: -113.0667 },
    "Fallon": { lat: 46.8333, lng: -104.4167 },
    "Fergus": { lat: 47.2667, lng: -109.2167 },
    "Flathead": { lat: 48.3000, lng: -114.3000 },
    "Gallatin": { lat: 45.5833, lng: -111.1667 },
    "Garfield": { lat: 47.2667, lng: -106.9833 },
    "Glacier": { lat: 48.7000, lng: -112.9833 },
    "Golden Valley": { lat: 46.3833, lng: -109.1667 },
    "Granite": { lat: 46.4167, lng: -113.4333 },
    "Hill": { lat: 48.6333, lng: -110.1167 },
    "Jefferson": { lat: 46.1500, lng: -112.0333 },
    "Judith Basin": { lat: 47.0333, lng: -110.2667 },
    "Lake": { lat: 47.6500, lng: -114.0833 },
    "Lewis and Clark": { lat: 47.1167, lng: -112.3833 },
    "Liberty": { lat: 48.5500, lng: -111.0333 },
    "Lincoln": { lat: 48.5500, lng: -115.4000 },
    "McCone": { lat: 47.6500, lng: -105.8000 },
    "Madison": { lat: 45.3000, lng: -111.9167 },
    "Meagher": { lat: 46.5833, lng: -110.9167 },
    "Mineral": { lat: 47.1500, lng: -114.9833 },
    "Missoula": { lat: 46.9000, lng: -113.9000 },
    "Musselshell": { lat: 46.5000, lng: -108.4000 },
    "Park": { lat: 45.4167, lng: -110.5167 },
    "Petroleum": { lat: 47.1167, lng: -108.2500 },
    "Phillips": { lat: 48.2500, lng: -107.9167 },
    "Pondera": { lat: 48.2167, lng: -112.2167 },
    "Powder River": { lat: 45.4000, lng: -105.6333 },
    "Powell": { lat: 46.8500, lng: -112.9333 },
    "Prairie": { lat: 46.8333, lng: -105.3667 },
    "Ravalli": { lat: 46.0833, lng: -114.1167 },
    "Richland": { lat: 47.7833, lng: -104.5667 },
    "Roosevelt": { lat: 48.3000, lng: -105.0167 },
    "Rosebud": { lat: 46.2333, lng: -106.7333 },
    "Sanders": { lat: 47.6667, lng: -115.1333 },
    "Sheridan": { lat: 48.7500, lng: -104.5000 },
    "Silver Bow": { lat: 45.9000, lng: -112.6500 },
    "Stillwater": { lat: 45.6667, lng: -109.4000 },
    "Sweet Grass": { lat: 45.8000, lng: -109.9167 },
    "Teton": { lat: 47.8333, lng: -112.2500 },
    "Toole": { lat: 48.6500, lng: -111.7000 },
    "Treasure": { lat: 46.2000, lng: -107.2667 },
    "Valley": { lat: 48.3500, lng: -106.6667 },
    "Wheatland": { lat: 46.4667, lng: -109.8333 },
    "Wibaux": { lat: 46.9667, lng: -104.2500 },
    "Yellowstone": { lat: 45.9333, lng: -108.2667 }
  },
  NC: {
    "Alamance": { lat: 36.0400, lng: -79.4000 },
    "Alexander": { lat: 35.9200, lng: -81.1800 },
    "Alleghany": { lat: 36.4900, lng: -81.1300 },
    "Anson": { lat: 35.0000, lng: -80.1100 },
    "Ashe": { lat: 36.4300, lng: -81.5000 },
    "Avery": { lat: 36.0800, lng: -81.9200 },
    "Beaufort": { lat: 35.4800, lng: -76.8400 },
    "Bertie": { lat: 36.0700, lng: -76.9600 },
    "Bladen": { lat: 34.6100, lng: -78.5600 },
    "Brunswick": { lat: 34.0400, lng: -78.2200 },
    "Buncombe": { lat: 35.6100, lng: -82.5300 },
    "Burke": { lat: 35.7500, lng: -81.7000 },
    "Cabarrus": { lat: 35.3900, lng: -80.5500 },
    "Caldwell": { lat: 35.9500, lng: -81.5500 },
    "Camden": { lat: 36.3400, lng: -76.1700 },
    "Carteret": { lat: 34.8600, lng: -76.5400 },
    "Caswell": { lat: 36.3900, lng: -79.3300 },
    "Catawba": { lat: 35.6600, lng: -81.2100 },
    "Chatham": { lat: 35.7000, lng: -79.2600 },
    "Cherokee": { lat: 35.1300, lng: -84.0600 },
    "Chowan": { lat: 36.1200, lng: -76.6000 },
    "Clay": { lat: 35.0500, lng: -83.7500 },
    "Cleveland": { lat: 35.3300, lng: -81.5500 },
    "Columbus": { lat: 34.2700, lng: -78.6500 },
    "Craven": { lat: 35.1100, lng: -77.0700 },
    "Cumberland": { lat: 35.0488, lng: -78.8280 },
    "Currituck": { lat: 36.4000, lng: -75.9800 },
    "Dare": { lat: 35.6900, lng: -75.8000 },
    "Davidson": { lat: 35.7900, lng: -80.2100 },
    "Davie": { lat: 35.9300, lng: -80.5400 },
    "Duplin": { lat: 34.9300, lng: -77.9300 },
    "Durham": { lat: 35.9800, lng: -78.8800 },
    "Edgecombe": { lat: 35.9100, lng: -77.6000 },
    "Forsyth": { lat: 36.0804, lng: -80.2642 },
    "Franklin": { lat: 36.1300, lng: -78.3000 },
    "Gaston": { lat: 35.2900, lng: -81.1800 },
    "Gates": { lat: 36.4400, lng: -76.7000 },
    "Graham": { lat: 35.3500, lng: -83.8300 },
    "Granville": { lat: 36.3000, lng: -78.6500 },
    "Greene": { lat: 35.4800, lng: -77.6800 },
    "Guilford": { lat: 36.0880, lng: -79.8270 },
    "Halifax": { lat: 36.2500, lng: -77.6500 },
    "Harnett": { lat: 35.3700, lng: -78.8700 },
    "Haywood": { lat: 35.5500, lng: -82.9800 },
    "Henderson": { lat: 35.3300, lng: -82.4800 },
    "Hertford": { lat: 36.3600, lng: -76.9800 },
    "Hoke": { lat: 35.0200, lng: -79.2400 },
    "Hyde": { lat: 35.4000, lng: -76.2500 },
    "Iredell": { lat: 35.8100, lng: -80.8700 },
    "Jackson": { lat: 35.2800, lng: -83.1400 },
    "Johnston": { lat: 35.5200, lng: -78.3600 },
    "Jones": { lat: 35.0200, lng: -77.3500 },
    "Lee": { lat: 35.4700, lng: -79.1700 },
    "Lenoir": { lat: 35.2400, lng: -77.6400 },
    "Lincoln": { lat: 35.4800, lng: -81.2200 },
    "McDowell": { lat: 35.6800, lng: -82.0500 },
    "Macon": { lat: 35.1500, lng: -83.4200 },
    "Madison": { lat: 35.8500, lng: -82.7100 },
    "Martin": { lat: 35.8400, lng: -77.1000 },
    "Mecklenburg": { lat: 35.2072, lng: -80.9568 },
    "Mitchell": { lat: 36.0100, lng: -82.1600 },
    "Montgomery": { lat: 35.3300, lng: -79.9000 },
    "Moore": { lat: 35.3100, lng: -79.4800 },
    "Nash": { lat: 35.9700, lng: -77.9900 },
    "New Hanover": { lat: 34.1800, lng: -77.8600 },
    "Northampton": { lat: 36.4200, lng: -77.4000 },
    "Onslow": { lat: 34.7300, lng: -77.4000 },
    "Orange": { lat: 36.0600, lng: -79.1200 },
    "Pamlico": { lat: 35.1500, lng: -76.6500 },
    "Pasquotank": { lat: 36.3000, lng: -76.2500 },
    "Pender": { lat: 34.5200, lng: -77.9000 },
    "Perquimans": { lat: 36.1800, lng: -76.4100 },
    "Person": { lat: 36.3900, lng: -78.9700 },
    "Pitt": { lat: 35.5900, lng: -77.3700 },
    "Polk": { lat: 35.2800, lng: -82.1700 },
    "Randolph": { lat: 35.7100, lng: -79.8100 },
    "Richland": { lat: 34.9900, lng: -79.7500 },
    "Robeson": { lat: 34.6400, lng: -79.1000 },
    "Rockingham": { lat: 36.4000, lng: -79.7800 },
    "Rowan": { lat: 35.6400, lng: -80.5200 },
    "Rutherford": { lat: 35.4000, lng: -81.9200 },
    "Sampson": { lat: 34.9900, lng: -78.3700 },
    "Scotland": { lat: 34.8400, lng: -79.4800 },
    "Stanly": { lat: 35.3100, lng: -80.2500 },
    "Stokes": { lat: 36.4000, lng: -80.2400 },
    "Surry": { lat: 36.4100, lng: -80.6900 },
    "Swain": { lat: 35.4800, lng: -83.4800 },
    "Transylvania": { lat: 35.2000, lng: -82.8000 },
    "Tyrrell": { lat: 35.8700, lng: -76.1700 },
    "Union": { lat: 34.9900, lng: -80.5300 },
    "Vance": { lat: 36.3600, lng: -78.4100 },
    "Wake": { lat: 35.7796, lng: -78.6382 },
    "Warren": { lat: 36.4000, lng: -78.1000 },
    "Washington": { lat: 35.8400, lng: -76.5700 },
    "Watauga": { lat: 36.2300, lng: -81.7000 },
    "Wayne": { lat: 35.3700, lng: -77.9900 },
    "Wilkes": { lat: 36.2100, lng: -81.1600 },
    "Wilson": { lat: 35.7100, lng: -77.9200 },
    "Yadkin": { lat: 36.1600, lng: -80.6600 },
    "Yancey": { lat: 35.9000, lng: -82.3100 }
  },
  ND: {
    "Adams": { lat: 46.0900, lng: -102.5300 },
    "Barnes": { lat: 46.9400, lng: -98.0700 },
    "Benson": { lat: 48.0700, lng: -99.3700 },
    "Billings": { lat: 47.0200, lng: -103.3800 },
    "Bottineau": { lat: 48.7900, lng: -100.4400 },
    "Bowman": { lat: 46.1100, lng: -103.5200 },
    "Burke": { lat: 48.7900, lng: -102.5200 },
    "Burleigh": { lat: 46.9800, lng: -100.4700 },
    "Cass": { lat: 46.9300, lng: -97.2500 },
    "Cavalier": { lat: 48.7700, lng: -98.4600 },
    "Dickey": { lat: 46.1100, lng: -98.5000 },
    "Divide": { lat: 48.8200, lng: -103.5200 },
    "Dunn": { lat: 47.3500, lng: -102.6200 },
    "Eddy": { lat: 47.7200, lng: -98.9000 },
    "Emmons": { lat: 46.2800, lng: -100.2400 },
    "Foster": { lat: 47.4600, lng: -98.8900 },
    "Golden Valley": { lat: 46.9400, lng: -103.8500 },
    "Grand Forks": { lat: 47.9200, lng: -97.4500 },
    "Grant": { lat: 46.3600, lng: -101.6400 },
    "Griggs": { lat: 47.4600, lng: -98.2300 },
    "Hettinger": { lat: 46.4300, lng: -102.4600 },
    "Kidder": { lat: 46.9800, lng: -99.7800 },
    "LaMoure": { lat: 46.4600, lng: -98.5300 },
    "Logan": { lat: 46.4600, lng: -99.4800 },
    "McHenry": { lat: 48.2300, lng: -100.6300 },
    "McIntosh": { lat: 46.1100, lng: -99.4400 },
    "McKenzie": { lat: 47.7400, lng: -103.4000 },
    "McLean": { lat: 47.6100, lng: -101.3200 },
    "Mercer": { lat: 47.3100, lng: -101.8300 },
    "Morton": { lat: 46.7200, lng: -101.2800 },
    "Mountrail": { lat: 48.2000, lng: -102.3600 },
    "Nelson": { lat: 47.9200, lng: -98.1900 },
    "Oliver": { lat: 47.1200, lng: -101.3400 },
    "Pembina": { lat: 48.7700, lng: -97.5500 },
    "Pierce": { lat: 48.2500, lng: -99.9800 },
    "Ramsey": { lat: 48.2700, lng: -98.7200 },
    "Ransom": { lat: 46.4600, lng: -97.6500 },
    "Renville": { lat: 48.7200, lng: -101.6600 },
    "Richland": { lat: 46.2600, lng: -96.9500 },
    "Rolette": { lat: 48.7700, lng: -99.8400 },
    "Sargent": { lat: 46.1100, lng: -97.6300 },
    "Sheridan": { lat: 47.5800, lng: -100.3400 },
    "Sioux": { lat: 46.1100, lng: -101.0400 },
    "Slope": { lat: 46.4500, lng: -103.4600 },
    "Stark": { lat: 46.8200, lng: -102.6600 },
    "Steele": { lat: 47.4600, lng: -97.7200 },
    "Stutsman": { lat: 46.9200, lng: -98.9600 },
    "Towner": { lat: 48.6900, lng: -99.2500 },
    "Traill": { lat: 47.4500, lng: -97.1600 },
    "Walsh": { lat: 48.3700, lng: -97.7200 },
    "Ward": { lat: 48.2200, lng: -101.5400 },
    "Wells": { lat: 47.5900, lng: -99.6600 },
    "Williams": { lat: 48.3400, lng: -103.4800 }
  },
  NE: {
    "Adams": { lat: 40.5167, lng: -98.5000 },
    "Antelope": { lat: 42.1833, lng: -98.0667 },
    "Arthur": { lat: 41.5667, lng: -101.6833 },
    "Banner": { lat: 41.5500, lng: -103.7167 },
    "Blaine": { lat: 41.9333, lng: -99.9833 },
    "Boone": { lat: 41.7000, lng: -98.0667 },
    "Box Butte": { lat: 42.2167, lng: -103.0833 },
    "Boyd": { lat: 42.9000, lng: -98.7667 },
    "Brown": { lat: 42.4333, lng: -99.9333 },
    "Buffalo": { lat: 40.8500, lng: -99.0750 },
    "Burt": { lat: 41.8500, lng: -96.3333 },
    "Butler": { lat: 41.2333, lng: -97.1333 },
    "Cass": { lat: 40.9083, lng: -96.1417 },
    "Cedar": { lat: 42.6000, lng: -97.2500 },
    "Chase": { lat: 40.5333, lng: -101.7000 },
    "Cherry": { lat: 42.5500, lng: -101.1167 },
    "Cheyenne": { lat: 41.2167, lng: -102.9833 },
    "Clay": { lat: 40.5167, lng: -98.0500 },
    "Colfax": { lat: 41.5667, lng: -97.0833 },
    "Cuming": { lat: 41.9167, lng: -96.7833 },
    "Custer": { lat: 41.3917, lng: -99.7250 },
    "Dakota": { lat: 42.3917, lng: -96.5667 },
    "Dawes": { lat: 42.7167, lng: -103.1333 },
    "Dawson": { lat: 40.8667, lng: -99.8167 },
    "Deuel": { lat: 41.1167, lng: -102.3333 },
    "Dixon": { lat: 42.4833, lng: -96.8500 },
    "Dodge": { lat: 41.5667, lng: -96.6333 },
    "Douglas": { lat: 41.2950, lng: -96.1500 },
    "Dundy": { lat: 40.1833, lng: -101.6833 },
    "Fillmore": { lat: 40.5250, lng: -97.5917 },
    "Franklin": { lat: 40.1750, lng: -98.9500 },
    "Frontier": { lat: 40.5333, lng: -100.4000 },
    "Furnas": { lat: 40.1750, lng: -99.9083 },
    "Gage": { lat: 40.2583, lng: -96.6917 },
    "Garden": { lat: 41.6167, lng: -102.3333 },
    "Garfield": { lat: 41.9000, lng: -98.9833 },
    "Gosper": { lat: 40.5083, lng: -99.8250 },
    "Grant": { lat: 41.9167, lng: -101.7333 },
    "Greeley": { lat: 41.5667, lng: -98.5333 },
    "Hall": { lat: 40.5917, lng: -98.5000 },
    "Hamilton": { lat: 40.8750, lng: -98.0250 },
    "Harlan": { lat: 40.1750, lng: -99.3250 },
    "Hayes": { lat: 40.5250, lng: -101.0167 },
    "Hitchcock": { lat: 40.1750, lng: -101.0167 },
    "Holt": { lat: 42.4500, lng: -98.7833 },
    "Hooker": { lat: 41.9167, lng: -101.1333 },
    "Howard": { lat: 41.2167, lng: -98.5167 },
    "Jefferson": { lat: 40.1750, lng: -97.1583 },
    "Johnson": { lat: 40.3917, lng: -96.2167 },
    "Kearny": { lat: 37.9833, lng: -101.3167 },
    "Kingman": { lat: 37.5500, lng: -98.1333 },
    "Kiowa": { lat: 37.5500, lng: -99.2833 },
    "Labette": { lat: 37.2000, lng: -95.2833 },
    "Lane": { lat: 38.4667, lng: -100.4667 },
    "Leavenworth": { lat: 39.2000, lng: -95.0333 },
    "Lincoln": { lat: 39.0500, lng: -98.1500 },
    "Linn": { lat: 38.2167, lng: -94.8500 },
    "Logan": { lat: 39.0000, lng: -101.1000 },
    "Lyon": { lat: 38.4500, lng: -96.1500 },
    "Marion": { lat: 38.3500, lng: -97.1000 },
    "Marshall": { lat: 39.7833, lng: -96.5333 },
    "McPherson": { lat: 38.3500, lng: -97.6667 },
    "Meade": { lat: 37.2333, lng: -100.3500 },
    "Miami": { lat: 38.5833, lng: -94.8500 },
    "Mitchell": { lat: 39.4167, lng: -98.2000 },
    "Montgomery": { lat: 37.1900, lng: -95.7400 },
    "Morris": { lat: 38.6833, lng: -96.6500 },
    "Morton": { lat: 37.1900, lng: -101.8167 },
    "Nemaha": { lat: 39.7833, lng: -96.0667 },
    "Neosho": { lat: 37.5500, lng: -95.3000 },
    "Ness": { lat: 38.4667, lng: -99.9000 },
    "Norton": { lat: 39.7833, lng: -99.8833 },
    "Osage": { lat: 38.6500, lng: -95.7333 },
    "Osborne": { lat: 39.3500, lng: -98.7667 },
    "Ottawa": { lat: 39.1333, lng: -97.6667 },
    "Pawnee": { lat: 38.1833, lng: -99.2333 },
    "Phillips": { lat: 39.7833, lng: -99.3417 },
    "Pottawatomie": { lat: 39.3333, lng: -96.3333 },
    "Pratt": { lat: 37.6500, lng: -98.7333 },
    "Rawlins": { lat: 39.7833, lng: -101.0833 },
    "Reno": { lat: 37.9500, lng: -98.0833 },
    "Sedgwick": { lat: 37.6846, lng: -97.4611 },
    "Shawnee": { lat: 39.0417, lng: -95.7556 },
    "Wyandotte": { lat: 39.1147, lng: -94.7647 }
  },
  NH: {
    "Belknap": { lat: 43.5167, lng: -71.4167 },
    "Carroll": { lat: 43.8667, lng: -71.1667 },
    "Cheshire": { lat: 42.9167, lng: -72.2500 },
    "Coos": { lat: 44.7000, lng: -71.3000 },
    "Grafton": { lat: 43.9389, lng: -71.8167 },
    "Hillsborough": { lat: 42.9156, lng: -71.5894 },
    "Merrimack": { lat: 43.2972, lng: -71.6806 },
    "Rockingham": { lat: 42.9872, lng: -71.0889 },
    "Strafford": { lat: 43.3278, lng: -71.0306 },
    "Sullivan": { lat: 43.3667, lng: -72.2167 }
  },
  NJ: {
    "Atlantic": { lat: 39.4683, lng: -74.6333 },
    "Bergen": { lat: 40.9597, lng: -74.0741 },
    "Burlington": { lat: 39.8767, lng: -74.6683 },
    "Camden": { lat: 39.9250, lng: -74.9583 },
    "Cape May": { lat: 39.0833, lng: -74.8500 },
    "Cumberland": { lat: 39.3333, lng: -75.1333 },
    "Essex": { lat: 40.7870, lng: -74.2469 },
    "Gloucester": { lat: 39.7167, lng: -75.1333 },
    "Hudson": { lat: 40.7344, lng: -74.0434 },
    "Hunterdon": { lat: 40.5667, lng: -74.9167 },
    "Mercer": { lat: 40.2833, lng: -74.7000 },
    "Middlesex": { lat: 40.4397, lng: -74.4127 },
    "Monmouth": { lat: 40.2600, lng: -74.0000 },
    "Morris": { lat: 40.7667, lng: -74.5000 },
    "Ocean": { lat: 39.8667, lng: -74.2500 },
    "Passaic": { lat: 41.0333, lng: -74.3000 },
    "Salem": { lat: 39.5667, lng: -75.3500 },
    "Somerset": { lat: 40.5667, lng: -74.6167 },
    "Sussex": { lat: 41.0333, lng: -74.6833 },
    "Union": { lat: 40.6667, lng: -74.3000 },
    "Warren": { lat: 40.9167, lng: -74.9833 }
  },
  NM: {
    "Bernalillo": { lat: 35.0517, lng: -106.6707 },
    "Catron": { lat: 33.9167, lng: -108.4167 },
    "Chaves": { lat: 33.3500, lng: -104.4667 },
    "Cibola": { lat: 34.9167, lng: -107.9833 },
    "Colfax": { lat: 36.6000, lng: -104.6500 },
    "Curry": { lat: 34.5667, lng: -103.3500 },
    "De Baca": { lat: 34.3500, lng: -104.2167 },
    "Dona Ana": { lat: 32.3897, lng: -106.8137 },
    "Eddy": { lat: 32.5167, lng: -104.2667 },
    "Grant": { lat: 32.7500, lng: -108.3833 },
    "Guadalupe": { lat: 34.8667, lng: -104.7833 },
    "Harding": { lat: 35.8500, lng: -103.8500 },
    "Hidalgo": { lat: 31.9167, lng: -108.7167 },
    "Lea": { lat: 32.8000, lng: -103.4167 },
    "Lincoln": { lat: 33.7500, lng: -105.4667 },
    "Los Alamos": { lat: 35.8833, lng: -106.3000 },
    "Luna": { lat: 32.1833, lng: -107.7500 },
    "McKinley": { lat: 35.5833, lng: -108.2667 },
    "Mora": { lat: 36.0167, lng: -104.9500 },
    "Otero": { lat: 32.9167, lng: -105.7667 },
    "Quay": { lat: 35.1000, lng: -103.5500 },
    "Rio Arriba": { lat: 36.5167, lng: -106.7000 },
    "Roosevelt": { lat: 34.0167, lng: -103.4833 },
    "Sandoval": { lat: 35.6887, lng: -106.8657 },
    "San Juan": { lat: 36.5167, lng: -108.3167 },
    "San Miguel": { lat: 35.4667, lng: -104.8167 },
    "Santa Fe": { lat: 35.6867, lng: -105.9377 },
    "Sierra": { lat: 33.1333, lng: -107.1833 },
    "Socorro": { lat: 34.0167, lng: -106.9167 },
    "Taos": { lat: 36.5833, lng: -105.6333 },
    "Torrance": { lat: 34.6500, lng: -105.8500 },
    "Union": { lat: 36.4833, lng: -103.4667 },
    "Valencia": { lat: 34.7157, lng: -106.8057 }
  },
  NV: {
    "Carson City": { lat: 39.1638, lng: -119.7674 },
    "Churchill": { lat: 39.5800, lng: -118.7800 },
    "Clark": { lat: 36.2140, lng: -115.0137 },
    "Douglas": { lat: 38.9121, lng: -119.6166 },
    "Elko": { lat: 40.8324, lng: -115.7631 },
    "Esmeralda": { lat: 37.7800, lng: -117.6300 },
    "Eureka": { lat: 39.9800, lng: -116.2700 },
    "Humboldt": { lat: 41.4100, lng: -118.1100 },
    "Lander": { lat: 39.9400, lng: -117.0500 },
    "Lincoln": { lat: 37.6400, lng: -114.8800 },
    "Lyon": { lat: 39.0200, lng: -119.1900 },
    "Mineral": { lat: 38.5400, lng: -118.4400 },
    "Nye": { lat: 38.0400, lng: -117.0300 },
    "Pershing": { lat: 40.4400, lng: -118.4100 },
    "Storey": { lat: 39.3800, lng: -119.5300 },
    "Washoe": { lat: 39.5800, lng: -119.8480 },
    "White Pine": { lat: 39.4400, lng: -114.9000 }
  },
  NY: {
    "Albany": { lat: 42.6000, lng: -73.9700 },
    "Allegany": { lat: 42.2500, lng: -78.0300 },
    "Bronx": { lat: 40.8447, lng: -73.8647 },
    "Broome": { lat: 42.1600, lng: -75.8200 },
    "Kings": { lat: 40.6417, lng: -73.9357 },
    "Nassau": { lat: 40.7247, lng: -73.5937 },
    "New York": { lat: 40.7127, lng: -74.0067 },
    "Queens": { lat: 40.7287, lng: -73.7947 },
    "Richmond": { lat: 40.5797, lng: -74.1507 },
    "Suffolk": { lat: 40.9847, lng: -72.6157 },
    "Westchester": { lat: 41.0317, lng: -73.7677 }
  },
  OH: {
    "Adams": { lat: 38.8500, lng: -83.4700 },
    "Allen": { lat: 40.7700, lng: -84.1100 },
    "Ashland": { lat: 40.8500, lng: -82.2700 },
    "Ashtabula": { lat: 41.8900, lng: -80.7700 },
    "Athens": { lat: 39.3300, lng: -82.0500 },
    "Auglaize": { lat: 40.5600, lng: -84.2200 },
    "Belmont": { lat: 40.0200, lng: -81.0900 },
    "Brown": { lat: 38.9300, lng: -83.8700 },
    "Butler": { lat: 39.4400, lng: -84.5700 },
    "Carroll": { lat: 40.5800, lng: -81.0900 },
    "Champaign": { lat: 40.1400, lng: -83.7700 },
    "Clark": { lat: 39.9200, lng: -83.7800 },
    "Clermont": { lat: 39.0500, lng: -84.1500 },
    "Clinton": { lat: 39.4100, lng: -83.8100 },
    "Columbiana": { lat: 40.7700, lng: -80.7800 },
    "Coshocton": { lat: 40.2700, lng: -81.9200 },
    "Crawford": { lat: 40.8500, lng: -82.9200 },
    "Cuyahoga": { lat: 41.4400, lng: -81.7600 },
    "Darke": { lat: 40.1300, lng: -84.6200 },
    "Defiance": { lat: 41.3200, lng: -84.4900 },
    "Delaware": { lat: 40.2800, lng: -83.0100 },
    "Erie": { lat: 41.3500, lng: -82.6000 },
    "Fairfield": { lat: 39.7500, lng: -82.6300 },
    "Fayette": { lat: 39.5600, lng: -83.4500 },
    "Franklin": { lat: 39.9700, lng: -83.0100 },
    "Fulton": { lat: 41.6000, lng: -84.1300 },
    "Gallia": { lat: 38.8200, lng: -82.3200 },
    "Geauga": { lat: 41.5000, lng: -81.1800 },
    "Greene": { lat: 39.6900, lng: -83.8900 },
    "Guernsey": { lat: 40.0500, lng: -81.4900 },
    "Hamilton": { lat: 39.1900, lng: -84.5400 },
    "Hancock": { lat: 41.0000, lng: -83.6700 },
    "Hardin": { lat: 40.6600, lng: -83.6600 },
    "Harrison": { lat: 40.2900, lng: -81.0900 },
    "Henry": { lat: 41.3300, lng: -84.0700 },
    "Highland": { lat: 39.1800, lng: -83.6000 },
    "Hocking": { lat: 39.5200, lng: -82.4800 },
    "Holmes": { lat: 40.5600, lng: -81.9300 },
    "Huron": { lat: 41.1500, lng: -82.6000 },
    "Jackson": { lat: 39.0500, lng: -82.6400 },
    "Jefferson": { lat: 40.3900, lng: -80.7600 },
    "Knox": { lat: 40.4000, lng: -82.4200 },
    "Lake": { lat: 41.7000, lng: -81.2400 },
    "Lawrence": { lat: 38.6000, lng: -82.5900 },
    "Licking": { lat: 40.0900, lng: -82.4800 },
    "Logan": { lat: 40.3900, lng: -83.7700 },
    "Lorain": { lat: 41.2900, lng: -82.1500 },
    "Lucas": { lat: 41.6800, lng: -83.5000 },
    "Madison": { lat: 39.8900, lng: -83.4000 },
    "Mahoning": { lat: 41.0200, lng: -80.7700 },
    "Marion": { lat: 40.5900, lng: -83.1600 },
    "Medina": { lat: 41.1200, lng: -81.9000 },
    "Meigs": { lat: 39.0800, lng: -82.0200 },
    "Mercer": { lat: 40.5400, lng: -84.6300 },
    "Miami": { lat: 40.0500, lng: -84.2300 },
    "Monroe": { lat: 39.7300, lng: -81.0800 },
    "Montgomery": { lat: 39.7500, lng: -84.2900 },
    "Morgan": { lat: 39.6200, lng: -81.8500 },
    "Morrow": { lat: 40.5200, lng: -82.7900 },
    "Muskingum": { lat: 39.9700, lng: -82.0100 },
    "Noble": { lat: 39.7700, lng: -81.4600 },
    "Ottawa": { lat: 41.5400, lng: -83.1300 },
    "Paulding": { lat: 41.1200, lng: -84.5800 },
    "Perry": { lat: 39.7400, lng: -82.2400 },
    "Pickaway": { lat: 39.6400, lng: -83.0200 },
    "Pike": { lat: 39.0800, lng: -83.0700 },
    "Portage": { lat: 41.1700, lng: -81.2000 },
    "Preble": { lat: 39.7400, lng: -84.6500 },
    "Putnam": { lat: 41.0200, lng: -84.1300 },
    "Richland": { lat: 40.7700, lng: -82.5400 },
    "Ross": { lat: 39.3300, lng: -83.0600 },
    "Sandusky": { lat: 41.3500, lng: -83.1400 },
    "Scioto": { lat: 38.8200, lng: -83.0000 },
    "Seneca": { lat: 41.1200, lng: -83.1300 },
    "Shelby": { lat: 38.2167, lng: -85.2333 },
    "Stark": { lat: 40.8100, lng: -81.3700 },
    "Summit": { lat: 41.1300, lng: -81.5300 },
    "Trumbull": { lat: 41.3200, lng: -80.7600 },
    "Tuscarawas": { lat: 40.4400, lng: -81.4700 },
    "Union": { lat: 40.3000, lng: -83.3700 },
    "Van Wert": { lat: 40.8600, lng: -84.5800 },
    "Vinton": { lat: 39.2500, lng: -82.4800 },
    "Warren": { lat: 39.4300, lng: -84.1600 },
    "Washington": { lat: 39.4600, lng: -81.4900 },
    "Wayne": { lat: 40.8300, lng: -81.8900 },
    "Williams": { lat: 41.5600, lng: -84.5800 },
    "Wood": { lat: 41.3600, lng: -83.6200 },
    "Wyandot": { lat: 40.8400, lng: -83.3000 }
  },
  OK: {
    "Adair": { lat: 35.9000, lng: -94.6500 },
    "Alfalfa": { lat: 36.7300, lng: -98.3200 },
    "Atoka": { lat: 34.3800, lng: -96.0400 },
    "Beaver": { lat: 36.7500, lng: -100.4800 },
    "Beckham": { lat: 35.2700, lng: -99.6600 },
    "Blaine": { lat: 35.8800, lng: -98.4300 },
    "Bryan": { lat: 33.9600, lng: -96.2600 },
    "Caddo": { lat: 35.1700, lng: -98.3800 },
    "Canadian": { lat: 35.5425, lng: -97.9844 },
    "Carter": { lat: 34.2500, lng: -97.2900 },
    "Cherokee": { lat: 35.9100, lng: -95.0000 },
    "Choctaw": { lat: 34.0200, lng: -95.5500 },
    "Cimarron": { lat: 36.7500, lng: -102.5100 },
    "Cleveland": { lat: 35.2033, lng: -97.3250 },
    "Coal": { lat: 34.5900, lng: -96.3000 },
    "Comanche": { lat: 34.6617, lng: -98.4717 },
    "Cotton": { lat: 34.2900, lng: -98.3700 },
    "Craig": { lat: 36.7600, lng: -95.2100 },
    "Creek": { lat: 35.9000, lng: -96.3700 },
    "Custer": { lat: 35.6400, lng: -99.0000 },
    "Delaware": { lat: 36.4100, lng: -94.8000 },
    "Dewey": { lat: 35.9800, lng: -99.0100 },
    "Ellis": { lat: 36.2200, lng: -99.7500 },
    "Garfield": { lat: 36.3800, lng: -97.7800 },
    "Garvin": { lat: 34.7100, lng: -97.3100 },
    "Grady": { lat: 35.0200, lng: -97.8800 },
    "Grant": { lat: 36.8000, lng: -97.7800 },
    "Greer": { lat: 34.9300, lng: -99.5600 },
    "Harmon": { lat: 34.7400, lng: -99.8500 },
    "Harper": { lat: 36.7900, lng: -99.6700 },
    "Haskell": { lat: 35.2200, lng: -95.1100 },
    "Hughes": { lat: 35.0500, lng: -96.2500 },
    "Jackson": { lat: 34.5900, lng: -99.4100 },
    "Jefferson": { lat: 34.1100, lng: -97.8300 },
    "Johnston": { lat: 34.3200, lng: -96.6600 },
    "Kay": { lat: 36.8200, lng: -97.1500 },
    "Kingfisher": { lat: 35.9500, lng: -97.9400 },
    "Kiowa": { lat: 34.9200, lng: -98.9800 },
    "Latimer": { lat: 34.8700, lng: -95.2400 },
    "Le Flore": { lat: 34.9000, lng: -94.7000 },
    "Lincoln": { lat: 35.7000, lng: -96.8800 },
    "Logan": { lat: 35.9200, lng: -97.4400 },
    "Love": { lat: 33.9500, lng: -97.2400 },
    "Major": { lat: 36.3100, lng: -98.5400 },
    "Marshall": { lat: 34.0200, lng: -96.7700 },
    "Mayes": { lat: 36.3000, lng: -95.2300 },
    "McClain": { lat: 35.0100, lng: -97.4400 },
    "McCurtain": { lat: 34.1100, lng: -94.7700 },
    "McIntosh": { lat: 35.3700, lng: -95.6700 },
    "Murray": { lat: 34.4800, lng: -97.0600 },
    "Muskogee": { lat: 35.6200, lng: -95.3800 },
    "Noble": { lat: 36.3900, lng: -97.2300 },
    "Nowata": { lat: 36.7900, lng: -95.6200 },
    "Okfuskee": { lat: 35.4700, lng: -96.3200 },
    "Oklahoma": { lat: 35.5514, lng: -97.4075 },
    "Okmulgee": { lat: 35.6500, lng: -95.9600 },
    "Osage": { lat: 36.6300, lng: -96.4100 },
    "Osborne": { lat: 39.3500, lng: -98.7667 },
    "Ottawa": { lat: 39.1333, lng: -97.6667 },
    "Pawnee": { lat: 38.1833, lng: -99.2333 },
    "Phillips": { lat: 39.7833, lng: -99.3417 },
    "Pottawatomie": { lat: 39.3333, lng: -96.3333 },
    "Pratt": { lat: 37.6500, lng: -98.7333 },
    "Rawlins": { lat: 39.7833, lng: -101.0833 },
    "Reno": { lat: 37.9500, lng: -98.0833 },
    "Sedgwick": { lat: 37.6846, lng: -97.4611 },
    "Shawnee": { lat: 39.0417, lng: -95.7556 },
    "Wyandotte": { lat: 39.1147, lng: -94.7647 }
  },
  OR: {
    "Clackamas": { lat: 45.4000, lng: -122.2200 },
    "Lane": { lat: 44.0521, lng: -123.0868 },
    "Marion": { lat: 44.9212, lng: -122.5826 },
    "Multnomah": { lat: 45.5118, lng: -122.6756 },
    "Washington": { lat: 45.5600, lng: -123.0900 }
  },
  PA: {
    "Adams": { lat: 39.8700, lng: -77.2200 },
    "Allegheny": { lat: 40.4406, lng: -79.9959 },
    "Armstrong": { lat: 40.8100, lng: -79.4600 },
    "Beaver": { lat: 40.6900, lng: -80.3500 },
    "Bedford": { lat: 40.0100, lng: -78.4900 },
    "Berks": { lat: 40.4100, lng: -75.9200 },
    "Blair": { lat: 40.4800, lng: -78.3500 },
    "Bradford": { lat: 41.6400, lng: -76.7100 },
    "Bucks": { lat: 40.3460, lng: -75.0560 },
    "Butler": { lat: 40.9100, lng: -79.9100 },
    "Cambria": { lat: 40.4700, lng: -78.7200 },
    "Cameron": { lat: 41.4400, lng: -78.2000 },
    "Carbon": { lat: 40.9100, lng: -75.7000 },
    "Centre": { lat: 40.8500, lng: -77.8500 },
    "Chester": { lat: 39.9700, lng: -75.7500 },
    "Clarion": { lat: 41.1900, lng: -79.4200 },
    "Clearfield": { lat: 41.0000, lng: -78.4700 },
    "Clinton": { lat: 41.0300, lng: -77.4000 },
    "Columbia": { lat: 41.0500, lng: -76.4000 },
    "Crawford": { lat: 41.6800, lng: -80.1100 },
    "Cumberland": { lat: 40.1600, lng: -77.2600 },
    "Dauphin": { lat: 40.3700, lng: -76.7900 },
    "Delaware": { lat: 39.9078, lng: -75.3879 },
    "Elk": { lat: 41.4300, lng: -78.6500 },
    "Erie": { lat: 42.1200, lng: -80.0900 },
    "Fayette": { lat: 39.9200, lng: -79.6400 },
    "Forest": { lat: 41.5100, lng: -79.2300 },
    "Franklin": { lat: 39.9300, lng: -77.7200 },
    "Fulton": { lat: 39.9200, lng: -78.1100 },
    "Greene": { lat: 39.8500, lng: -80.2200 },
    "Huntingdon": { lat: 40.4800, lng: -77.9800 },
    "Indiana": { lat: 40.6500, lng: -79.0900 },
    "Jefferson": { lat: 41.1200, lng: -78.9900 },
    "Juniata": { lat: 40.5300, lng: -77.4000 },
    "Lackawanna": { lat: 41.4400, lng: -75.6100 },
    "Lancaster": { lat: 40.0400, lng: -76.2500 },
    "Lawrence": { lat: 40.9900, lng: -80.3300 },
    "Lebanon": { lat: 40.3700, lng: -76.4600 },
    "Lehigh": { lat: 40.6100, lng: -75.5900 },
    "Luzerne": { lat: 41.1800, lng: -75.9800 },
    "Lycoming": { lat: 41.3400, lng: -77.0600 },
    "McKean": { lat: 41.8100, lng: -78.5700 },
    "Mercer": { lat: 41.3000, lng: -80.2400 },
    "Mifflin": { lat: 40.6100, lng: -77.6200 },
    "Monroe": { lat: 41.0600, lng: -75.3300 },
    "Montgomery": { lat: 40.1557, lng: -75.2647 },
    "Montour": { lat: 41.0300, lng: -76.6600 },
    "Northampton": { lat: 40.7500, lng: -75.3100 },
    "Northumberland": { lat: 40.8500, lng: -76.7100 },
    "Perry": { lat: 40.4000, lng: -77.2600 },
    "Philadelphia": { lat: 39.9526, lng: -75.1652 },
    "Pike": { lat: 41.3300, lng: -75.0300 },
    "Potter": { lat: 41.7400, lng: -77.9000 },
    "Schuylkill": { lat: 40.7000, lng: -76.1900 },
    "Snyder": { lat: 40.7700, lng: -77.0700 },
    "Somerset": { lat: 40.0100, lng: -79.0300 },
    "Sullivan": { lat: 41.4500, lng: -76.5100 },
    "Susquehanna": { lat: 41.8200, lng: -75.8000 },
    "Tioga": { lat: 41.7700, lng: -77.2500 },
    "Union": { lat: 40.9600, lng: -77.0600 },
    "Venango": { lat: 41.4000, lng: -79.7600 },
    "Warren": { lat: 41.8400, lng: -79.1400 },
    "Washington": { lat: 38.0500, lng: -90.8833 },
    "Wayne": { lat: 37.1167, lng: -90.4667 },
    "Westmoreland": { lat: 40.3100, lng: -79.4700 },
    "Wyoming": { lat: 41.5200, lng: -76.0100 },
    "York": { lat: 39.9200, lng: -76.7300 }
  },
  RI: {
    "Bristol": { lat: 41.6833, lng: -71.2833 },
    "Kent": { lat: 41.7000, lng: -71.5167 },
    "Newport": { lat: 41.5500, lng: -71.2833 },
    "Providence": { lat: 41.8240, lng: -71.4128 },
    "Washington": { lat: 41.4667, lng: -71.6167 }
  },
  SC: {
    "Charleston": { lat: 32.7765, lng: -79.9311 },
    "Greenville": { lat: 34.8526, lng: -82.3940 },
    "Horry": { lat: 33.8361, lng: -79.0559 },
    "Richland": { lat: 34.0007, lng: -81.0348 },
    "Spartanburg": { lat: 34.9493, lng: -81.9320 }
  },
  SD: {
    "Brookings": { lat: 44.3697, lng: -96.7907 },
    "Brown": { lat: 45.5897, lng: -98.3517 },
    "Lincoln": { lat: 43.4667, lng: -96.7333 },
    "Minnehaha": { lat: 43.6747, lng: -96.7917 },
    "Pennington": { lat: 44.0033, lng: -102.8233 }
  },
  TN: {
    "Davidson": { lat: 36.1627, lng: -86.7816 },
    "Hamilton": { lat: 35.1807, lng: -85.1648 },
    "Knox": { lat: 35.9606, lng: -83.9207 },
    "Rutherford": { lat: 35.8427, lng: -86.4167 },
    "Shelby": { lat: 35.1495, lng: -89.9949 }
  },
  TX: {
    "Anderson": { lat: 31.8133, lng: -95.6525 },
    "Andrews": { lat: 32.3189, lng: -102.6378 },
    "Angelina": { lat: 31.2547, lng: -94.6119 },
    "Aransas": { lat: 28.1636, lng: -96.9900 },
    "Armstrong": { lat: 34.9647, lng: -101.3572 },
    "Atascosa": { lat: 28.8936, lng: -98.5272 },
    "Austin": { lat: 29.8847, lng: -96.2778 },
    "Bailey": { lat: 34.0686, lng: -102.8297 },
    "Bandera": { lat: 29.7475, lng: -99.2464 },
    "Bastrop": { lat: 30.1103, lng: -97.3153 },
    "Baylor": { lat: 33.6164, lng: -99.2133 },
    "Bee": { lat: 28.4178, lng: -97.7411 },
    "Bell": { lat: 31.0375, lng: -97.4781 },
    "Bexar": { lat: 29.4247, lng: -98.4937 },
    "Blanco": { lat: 30.2664, lng: -98.3964 },
    "Bowie": { lat: 33.4458, lng: -94.4236 },
    "Brazoria": { lat: 29.1667, lng: -95.4333 },
    "Brazos": { lat: 30.6606, lng: -96.3022 },
    "Brewster": { lat: 29.9997, lng: -103.2514 },
    "Briscoe": { lat: 34.5294, lng: -101.2089 },
    "Brooks": { lat: 27.0325, lng: -98.2186 },
    "Brown": { lat: 31.7742, lng: -98.9997 },
    "Burleson": { lat: 30.4922, lng: -96.6214 },
    "Burnet": { lat: 30.7883, lng: -98.1822 },
    "Caldwell": { lat: 29.8869, lng: -97.6122 },
    "Callahan": { lat: 32.2975, lng: -99.3736 },
    "Cameron": { lat: 26.1508, lng: -97.4250 },
    "Camp": { lat: 32.9736, lng: -94.9786 },
    "Carson": { lat: 35.4036, lng: -101.3542 },
    "Cass": { lat: 33.0436, lng: -94.3425 },
    "Chambers": { lat: 29.7083, lng: -94.6714 },
    "Cherokee": { lat: 31.8364, lng: -95.1653 },
    "Clay": { lat: 33.7858, lng: -98.2089 },
    "Cochran": { lat: 33.6036, lng: -102.8289 },
    "Coke": { lat: 31.8889, lng: -100.5297 },
    "Coleman": { lat: 31.8272, lng: -99.4264 },
    "Collin": { lat: 33.1987, lng: -96.5727 },
    "Collingsworth": { lat: 34.9647, lng: -100.2700 },
    "Colorado": { lat: 29.6208, lng: -96.5264 },
    "Comal": { lat: 29.8083, lng: -98.2772 },
    "Cooke": { lat: 33.6394, lng: -97.2125 },
    "Coryell": { lat: 31.3911, lng: -97.7997 },
    "Cottle": { lat: 34.0778, lng: -100.2736 },
    "Crane": { lat: 31.4275, lng: -102.5178 },
    "Crockett": { lat: 30.7233, lng: -101.4122 },
    "Crosby": { lat: 33.6147, lng: -101.3003 },
    "Dallam": { lat: 36.2778, lng: -102.6028 },
    "Dallas": { lat: 32.7767, lng: -96.7977 },
    "Dawson": { lat: 32.7425, lng: -101.9472 },
    "Deaf Smith": { lat: 34.9667, lng: -102.6000 },
    "Delta": { lat: 33.3858, lng: -95.6722 },
    "Denton": { lat: 33.2147, lng: -97.1307 },
    "DeWitt": { lat: 29.0817, lng: -97.3561 },
    "Dickens": { lat: 33.6217, lng: -100.8364 },
    "Dimmit": { lat: 28.4225, lng: -99.7567 },
    "Duncan": { lat: 32.7358, lng: -101.9478 },
    "Ector": { lat: 31.8692, lng: -102.5425 },
    "Edwards": { lat: 29.9825, lng: -100.3044 },
    "El Paso": { lat: 31.7617, lng: -106.4857 },
    "Ellis": { lat: 32.3483, lng: -96.7944 },
    "Erath": { lat: 32.2361, lng: -98.2178 },
    "Falls": { lat: 31.2533, lng: -96.9358 },
    "Fannin": { lat: 33.5936, lng: -96.1069 },
    "Fayette": { lat: 29.8769, lng: -96.9194 },
    "Fisher": { lat: 32.7397, lng: -100.4014 },
    "Floyd": { lat: 34.0725, lng: -101.3031 },
    "Foard": { lat: 33.9758, lng: -99.7786 },
    "Fort Bend": { lat: 29.6194, lng: -95.7653 },
    "Francis": { lat: 33.1536, lng: -99.2936 },
    "Freestone": { lat: 31.7047, lng: -96.1486 },
    "Frio": { lat: 28.8678, lng: -99.1083 },
    "Gaines": { lat: 32.7414, lng: -102.6350 },
    "Galveston": { lat: 29.3803, lng: -94.9036 },
    "Garza": { lat: 33.1797, lng: -101.2989 },
    "Gillespie": { lat: 30.3183, lng: -98.9467 },
    "Glasscock": { lat: 31.8706, lng: -101.5203 },
    "Goliad": { lat: 28.6569, lng: -97.4264 },
    "Gonzales": { lat: 29.4567, lng: -97.4922 },
    "Gray": { lat: 35.4008, lng: -100.8122 },
    "Grayson": { lat: 33.6258, lng: -96.6775 },
    "Gregg": { lat: 32.4808, lng: -94.8169 },
    "Grimes": { lat: 30.5419, lng: -95.9858 },
    "Guadalupe": { lat: 29.5833, lng: -97.9486 },
    "Hale": { lat: 34.0706, lng: -101.8269 },
    "Hall": { lat: 34.5308, lng: -100.6947 },
    "Haskell": { lat: 33.1781, lng: -99.7303 },
    "Harrison": { lat: 32.5478, lng: -94.3719 },
    "Hartley": { lat: 35.8400, lng: -102.6128 },
    "Hays": { lat: 30.0583, lng: -98.0311 },
    "Hemphill": { lat: 35.8375, lng: -100.2703 },
    "Henderson": { lat: 32.2119, lng: -95.8539 },
    "Hidalgo": { lat: 26.3969, lng: -98.1811 },
    "Hill": { lat: 31.9908, lng: -97.1322 },
    "Hockley": { lat: 33.6075, lng: -102.3431 },
    "Hood": { lat: 32.4303, lng: -97.8322 },
    "Hopkins": { lat: 33.1494, lng: -95.5639 },
    "Houston": { lat: 31.3197, lng: -95.4222 },
    "Howard": { lat: 32.3061, lng: -101.4353 },
    "Hudspeth": { lat: 31.4561, lng: -105.3611 },
    "Hunt": { lat: 33.1236, lng: -96.0858 },
    "Johnson": { lat: 32.3794, lng: -97.3664 },
    "Jones": { lat: 32.7397, lng: -99.8819 },
    "Kaufman": { lat: 32.5989, lng: -96.2875 },
    "Kendall": { lat: 29.9444, lng: -98.7111 },
    "Kenedy": { lat: 26.9389, lng: -97.6389 },
    "Kent": { lat: 33.1814, lng: -100.7772 },
    "Kerr": { lat: 30.0614, lng: -99.3500 },
    "Kimble": { lat: 30.4867, lng: -99.7486 },
    "King": { lat: 33.6164, lng: -100.2556 },
    "Kinney": { lat: 29.3500, lng: -100.4178 },
    "Kleberg": { lat: 27.4333, lng: -97.6667 },
    "Knox": { lat: 33.6064, lng: -99.7414 },
    "Lamar": { lat: 33.6672, lng: -95.5711 },
    "Lampasas": { lat: 31.1961, lng: -98.2414 },
    "La Salle": { lat: 28.3458, lng: -99.0994 },
    "Lavaca": { lat: 29.3842, lng: -96.9300 },
    "Lee": { lat: 30.3106, lng: -96.9658 },
    "Leon": { lat: 31.2967, lng: -95.9958 },
    "Liberty": { lat: 30.1517, lng: -94.8122 },
    "Limestone": { lat: 31.5453, lng: -96.5803 },
    "Lipscomb": { lat: 36.2772, lng: -100.2736 },
    "Live Oak": { lat: 28.3519, lng: -98.1244 },
    "Llano": { lat: 30.7075, lng: -98.6842 },
    "Loving": { lat: 31.8494, lng: -103.5797 },
    "Lubbock": { lat: 33.6100, lng: -101.8208 },
    "Lynn": { lat: 33.1767, lng: -101.8161 },
    "McCulloch": { lat: 31.1994, lng: -99.3475 },
    "McLennan": { lat: 31.5522, lng: -97.2019 },
    "McMullen": { lat: 28.2250, lng: -98.5681 },
    "Madison": { lat: 30.9575, lng: -95.9114 },
    "Marion": { lat: 32.7981, lng: -94.3572 },
    "Martin": { lat: 32.3061, lng: -101.9517 },
    "Mason": { lat: 30.7175, lng: -99.2303 },
    "Matagorda": { lat: 28.8258, lng: -96.0000 },
    "Maverick": { lat: 28.7422, lng: -100.3153 },
    "Medina": { lat: 29.3558, lng: -99.1103 },
    "Menard": { lat: 30.9178, lng: -99.8208 },
    "Midland": { lat: 31.8692, lng: -102.0317 },
    "Milam": { lat: 30.7864, lng: -96.9772 },
    "Mills": { lat: 31.4958, lng: -98.5936 },
    "Mitchell": { lat: 32.3061, lng: -100.9211 },
    "Montague": { lat: 33.6756, lng: -97.7247 },
    "Montgomery": { lat: 30.3003, lng: -95.5031 },
    "Moore": { lat: 35.8375, lng: -101.8931 },
    "Morris": { lat: 33.1136, lng: -94.7325 },
    "Nacogdoches": { lat: 31.6161, lng: -94.6158 },
    "Navarro": { lat: 32.0472, lng: -96.4722 },
    "Newton": { lat: 30.7853, lng: -93.7447 },
    "Nolan": { lat: 32.3036, lng: -100.4061 },
    "Nueces": { lat: 27.7358, lng: -97.5167 },
    "Ochiltree": { lat: 36.2778, lng: -100.8156 },
    "Oldham": { lat: 35.4050, lng: -102.6028 },
    "Orange": { lat: 30.1228, lng: -93.8939 },
    "Palo Pinto": { lat: 32.7531, lng: -98.3131 },
    "Panola": { lat: 32.1622, lng: -94.3056 },
    "Parker": { lat: 32.7775, lng: -97.8050 },
    "Parmer": { lat: 34.5294, lng: -102.7844 },
    "Pecos": { lat: 30.7811, lng: -102.7236 },
    "Polk": { lat: 30.7922, lng: -94.8297 },
    "Potter": { lat: 35.4008, lng: -101.8931 },
    "Presidio": { lat: 30.0000, lng: -104.2417 },
    "Rains": { lat: 32.8700, lng: -95.7933 },
    "Randall": { lat: 34.9658, lng: -101.8972 },
    "Reagan": { lat: 31.3667, lng: -101.5231 },
    "Real": { lat: 29.8319, lng: -99.8222 },
    "Red River": { lat: 33.6206, lng: -95.0503 },
    "Reeves": { lat: 31.3236, lng: -103.6931 },
    "Refugio": { lat: 28.3258, lng: -97.1650 },
    "Roberts": { lat: 35.8383, lng: -100.8136 },
    "Robertson": { lat: 31.0272, lng: -96.5125 },
    "Rockwall": { lat: 32.8972, lng: -96.4078 },
    "Runnels": { lat: 31.8311, lng: -99.9761 },
    "Rusk": { lat: 32.1075, lng: -94.7525 },
    "Sabine": { lat: 31.3433, lng: -93.8514 },
    "San Augustine": { lat: 31.5297, lng: -94.1069 },
    "San Jacinto": { lat: 30.5794, lng: -95.1667 },
    "San Patricio": { lat: 28.0094, lng: -97.5167 },
    "San Saba": { lat: 31.1947, lng: -98.7181 },
    "Schleicher": { lat: 30.8975, lng: -100.5386 },
    "Smith": { lat: 32.3750, lng: -95.2700 },
    "Somervell": { lat: 32.2222, lng: -97.7778 },
    "Starr": { lat: 26.5617, lng: -98.7383 },
    "Stephens": { lat: 32.7358, lng: -98.8361 },
    "Sterling": { lat: 31.8311, lng: -101.0497 },
    "Stonewall": { lat: 33.1781, lng: -100.2536 },
    "Sutton": { lat: 30.4986, lng: -100.5378 },
    "Swisher": { lat: 34.5294, lng: -101.7347 },
    "Tarrant": { lat: 32.7557, lng: -97.3307 },
    "Taylor": { lat: 32.3014, lng: -99.8900 },
    "Terrell": { lat: 30.2244, lng: -102.0767 },
    "Terry": { lat: 33.1797, lng: -102.3344 },
    "Throckmorton": { lat: 33.1778, lng: -99.2122 },
    "Titus": { lat: 33.2319, lng: -94.9708 },
    "Tom Green": { lat: 31.4047, lng: -100.4622 },
    "Travis": { lat: 30.2677, lng: -97.7437 },
    "Trinity": { lat: 31.0903, lng: -95.1358 },
    "Tyler": { lat: 30.7708, lng: -94.3778 },
    "Upshur": { lat: 32.7364, lng: -94.9417 },
    "Upton": { lat: 31.3686, lng: -102.0428 },
    "Val Verde": { lat: 29.8919, lng: -101.1517 },
    "Van Zandt": { lat: 32.5639, lng: -95.8369 },
    "Victoria": { lat: 28.8056, lng: -96.9714 },
    "Walker": { lat: 30.7394, lng: -95.5722 },
    "Waller": { lat: 30.0106, lng: -95.9861 },
    "Ward": { lat: 31.5097, lng: -103.1028 },
    "Washington": { lat: 30.2317, lng: -96.4053 },
    "Webb": { lat: 27.7611, lng: -99.3314 },
    "Wharton": { lat: 29.2778, lng: -96.2222 },
    "Wheeler": { lat: 35.4014, lng: -100.2703 },
    "Williamson": { lat: 30.6483, lng: -97.6008 },
    "Wilson": { lat: 29.1708, lng: -98.0869 },
    "Winkler": { lat: 31.8500, lng: -103.0486 },
    "Wise": { lat: 33.2156, lng: -97.6544 },
    "Wood": { lat: 32.7864, lng: -95.3822 },
    "Yoakum": { lat: 33.1778, lng: -102.8289 },
    "Young": { lat: 33.1767, lng: -98.6878 },
    "Zapata": { lat: 27.0006, lng: -99.1686 },
    "Zavala": { lat: 28.8667, lng: -99.7606 }
  },
  UT: {
    "Davis": { lat: 41.0277, lng: -112.0000 },
    "Salt Lake": { lat: 40.7608, lng: -111.8910 },
    "Utah": { lat: 40.1197, lng: -111.6703 },
    "Washington": { lat: 37.2805, lng: -113.5081 },
    "Weber": { lat: 41.2730, lng: -111.9729 }
  },
  VA: {
    "Accomack": { lat: 37.7672, lng: -75.6323 },
    "Albemarle": { lat: 38.0236, lng: -78.5536 },
    "Alleghany": { lat: 37.7896, lng: -79.9973 },
    "Amelia": { lat: 37.3418, lng: -77.9800 },
    "Amherst": { lat: 37.6026, lng: -79.1468 },
    "Appomattox": { lat: 37.3675, lng: -78.8160 },
    "Arlington": { lat: 38.8787, lng: -77.1017 },
    "Augusta": { lat: 38.1754, lng: -79.1278 },
    "Bath": { lat: 38.0686, lng: -79.7328 },
    "Bedford": { lat: 37.3144, lng: -79.5269 },
    "Bland": { lat: 37.1316, lng: -81.1313 },
    "Botetourt": { lat: 37.5510, lng: -79.8001 },
    "Brunswick": { lat: 36.7649, lng: -77.8632 },
    "Buchanan": { lat: 37.3273, lng: -82.0312 },
    "Buckingham": { lat: 37.5574, lng: -78.5297 },
    "Campbell": { lat: 37.2020, lng: -79.0973 },
    "Caroline": { lat: 38.0296, lng: -77.3463 },
    "Carroll": { lat: 36.7300, lng: -80.7300 },
    "Charles City": { lat: 37.3540, lng: -77.0537 },
    "Charlotte": { lat: 37.0156, lng: -78.6549 },
    "Chesterfield": { lat: 37.3773, lng: -77.5847 },
    "Clarke": { lat: 39.1120, lng: -77.9977 },
    "Craig": { lat: 37.4800, lng: -80.2100 },
    "Culpeper": { lat: 38.4800, lng: -77.9600 },
    "Cumberland": { lat: 37.5173, lng: -78.2442 },
    "Dickenson": { lat: 37.1166, lng: -82.3500 },
    "Dinwiddie": { lat: 37.0700, lng: -77.6300 },
    "Essex": { lat: 37.9300, lng: -76.9400 },
    "Fairfax": { lat: 38.8517, lng: -77.3007 },
    "Fairfax City": { lat: 38.8467, lng: -77.3067 },
    "Falls Church City": { lat: 38.8857, lng: -77.1727 },
    "Fauquier": { lat: 38.7300, lng: -77.8000 },
    "Floyd": { lat: 36.9300, lng: -80.3600 },
    "Fluvanna": { lat: 37.8400, lng: -78.2800 },
    "Franklin": { lat: 36.9900, lng: -79.8800 },
    "Frederick": { lat: 39.2000, lng: -78.2800 },
    "Giles": { lat: 37.3100, lng: -80.7000 },
    "Gloucester": { lat: 37.4000, lng: -76.5300 },
    "Goochland": { lat: 37.7200, lng: -77.9200 },
    "Grayson": { lat: 36.6500, lng: -81.2300 },
    "Greene": { lat: 38.2900, lng: -78.4700 },
    "Greensville": { lat: 36.6800, lng: -77.5600 },
    "Halifax": { lat: 36.7600, lng: -78.9300 },
    "Hanover": { lat: 37.7600, lng: -77.4900 },
    "Henrico": { lat: 37.5300, lng: -77.3900 },
    "Henry": { lat: 36.6800, lng: -79.8800 },
    "Highland": { lat: 38.3500, lng: -79.5600 },
    "Isle of Wight": { lat: 36.9000, lng: -76.7000 },
    "James City": { lat: 37.3100, lng: -76.7700 },
    "King and Queen": { lat: 37.7100, lng: -76.8800 },
    "King George": { lat: 38.2600, lng: -77.1500 },
    "King William": { lat: 37.7000, lng: -77.0000 },
    "Lancaster": { lat: 37.7100, lng: -76.4100 },
    "Lee": { lat: 36.7000, lng: -83.1300 },
    "Loudoun": { lat: 39.0897, lng: -77.6357 },
    "Louisa": { lat: 37.9700, lng: -77.9600 },
    "Lunenburg": { lat: 36.9500, lng: -78.2400 },
    "Madison": { lat: 38.4100, lng: -78.2700 },
    "Mathews": { lat: 37.4400, lng: -76.3300 },
    "Mecklenburg": { lat: 36.6800, lng: -78.3600 },
    "Middlesex": { lat: 37.6100, lng: -76.5500 },
    "Montgomery": { lat: 37.1700, lng: -77.9200 },
    "Nelson": { lat: 37.7900, lng: -78.8800 },
    "New Kent": { lat: 37.5000, lng: -76.9900 },
    "Northampton": { lat: 37.3400, lng: -75.9400 },
    "Northumberland": { lat: 37.8900, lng: -76.4100 },
    "Nottoway": { lat: 37.1300, lng: -78.0500 },
    "Orange": { lat: 38.2400, lng: -78.0100 },
    "Page": { lat: 38.6200, lng: -78.4800 },
    "Patrick": { lat: 36.6800, lng: -80.2800 },
    "Pittsylvania": { lat: 36.8100, lng: -79.3900 },
    "Powhatan": { lat: 37.5500, lng: -77.9200 },
    "Prince Edward": { lat: 37.2200, lng: -78.4300 },
    "Prince George": { lat: 37.1800, lng: -77.2200 },
    "Prince William": { lat: 38.6827, lng: -77.5387 },
    "Pulaski": { lat: 37.0600, lng: -80.7100 },
    "Rappahannock": { lat: 38.6800, lng: -78.1600 },
    "Richmond": { lat: 37.9300, lng: -76.7200 },
    "Roanoke": { lat: 37.2700, lng: -80.0000 },
    "Rockbridge": { lat: 37.8100, lng: -79.4500 },
    "Rockingham": { lat: 38.5100, lng: -78.8700 },
    "Russell": { lat: 36.9300, lng: -82.1000 },
    "Scott": { lat: 36.7100, lng: -82.6000 },
    "Shenandoah": { lat: 38.8500, lng: -78.5700 },
    "Smyth": { lat: 36.8400, lng: -81.5300 },
    "Southampton": { lat: 36.7200, lng: -77.1000 },
    "Spotsylvania": { lat: 38.1800, lng: -77.6500 },
    "Stafford": { lat: 38.4200, lng: -77.4600 },
    "Surry": { lat: 37.1200, lng: -76.8800 },
    "Sussex": { lat: 36.9200, lng: -77.2800 },
    "Tazewell": { lat: 37.1200, lng: -81.5600 },
    "Warren": { lat: 38.9100, lng: -78.2100 },
    "Washington": { lat: 36.7400, lng: -81.9500 },
    "Westmoreland": { lat: 38.1100, lng: -76.8000 },
    "Wise": { lat: 36.9800, lng: -82.6200 },
    "Wythe": { lat: 36.9100, lng: -81.0800 },
    "York": { lat: 37.2400, lng: -76.5400 }
  },
  VT: {
    "Chittenden": { lat: 44.4606, lng: -73.2128 },
    "Rutland": { lat: 43.6106, lng: -73.0294 },
    "Washington": { lat: 44.2733, lng: -72.6156 },
    "Windham": { lat: 42.9906, lng: -72.7139 },
    "Windsor": { lat: 43.5806, lng: -72.5856 }
  },
  WA: {
    "Clark": { lat: 45.7793, lng: -122.4822 },
    "King": { lat: 47.5480, lng: -121.9836 },
    "Pierce": { lat: 47.2529, lng: -122.4443 },
    "Snohomish": { lat: 48.0428, lng: -121.7410 },
    "Spokane": { lat: 47.6205, lng: -117.3642 }
  },
  WI: {
    "Brown": { lat: 44.4527, lng: -88.0000 },
    "Dane": { lat: 43.0731, lng: -89.4012 },
    "Milwaukee": { lat: 43.0389, lng: -87.8152 },
    "Racine": { lat: 42.7261, lng: -87.8152 },
    "Waukesha": { lat: 43.0117, lng: -88.2315 }
  },
  WV: {
    "Berkeley": { lat: 39.4639, lng: -78.0256 },
    "Cabell": { lat: 38.4194, lng: -82.3244 },
    "Kanawha": { lat: 38.3489, lng: -81.6322 },
    "Monongalia": { lat: 39.6333, lng: -80.0500 },
    "Putnam": { lat: 38.5089, lng: -81.9089 }
  },
  WY: {
    "Campbell": { lat: 44.2486, lng: -105.5486 },
    "Fremont": { lat: 43.0406, lng: -108.6306 },
    "Laramie": { lat: 41.3075, lng: -105.5903 },
    "Natrona": { lat: 42.9625, lng: -106.7986 },
    "Sweetwater": { lat: 41.6594, lng: -108.8794 }
  }
};

// Helper function to get coordinates for a specific county
export const getCountyCoordinates = (state: string, county?: string): { lat: number; lng: number } => {
    if (!county) {
        return stateCountyCoordinates[state];
    }
    
    if (!countyCoordinates[state] || !countyCoordinates[state][county]) {
        return stateCountyCoordinates[state];
    }
    return countyCoordinates[state][county];
}; 
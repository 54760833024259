import React from 'react'

import { useNavigate } from 'react-router-dom';
import { useCompleteSignIn, useUpdateAccountDetails } from "../hooks/authhooks";

// import { ResourceCardProps } from "../data/types";
import StarRating from './StarRating';

import { ReactComponent as HeartIcon } from "../icons/HeartIcon.svg";
import { ReactComponent as HeartOutlineIcon } from "../icons/HeartOutlineIcon.svg";
import { ReactComponent as LocationIcon } from "../icons/locationIcon.svg";

import { capitalizeString, getEmailFromToken, getDirectLinkFromGoogleDriveSharedLink, getActionButtonLabel } from '../utils/helpers';
import { ResourceSchema, ResourceType, SearchFilterOptionType } from '../data/types';
import { useLoadCurrentResource } from '../hooks/apphooks';


type ResourceCardProps = {
  resource: ResourceSchema;
  resourceIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  resourceType: ResourceType
  searchFilterOptions?: SearchFilterOptionType
  isMobile?: boolean
  toggleApplyModal: React.Dispatch<React.SetStateAction<ResourceType | undefined>>
  setApplyResource: React.Dispatch<React.SetStateAction<ResourceSchema>>
  // allMemberships: MembershipSchema[]
}

const ResourceCard: React.FC<ResourceCardProps> = ({isMobile, resourceType, resource, searchFilterOptions, resourceIcon: ResourceIcon, toggleApplyModal, setApplyResource}) => {
    // const [showLogo, updateShowLogo] = useState(true) 
    const navigate = useNavigate();

    const { getSavedResourceTruths } = useCompleteSignIn()
    const savedResources = getSavedResourceTruths()
    const setSignInModalIsOpen =  searchFilterOptions?.setSignInModalIsOpen
  
    // console.log("savedResources", savedResources)


    const getBgColor  = () => {
      // let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      // return memberStatus ? getMembershipStatusColor(memberStatus).bgColor : "#bbb"
      return '#fff'
    }

    const getPrimaryTextColor  = () => {
      // let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      // return memberStatus ? getMembershipStatusColor(memberStatus).primaryTextColor : "#202124"
      return '#202124'
    }

    const getSecondaryTextColor  = () => {
      // let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      // return memberStatus ? getMembershipStatusColor(memberStatus).secondaryTextColor : "#696969"
      return '#696969'
    }

    const { currentResource } = useLoadCurrentResource(resource.id ? resource.id : "")
    resource = currentResource ? currentResource : resource

    const handleCardClick = async () => {
      if (resourceType && resource?.id){
        navigate(`/${resourceType}/${resource.id}`)
      }
    }

    const openInNewTab = (url: string) => {
      if (url){
        window.open(url, "_blank");
      }
    };

    const loggedin_username = getEmailFromToken(localStorage.getItem('accessToken'))

    const { saveResource, removeResource } = useUpdateAccountDetails(loggedin_username)

    const handleSaveResource = () => {
      if (resource && resource.id){
        if (!loggedin_username && setSignInModalIsOpen){
          setSignInModalIsOpen(true)
        } else if (loggedin_username) {
          saveResource(resource.id)
        }
      }
    }
  
    const handleRemoveSavedResource = () => {
      if (resource && resource.id){
        removeResource(resource.id)
      }
    }

    var actionButtonLabel = getActionButtonLabel(resourceType)

    if (isMobile){
      return (
          <div  className={`flex flex-col min-h-[200px] object-cover rounded-xl border-[1px] border-[#E9E9E9] w-[360px] mx-auto shadow-sm items-center text-start p-4 bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5`} 
                style={{backgroundColor: getBgColor(), opacity: 0.9}} 
          >

            <div className='flex flex-row '>
              {
                resource.active && (<div className='flex my-2 w-[86px] rounded-2xl bg-[#E7F5EC]'>
                  <p className='m-auto text-[#0F973D] text-[14px] font-raleway font-normal'>Available</p>
                </div>)
              }
            </div>

              <div className='flex flex-col m-2 w-full'>
                <div className='flex flex-row'>
                  <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md mr-auto my-2'>
                    {
                      getDirectLinkFromGoogleDriveSharedLink(resource?.logo) ? (
                        <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(resource.logo)} alt={resource?.id} /> 
                        ) : (
                          <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                          )
                    }
                  </div>
                  <div className='flex border-[1px] border-[#E9E9E9] text-[14px] h-[44px] ml-auto rounded-xl text-primaryDark-0'>
                  {
                    (savedResources && resource && resource?.id && !savedResources[resource.id])? (
                      <div className='flex flex-row text-black px-3 cursor-pointer' onClick={handleSaveResource}>
                        <HeartOutlineIcon className='text-black w-[16px] h-[15px] my-auto' />
                        <p className='mx-2 my-auto'>Save</p>
                      </div>
                    ) : (
                      <div className='flex flex-row text-primaryDark-400 px-3 cursor-pointer' onClick={handleRemoveSavedResource}>
                        <HeartIcon className='text-primaryDark-400 w-[16px] h-[15px] my-auto' />
                        <p className='mx-2 my-auto'>Saved</p>
                      </div>
                    )
                  }
                  </div>
                </div>
                  <p 
                    className='font-raleway font-semibold px-2 text-[18px] my-1 text-primaryDark-0 '
                  >
                    {resource.name}
                  </p>
                  <p className='px-2 font-raleway text-[14px] flex flex-row pt-1 text-[#8A8A8A] mb-4'>
                    <LocationIcon className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
                    {resource && resource.address ? `${resource.address}, `: ``} 
                    {resource && resource.city ? `${resource.city}, `: ``} 
                    {resource && resource.state ? `${resource.state} `: ``}  
                    {resource && resource.zipcode ? `${resource.zipcode} `: ``}  
      
                    {
                      (resource && 
                        !(resource.address && resource.city && resource.state && resource.zipcode)
                      ) ? `Nationwide`: ``
                    }
                  </p>
              </div>
           
              <button 
                onClick={() => openInNewTab(resource?.website ? resource.website : '')}
                className={`text-primaryDark-0 bg-white text-[14px] h-[51px] rounded-lg font-semibold border-[1px] border-[#E9E9E9] w-full mb-3 'h-auto'}`}
              >
                Visit Website
              </button>

              <button onClick={handleCardClick} className={`text-white bg-primaryDark-0 text-[14px] h-[51px] rounded-lg font-semibold border-0 w-full mb-3 ${(resource?.name && resource.name?.length <= 35) ? 'h-[51px]': 'h-auto'}`}>
                View {capitalizeString(resource.name ? resource.name : resourceType)}
              </button>
              {
                  (resource && resource.active && actionButtonLabel) ? (
                    <button 
                      onClick={()=> {
                        if (!loggedin_username && setSignInModalIsOpen !== undefined){
                            setSignInModalIsOpen(true)
                        } else {
                          toggleApplyModal(resourceType)
                          setApplyResource(resource)
                        }
                        

                      }} 
                      className={`text-white bg-primaryDark-300 text-[14px] h-[51px] rounded-lg font-bold border-0 w-full mb-3}`}>
                        {actionButtonLabel}
                    </button>
                  ) : (
                    <></>
                  )
                }
          </div>
          )
    } else {
      return (
        <div className={`flex flex-col ${resource.active ? 'h-[140px] ' : 'h-[120px] sm:h-[112px]'} object-cover rounded-xl border-0 w-[1152px] mx-auto my-auto shadow-sm  text-start p-4 bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5`} 
            style={{backgroundColor: getBgColor(), opacity: 0.9}} 
        >
            <div className='flex flex-row '>
              {
                resource.active && (<div className='flex my-2 w-[86px] rounded-2xl bg-[#E7F5EC]'>
                  <p className='m-auto text-[#0F973D] text-[14px] font-raleway font-normal'>Available</p>
                </div>)
              }
            </div>

            <div className='flex flex-row '>
              <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md'>
                {
                  getDirectLinkFromGoogleDriveSharedLink(resource?.logo) ? (
                    <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(resource.logo)} alt={resource?.id} /> 
                    ) : (
                      <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                      )
                }
              </div>

              <div className='flex flex-col w-2/3 m-2'>
                  <p 
                    className='font-open-sans font-semibold px-2 text-sm sm:text-md'
                    style={{color: getPrimaryTextColor()}}
                  >
                    {resource.name}
                  </p>
                  <p className='px-2 font-open-sans text-xs sm:text-sm flex flex-row pt-1' style={{color: getSecondaryTextColor(), opacity: 0.8}}>
                    <LocationIcon className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
                    {resource && resource.address ? `${resource.address}, `: ``} 
                    {resource && resource.city ? `${resource.city}, `: ``} 
                    {resource && resource.state ? `${resource.state} `: ``}  
                    {resource && resource.zipcode ? `${resource.zipcode} `: ``}  
                  {/* </p>

                  <p className='px-2 font-open-sans text-xs sm:text-sm' style={{color: getSecondaryTextColor(), opacity: 0.8}}> */}
                    {
                      (resource && 
                        !(resource.address && resource.city && resource.state && resource.zipcode)
                      ) ? `Nationwide`: ``
                    }
                  </p>

                  {
                    resource?.rating ? (
                      <div className='flex flex-row flex-start mx-2 my-1 mb-2 p-1'>
                        <StarRating review_rating={resource.rating} />
                      </div>
                    ) : (<></>)
                  }
              </div>
              <div className="flex flex-row gap-2 ml-auto">
                {
                  (savedResources && resource && resource?.id && !savedResources[resource.id])? (
                    <div className='flex flex-row text-black px-3 cursor-pointer' onClick={handleSaveResource}>
                      <HeartOutlineIcon className='text-black w-[16px] h-[15px] my-auto' />
                      <p className='mx-2 my-auto'>Save</p>
                    </div>
                  ) : (
                    <div className='flex flex-row text-primaryDark-400 px-3 cursor-pointer' onClick={handleRemoveSavedResource}>
                      <HeartIcon className='text-primaryDark-400 w-[16px] h-[15px] my-auto' />
                      <p className='mx-2 my-auto'>Saved</p>
                    </div>
                  )
                }
                <button onClick={handleCardClick} className="text-primaryDark-0 font-semibold border-[1px] border-grayDark-100 w-[146px] h-auto my-auto">
                  View {capitalizeString(resourceType)}
                </button>
               
                {
                  (resource && resource.active && actionButtonLabel) ? (
                    <button 
                      onClick={()=> {
                        if (!loggedin_username && setSignInModalIsOpen !== undefined){
                            setSignInModalIsOpen(true)
                        } else {
                          toggleApplyModal(resourceType)
                          setApplyResource(resource)
                        }
                      }} 
                      className="text-white font-semibold bg-primaryDark-0 w-[146px] h-auto my-auto">
                        {actionButtonLabel}
                    </button>
                  ) : (
                    <></>
                  )
                }
              </div>
            </div>
        </div>
      )
    }



  }




export default ResourceCard

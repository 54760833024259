import {useEffect, useState} from 'react'
import { PaymentMethod } from "@stripe/stripe-js";
import { useGetPaymentMethods } from '../../hooks/paymenthooks';
import { OrderItemSchema, ResourceBookingSchema } from '../../data/types';
// import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
// import SavedResourceCard from '../../components/SavedResourceCard';
import { useAddBooking, useCompleteSignIn } from '../../hooks/authhooks'
// import OrderItemCard from '../../components/OrderItemCard';
import CartItemCard, { EditCartItem } from '../../components/CartItemCard';
import BookingPaymentsView from '../../components/BookingPaymentsView';
import { useLoadCurrentResource } from '../../hooks/apphooks';
import LoadingScreen from '../shared/LoadingScreen';




const Cart = () => {
  const { user,  cartItems: cartItemsSaved, updateCart, clearCart } = useCompleteSignIn()
  const { addNewBooking, isLoadingBooking} = useAddBooking()

  const cartItems = Object.values(cartItemsSaved).flat();
  // const allSavedResources: SavedResourceSchema[] = (user && user?.saved_resources) ? user.saved_resources : []

  const [booking, setBooking] = useState<ResourceBookingSchema>({} as ResourceBookingSchema);
  const [width, setWidth] = useState(window.innerWidth);
  const [isEditingQty, setIsEditingQty] = useState<string>()

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }


  const isMobile = width < 1024;

  const loggedinUsername = user?.username ? user?.username : ""
  // const { removeResource } = useUpdateAccountDetails(loggedin_username)

  // const handleRemoveSavedResource = (resource_id: string | undefined) => {
  //   if (resource_id){
  //     removeResource(resource_id)
  //   }
  // }

//   const handleAddToCart = (inventoryItem: any, quantity: number = 1)=> {
//     if (inventoryItem && quantity){
//       const orderItem = inventoryItem as OrderItemSchema
//       orderItem.total_quantity = quantity
//       orderItem.inventory_id = inventoryItem.id
//       updateCartItems(prev => ([
//         ...prev,
//         orderItem
//       ]))
//     }
//   }


//   const handleUpdateCartItem = (item: OrderItemSchema, quantity: number = 0)=> {
//     var cartList = [...cartItems]
//     const itemIndex = cartItems.findIndex(i=>i.item_name === item.item_name)
//     if (itemIndex >= 0 && quantity !== item.total_quantity){
//       item.total_quantity = quantity
//       if (quantity === 0){
//         cartList = cartList.filter(i=> i.item_name !== item.item_name)
//       } else {
//         cartList[itemIndex] = item
//       }
//       updateCartItems(cartList)
//     }
//   }
    var { paymentMethods } = useGetPaymentMethods(loggedinUsername)
    paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
      (paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod
    );

    

    const handlePaymentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      if (name && value) {
        setBooking((prev => ({
          ...prev,
          [name]: value
        })));
      }

      if (name === 'payment_method' && value){
        const selectedMethod = paymentMethods.find((pm:any) => pm.id === value);
        setPaymentMethod(selectedMethod);
      }
    };


    const resourceId: string = (cartItems && cartItems.length > 0 && cartItems[0]?.resource_id) ? cartItems[0]?.resource_id : ""
    const { currentResource, updateResourceId } = useLoadCurrentResource(resourceId)
    
    const handleSubmitOrder = async () => {
      
      // console.log("Resource ID: ", resourceId)
      await updateResourceId(resourceId)

      if (currentResource){
        const {
          name: resourceName, 
          resource_type: resourceType, 
          community_id: communityId,
          city, county, state, address, logo, bgPhoto
        } = currentResource

        // console.log("currentResource: ", currentResource)
      

        // console.log("loggedinUsername && cartItems && cartItems.length > 0 && resourceId && resourceType && communityId: ", loggedinUsername && cartItems && cartItems.length > 0 && resourceId && resourceType && communityId)
      if (loggedinUsername && cartItems && cartItems.length > 0 && resourceId && resourceType && communityId){
        
        // const booking: ResourceBookingSchema = {} as ResourceBookingSchema
        booking.resource_id = resourceId
        booking.resource_name = resourceName
        booking.resource_type = resourceType
        booking.community_id = communityId
        booking.order_inventory = cartItems
        booking.total_cost = parseFloat(cartTotalCost.toFixed(2))
        booking.payment_method = paymentMethod?.id
        booking.resource_city = city
        booking.resource_county = county
        booking.resource_state = state
        booking.resource_address = address
        booking.resource_logo = logo
        booking.resource_bgPhoto = bgPhoto
        booking.community_id = communityId
        booking.username = loggedinUsername
        addNewBooking(booking)
        // console.log("Booking result: ", result)
        // if (result){
        clearCart()
        setBooking({} as ResourceBookingSchema)
        // }

      }
    }
  };

  const handleUpdateCartItem = (resourceId: string, item: OrderItemSchema, quantity: number = 0)=> {
    updateCart(resourceId, item, quantity)
  }


  const cartTotalCost = cartItems.reduce((sum, item) => sum + ((item?.unit_price ? item?.unit_price : 0) * item.total_quantity), 0);

  useEffect(() => {
    document.title = `HomeCook | Cart`
    updateResourceId(resourceId)
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [resourceId, currentResource, updateResourceId]);

  if (isLoadingBooking){
    return (<LoadingScreen height='h-auto' bgColor='inherit' />)
  }
  else if (isMobile){
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-11/12 sm:w-10/12 h-screen overflow-y-scroll relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen w-11/12 bg-[#FFFFFF] rounded-xl  mt-24">
          <h1 className='font-bold text-xl pt-10'>Cart</h1>
  
              {
                (
                    cartItems && cartItems.length > 0) ? cartItems.map((item: any, i: number) => (
                      (
                        (isEditingQty && isEditingQty === item.id) ? (
                          <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                        ) : ( 
                          <CartItemCard key={i} index={i} item={item} handleUpdateCartItem={handleUpdateCartItem} />
                        )
                      )
                    )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No cart items to show
                  </div>
                )
              }
          </div>
        </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-row w-11/12 sm:w-10/12 h-[1059px] relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen w-7/12 bg-[#FFFFFF] rounded-xl  mt-10">
              <h1 className='font-semibold text-[18px] text-[#808080] font-raleway mb-1'>Your Cart Items ({(cartItems && cartItems.length) ? cartItems.length : 0})</h1>

              {
                (
                    cartItems && cartItems.length > 0) ? cartItems.map((item: any, i: number) => (
                      (
                        (isEditingQty && isEditingQty === item.id) ? (
                          <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                        ) : ( 
                          <CartItemCard key={i} index={i} item={item} handleUpdateCartItem={handleUpdateCartItem} />
                        )
                      )
                    )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No cart items to show
                  </div>
                )
              }

          {
            (cartTotalCost) ? (
              <div className="flex flex-col mx-auto w-full my-10">
                  <label className='font-semibold text-[18px] text-[#808080] font-raleway'>
                    Payment Details
                  </label>

                  {(paymentMethods && paymentMethods.length > 0) && (<BookingPaymentsView paymentMethod={paymentMethod} handleSetApplication={handlePaymentChange} />)}
              </div>
            ) : (<></>)
          }
          </div>

          <div className="flex flex-col mx-auto sm:h-screen w-3/12 bg-[#FFFFFF] rounded-xl  mt-10">
              <h1 className='w-full font-semibold text-[18px] text-[#808080] font-raleway'>Cart Summary</h1>

              <div className='flex flex-col w-full h-[140px] text-[14px] font-open-sans border-[#E9E9E9] border-[1px] rounded-md p-2 my-3'>
                <span className='w-11/12 mx-auto flex flex-row'>
                  <p className='text-[#333333] text-[16px]'>
                    Total
                  </p>
                  <p className='text-black font-bold text-[20px] font-raleway ml-auto'>
                    ${cartTotalCost.toFixed(2)}
                  </p>
                </span>
                <button 
                  onClick={handleSubmitOrder}
                  className={`${(cartItems.length < 1  || (!paymentMethod?.id && cartTotalCost > 0 ? true : false)) ? 'bg-gray-300 ' : 'bg-primaryDark-0'} text-white w-11/12 border-0 mx-auto h-[54px] text-[18px] font-raleway font-semibold my-auto`}
                  disabled={cartItems.length < 1  || (!paymentMethod?.id && cartTotalCost > 0 ? true : false)}
                >
                  Checkout
                </button>
              </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}


export default Cart
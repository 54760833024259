import React, { useState } from 'react'
// import moment from 'moment';
import { PaymentMethod } from "@stripe/stripe-js";
// import { getMembershipPlans } from "../api/crud";
// import CancelIcon from '@mui/icons-material/Cancel';
// import { ResourceSchema, TrainingSchema, TrainingOrganizationSchema, ResourceType, ResourceBookingSchema  } from "../data/types";
import { useCompleteSignIn } from '../hooks/authhooks'
import { useGetPaymentMethods } from '../hooks/paymenthooks'
import { capitalizeString, formatDateString } from '../utils/helpers';
import StripePaymentCheckOut from "../molecules/PaymentCheckOut";



type BookingPaymentsViewProps = {
  paymentMethod: PaymentMethod;
  // setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  // React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  // handleSetApplication: (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement> ) => void;
  handleSetApplication: (e: any ) => void;
};

const BookingPaymentsView: React.FC<BookingPaymentsViewProps> = ({
  paymentMethod,
  // setIsEditPay,
  // setPaymentMethod,
  handleSetApplication
}) => {
  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : '';
  var { paymentMethods } = useGetPaymentMethods(loggedin_username);
  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];

  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // const selectedMethod = paymentMethods.find((pm:any) => pm.id === e.target.value);
    handleSetApplication(e);
    // setIsEditPay(false);
  };

  const [isAddPay, setIsAddPay] = useState(false);

  return (
    <div className="flex flex-col w-full h-full text-left my-2">
      <h1 className='cursor-pointer text-primaryDark-400 ml-auto'  onClick={() => setIsAddPay(prev => !prev)}>
        {((!isAddPay) && (paymentMethods && paymentMethods.length > 0)) ? '+ Add New Card' : 'Select Existing Card'}
      </h1>

      {
        (!isAddPay) ? ((paymentMethods && paymentMethods.length > 0) ? 
          (
            <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                <select
                    className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                    name="payment_method"
                    value={paymentMethod?.id}
                    onChange={handlePaymentMethodChange}
                >
                  <option value="">-- Select Existing Card --</option>

                  {
                    paymentMethods.map((paymentMethod: any, i: number)=>
                    (
                      (paymentMethod && paymentMethod?.card) && (
                        <option value={paymentMethod.id} key={i}>
                          <>
                            <>
                              {capitalizeString(paymentMethod?.card?.brand)}
                            </> &nbsp;&nbsp;
                            <>
                              ****{paymentMethod?.card?.last4 || ''}
                            </> &nbsp;&nbsp;
                            <>
                              Expires {formatDateString(paymentMethod?.card?.exp_month, paymentMethod?.card?.exp_year)}
                            </>
                          </>
                        </option>
                      )
                    ))
                  }
                </select>
            </div>
          ) : (
            <p className="font-open-sans font-light text-gray-500 text-sm">No Existing Payment Method</p>
          )
        ) : (
          <div className='my-2'>
            <StripePaymentCheckOut setIsAddingPayMethod={setIsAddPay} username="" mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={true} hasExistingPayMethod={paymentMethods && paymentMethods.length > 0} />
          </div>
        )
      }
    </div>
  );
};



export default BookingPaymentsView

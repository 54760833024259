import axios from 'axios'

import { AccountSchema, MessageSchema, AuthSchema, ResourceSchema, ResourceType, FilterType, CredentialSchema, ResourceBookingSchema, EnvironmentOptions, FeedPostSchema, MembershipSchema, FocusGroupFormData } from "../data/types";
import { objectToFormData } from '../utils/helpers'

import config from '../config';

const new_api_host = config.apiUrl
const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : ""
const apiKey = process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : ""


const axiosInstance = axios.create({
    baseURL: new_api_host,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
    }
})

// Adding API access keys to headers
axiosInstance.defaults.headers.common['HOMECOOK-SECRET-KEY'] = secretKey
axiosInstance.defaults.headers.common['HOMECOOK-API-KEY'] = apiKey



axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized") {
          const refresh_token = localStorage.getItem('refresh_token');

          return axiosInstance
              .post('/kitchens/user/auth', {refresh: refresh_token})
              .then((response) => {

                  let data = (response.data && response.data.body) ? response.data.body : response.data

                  localStorage.setItem('access_token', data.login_token);
                  localStorage.setItem('refresh_token', data.refresh_token);

                  axiosInstance.defaults.headers['Authorization'] = data.login_token
                  originalRequest.headers['Authorization'] = data.login_token

                  return axiosInstance(originalRequest);
              })
              .catch(err => {
                  console.log(err)
              });
      }
      return Promise.reject(error);
  }
)



const getAllResources = async (resource_type?: ResourceType, filter?: FilterType) => {
    let data: ResourceSchema[] | null
    let resource_api_url = `/resource`

    resource_api_url = resource_type ? `${resource_api_url}?resource_type=${resource_type}` : resource_api_url

    let response = await axiosInstance.get(resource_api_url)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        // console.log("allResources data", data)
        return data
    }
    return null
}

// const filterResourcesByType = async () => {
//     let data: CommunitySchema | null
//     let new_user_api_url = `/kitchens/community`
//     let response = await axiosInstance.get(new_user_api_url)
//     if (response){
//         data = (response.data && response.data.body) ? response.data.body : response.data
//         return data
//     }
//     return null
// }

const getResourceByID = async (resourceId: string) => {
    let data: ResourceSchema | null
    if (resourceId){
        let resourceApiUrl = `/resource/${resourceId}`
        let response = await axiosInstance.get(resourceApiUrl)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


const searchResources = async (q: string) => {
    let data: ResourceSchema[] | null
    let searchOption = q ? `?q=${q}` : ""
    let new_user_api_url = `/kitchens/community${searchOption}`
    let response = await axiosInstance.get(new_user_api_url)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
    return null
}



const initiateAuth = async (user: AuthSchema) => {
    let data: any
    let new_user_api_url = `/auth`
    if (user && user?.email){
        user.web_env = process.env.REACT_APP_NODE_ENV as EnvironmentOptions
        localStorage.removeItem('login_token');
        var response = await axiosInstance.post(new_user_api_url, user)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            if (data && data?.login_token){
                localStorage.setItem('login_token', data.login_token)
                // localStorage.setItem('refresh_token', data.refresh_token)
            }
            return data
        }
    }
    return null
}



const getAuthUser = async ({token_uid}: AuthSchema) => {
    let data: AccountSchema | null
    let new_user_api_url = `/auth/${token_uid}`

    if (token_uid){
        // let response = await axiosInstance.post(new_user_api_url, {token_uid})
        let response = await axiosInstance.get(new_user_api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

const getUserDetails = async (username: string) => {
    let data: AccountSchema | null
    let new_user_api_url = `/auth/${username}/user`

    var accessToken: any = localStorage.getItem('accessToken')
    axiosInstance.defaults.headers.common['accessToken'] = accessToken
    
    var refreshToken: any = localStorage.getItem('refreshToken')
    axiosInstance.defaults.headers.common['refreshToken'] = refreshToken

    if (username){
        let response = await axiosInstance.get(new_user_api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// Fetch All Message Threads
const getAllMessages = async (username: string, communityId: string) => {
    let data: MessageSchema[]
    
    if (username){
        // let api_url = `/community/${communityId}/messages`
        communityId = `resource_pantry_${communityId}`
        const apiUrl = `/community/${communityId}/messages/${username}`
        let response = await axiosInstance.get(apiUrl)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return []
}

const sendMessageToUser = async (username: string, communityId: string, content: string) => {
    let data: MessageSchema | null
    communityId = `resource_pantry_${communityId}`
    let apiUrl = `/community/${communityId}/messages/${username}`
  
    if (content){
        let response = await axiosInstance.post(apiUrl, {content})
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}
const updateUserDetails = async (username: string, userUpdates: AccountSchema) => {
    let new_user_api_url = `/auth/${username}/user`

    var accessToken: any = localStorage.getItem('accessToken')
    axiosInstance.defaults.headers.common['accessToken'] = accessToken
    
    var refreshToken: any = localStorage.getItem('refreshToken')
    axiosInstance.defaults.headers.common['refreshToken'] = refreshToken

    const userUpdateForm  = objectToFormData(userUpdates)

    if (username){
        let response = await axiosInstance.patch(new_user_api_url, userUpdateForm, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response){
            const data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


export const addSavedResource = async (username: string, resourceId: string) => {
    let data: ResourceSchema[] | null
    if (username && resourceId){
        let api_url = `/user/${username}/resource/${resourceId}`
        let response = await axiosInstance.post(api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

export const removeSavedResource = async (username: string, resourceId: string) => {
    let data: ResourceSchema[] | null
    if (username && resourceId){
        let api_url = `/user/${username}/resource/${resourceId}`
        let response = await axiosInstance.delete(api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


const verifyUserOTP = async (otp_code: string, username: string) => {
    let data: AccountSchema | null
    let new_user_api_url = `/community/auth/verify`
    // console.log("otp_code, phone", otp_code, username)
  
    if (otp_code){
        let response = await axiosInstance.post(new_user_api_url, {otp_code, username})
        // console.log('response token_username', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}



const generateAIResponse = async (username: string, prompt: string) => { 
    let data: MessageSchema | null
    // let ai_api_url = `/kitchens/ai/text?prompt=${prompt}`
    const communityId = "resource_pantry_chatbot"
    const api_url = `/community/${communityId}/messages/${username}/ai`

    if (prompt){
        let response = await axiosInstance.post(api_url, {content: prompt})
        // console.log('response token_username', response)
        if (response){
            // console.log("generateAIResponse response", response)
            data = (response.data && response.data.body) ? response.data.body : response.data
            // console.log("generateAIResponse data", data)
            return data
        }
    }
    return null
}

export const fetchPaymentMethods = async (username: string) => {
    try {
      if (username){
      let fetch_api_url = `/community/account/${username}/paymethods`
      let response = await axiosInstance.get(fetch_api_url)
        if (response && response.data){
            let data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    return []
}

export const addPaymentSetupIntent = async (username: string) => {
    if (username){
        let fetch_api_url = `/community/setupintent/${username}`
        try {
            let response = await axiosInstance.post(fetch_api_url)
            if (response && response.data){
                console.log("create success");
                let data = (response.data && response.data.body) ? response.data.body : response.data
                return data
            }
        } catch (err) {
            console.log(err);
        }
    }
    return null
}


export const deletePaymentMethod = async (paymentMethodId: string) => {
    if (paymentMethodId){
        let fetch_api_url = `/community/paymethods/${paymentMethodId}`
        try {
            var accessToken: any = localStorage.getItem('accessToken')
            axiosInstance.defaults.headers.common['accessToken'] = accessToken
            
            var refreshToken: any = localStorage.getItem('refreshToken')
            axiosInstance.defaults.headers.common['refreshToken'] = refreshToken

            let response = await axiosInstance.delete(fetch_api_url)
            if (response) {
                console.log("create success");
                return response.data
            }
        } catch (err) {
            console.log(err);
            return null
        }
    }
}


const addMembershipCredentials = async (username: string, cred: CredentialSchema) => {
    const communityId = `resource_pantry`
    if (communityId && username && cred){
      let fetch_api_url = `/community/${communityId}/documents/${username}/verify/new`
      var credForm  = objectToFormData(cred)
      try {
        let response = await axiosInstance.post(fetch_api_url, credForm, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response && response.status === 201) {
          console.log("create success");
          return response.data
        }
      } catch (err) {
        console.log(err);
        return null
      }
    }
  }


  const fetchMemberCredentials = async (username: string) => {
    let data: CredentialSchema[] = []
    const communityId = `resource_pantry`
    try {
      if (communityId){
      let fetch_api_url = `/community/${communityId}/documents/${username}`
      let response = await axiosInstance.get(fetch_api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    return data
  }


  // Fetch Resource Bookings by User
export const getUserResourceBookings = async (username: string) => {
    let data: MessageSchema[]
    if (username){
        const apiUrl =`/user/${username}/bookings`
        const response = await axiosInstance.get(apiUrl)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return []
}

// CREATE NEW RESOURCE BOOKING
export const createBooking = async (new_booking: ResourceBookingSchema) => {
    const { username, resource_id } = new_booking
    console.log(new_booking);
    if (username && resource_id){
        let booking_api_url =`/user/${username}/resource/${resource_id}/booking`
        let response = await axiosInstance.post(booking_api_url, new_booking)
        if (response){
            return response.data
        }
    }
    return null
}

// type KitchenIdParameter = {
//     communityId: string
//     id: string;
// }
  


// Get existing feed posts
const getFeedPosts = async () => {
    let data: FeedPostSchema[] = []
    const communityId = `resource_pantry`
    let fetch_api_url = `/community/${communityId}/feed`
    let response = await axiosInstance.get(fetch_api_url)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
    return null
}

// Create new feed post
const newFeedPost = async (username: string, newPost: FeedPostSchema) => {
    const {content, resource_type} = newPost
    if (username && content && resource_type) {
        const newPostForm  = objectToFormData(newPost)
        const feedPostApi =`/user/${username}/posts`
        const response = await axiosInstance.post(feedPostApi, newPostForm)
        if (response){
            return response.data
        }
    }
    return null
}



// FETCH MEMBERSHIP
export const getMembership = async (communityId: string, username: string) => {
    let data: MembershipSchema | null
    let membershipApiUrl = `/community/${communityId}/members/${username}`
    if (communityId && username){
        let response = await axiosInstance.get(membershipApiUrl)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


export const addCommunityMember = async (communityId: string, membership: MembershipSchema) => {
    if (communityId){
      let membershipApiUrl = `/community/${communityId}/members`;
      try {
        let response = await axiosInstance.post(membershipApiUrl, membership);
        if (response && response.status === 201) {
          console.log("create success");
          return response.data
        }
      } catch (err) {
        console.log(err);
        return null
      }
    }
}


const signOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('login_token');
}
const getMembershipPlans = async (communityId: string) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/plans`;
  
      try{
        let response = await axiosInstance.get(fetch_api_url);
        if (response) {
          let data = response.data;
          return data;
        }
        return []
      }catch(err){
        console.log(err);
      }
    }
  }
  const getResourceOptions = async (communityId: string, resource_id:string) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/resource/${resource_id}`;
  
      try{
        let response = await axiosInstance.get(fetch_api_url);
        if (response) {
          let data = response.data;
          return data;
        }
        return []
      }catch(err){
        console.error(err);
      }
    }
}




export const submitFocusGroupForm = async (formData: FocusGroupFormData, communityId?: string) => {
    let data: FocusGroupFormData | null
    let apiUrl = communityId 
    ? `/focus-group/${communityId}`
    : '/focus-group';
  
    if (formData){
        const response = await axiosInstance.post(apiUrl, formData);
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

export const getFocusGroupData = async (username: string, communityId?: string) => {
    let data: FocusGroupFormData | null
    let apiUrl = communityId 
    ? `/focus-group/${username}/${communityId}`
    : `/focus-group/${username}`;
  
    try {
        const response = await axiosInstance.get(apiUrl);
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    } catch (err) {
        console.error('Error fetching focus group data:', err);
    }
    return null
}

export { 
    initiateAuth, getAuthUser, getUserDetails, updateUserDetails, verifyUserOTP, signOut, 
    getResourceOptions,
    // updateUserData, 
    // getAllCommunities, getCommunityByID, 
    getResourceByID, getAllResources,  searchResources, generateAIResponse,
    // fetchPaymentMethods, addPaymentSetupIntent, addPayIntent,
    // filterResourcesByType
    getAllMessages, sendMessageToUser,
    addMembershipCredentials, 
    fetchMemberCredentials,
    getFeedPosts,
    newFeedPost,
    //  getMemberReviews,
    // createMembership, fetchMemberships, getMembership, updateCommunityMembership, addCommunityMember,
    getMembershipPlans,
    //  createBooking, updateBookingData, fetchMemberBookings, fetchBookingByID, getCommunityBookings
}

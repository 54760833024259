// import  {useEffect } from "react";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
  Marker
} from '@vis.gl/react-google-maps';
import { FilterType, ResourceSchema } from '../data/types';
import { useEffect, useState, useRef } from 'react';
// import { stateCountyCoordinates } from '../utils/enums';
import { getCountyCoordinates } from '../utils/countyCoordinates';
import { capitalizeString } from '../utils/helpers';

const googleMapsApiKey = process.env.REACT_APP_GOOGLEMAPS_API_KEY_PROD;

// Custom styles for the map
const mapStyles = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      { visibility: "on" },
      { lightness: 20 }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  }
];

type GoogleMapsProps = {
  currentFilter: FilterType;
  setCurrentFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  resources?: ResourceSchema[];
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ currentFilter, setCurrentFilter, resources = [] }) => {
  const { state, county } = currentFilter;
  const countyStateCoordinates = getCountyCoordinates(state, county);
  const [center, setCenter] = useState<{ lat: number; lng: number }>(countyStateCoordinates);
  const [markers, setMarkers] = useState<Array<{ position: { lat: number; lng: number }; title: string; label: string }>>([]);
  
  // Create marker icon configuration using a ref to avoid recreating it on every render
  const markerIcon = useRef({
    url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    size: { width: 28, height: 28 },
    anchor: { x: 14, y: 28 },
    labelOrigin: { x: 14, y: -10 }
  });

  useEffect(() => {
    setCenter(countyStateCoordinates);
  }, [state, countyStateCoordinates]);

  useEffect(() => {
    const geocodeLocations = async () => {
      const newMarkers = await Promise.all(
        resources.map(async (resource) => {
          try {
            const address = `${resource.address}, ${resource.city}, ${resource.state} ${resource.zipcode}`;
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`
            );
            const data = await response.json();

            if (data.results && data.results.length > 0) {
              const { lat, lng } = data.results[0].geometry.location;
              return {
                position: { lat, lng },
                title: `${resource.name} (${resource.city}, ${resource.state})`,
                label: `${resource.name} (${capitalizeString(resource.resource_type || '')})`,
              };
            }
            return null;
          } catch (error) {
            console.error('Error geocoding location:', error);
            return null;
          }
        })
      );

      setMarkers(newMarkers.filter((marker): marker is { position: { lat: number; lng: number }; title: string; label: string } => marker !== null));
    };

    if (resources.length > 0) {
      geocodeLocations();
    }
  }, [resources]);

  const handleCameraChanged = async (ev: MapCameraChangedEvent) => {
    const newCenter = ev.detail.center;
    // setCenter(newCenter);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newCenter.lat},${newCenter.lng}&key=${googleMapsApiKey}`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        let newState = '';
        let newCounty = '';

        // Find state and county from address components
        for (const component of addressComponents) {
          if (component.types.includes('administrative_area_level_1')) {
            newState = component.short_name;
          }
          if (component.types.includes('administrative_area_level_2')) {
            newCounty = component.long_name.replace(' County', '');
          }
        }

        console.log('newCounty', newCounty, newState);

        if (newState) {
          // Only update if we found state 
          setCurrentFilter(prev => ({
            ...prev,
            state: newState,
          }));
        } 
      }
    } catch (error) {
      console.error('Error geocoding coordinates:', error);
    }
  };

  return (
    <div className='h-[200px] mb-4'>
      <APIProvider apiKey={googleMapsApiKey as string}>
        {center && (
          <Map
            defaultZoom={county ? 13 : 8}
            defaultCenter={center}
            onCameraChanged={handleCameraChanged}
            styles={mapStyles}
            // disableDefaultUI={false}
            // zoomControl={true}
            mapTypeControl={false}
            // streetViewControl={false}
            // fullscreenControl={false}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                title={marker.title}
                label={{
                  text: marker.label,
                  className: "marker-label",
                  color: "#013847",
                  fontSize: "13px",
                  fontWeight: "bold",
                  fontFamily: "system-ui, -apple-system, sans-serif"
                }}
                icon={markerIcon.current.url}
                optimized={true}
                clickable={true}
                zIndex={1}
              />
            ))}
          </Map>
        )}
      </APIProvider>
    </div>
  );
}

export default GoogleMaps;



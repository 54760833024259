import { useQuery, useMutation} from '@tanstack/react-query'
// import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import { auth } from '../auth/firebase';
import {  initiateAuth, verifyUserOTP, getAuthUser, 
    getUserDetails, updateUserDetails, 
    addCommunityMember, getMembership, 
    addSavedResource, removeSavedResource, createBooking,
    addMembershipCredentials, fetchMemberCredentials, 
    newFeedPost, getFeedPosts, signOut, 
    getUserResourceBookings} from '../api/crud'
import { AccountSchema, AuthSchema, CredentialSchema, MembershipSchema, OrderItemSchema, ResourceBookingSchema, FeedPostSchema } from "../data/types";
import { useAuthState } from '../contexts/authcontext'
import { getUIDFromToken, getEmailFromToken } from '../utils/helpers';
import { useEffect, useState } from 'react';



const staleTime = 3000
const isEnabled = true


// get user details from access token detail
export const useLoadAuthUser = () => {
    const { userData, updateUserData, updateLogin, updateVerified } = useAuthState()


    const registerUserMutation = useMutation({ 
        mutationFn: async (user: AuthSchema) => {
            console.log("register user")
            const res = await initiateAuth(user)
            return res
    }})


    const verifyUserMutation = useMutation({ 
        mutationFn: async (otp: string) => {
            console.log("verify user")
            if (userData?.username){const res = await verifyUserOTP(otp,userData.username)
            if (res && Object.keys(res).length !== 0 && updateUserData && updateLogin && updateVerified){
                if (res?.login_token && res?.refresh_token){
                    localStorage.setItem('access_token', res.login_token)
                    localStorage.setItem('refresh_token', res.refresh_token)
                }
                updateUserData(res)
                updateLogin(true)
                if (res.auth_status){
                    updateVerified(res.auth_status) 
                    return true
                }
            }}
            return false
    }})

    const registerUser = (user: AuthSchema) => {
        registerUserMutation.mutate(user)
        return registerUserMutation?.data ? true : false
    }


    const verifyUser = (otp: string) => {
        verifyUserMutation.mutate(otp)
        return verifyUserMutation?.data ? true : false
    } 

    const signOutUser = () => {
        console.log("signout")
        signOut()
        if (updateUserData && updateLogin && updateVerified){
            updateUserData({} as AccountSchema)
            updateLogin(false)
            updateVerified(false)
        }
    }
    
    const isLoading = (
        // loadAuthMutation.isPending || 
        registerUserMutation.isPending ||  
        verifyUserMutation.isPending
    );

    return { registerUser, verifyUser, signOutUser, isLoading }
}




export const useGetAuthUser = () => {
    // const { authUser, updateAuthUser, updateLogin, updateVerified } = useAuthState()

    var login_token: any = localStorage.getItem('login_token')
    var token_uid: string = getUIDFromToken(login_token)

    const {data: authUser, refetch: reloadAuthUser,  isLoading: isLoadingAuthUser} = useQuery({ 
        queryKey: ['authUser', token_uid], 
        queryFn: async () => {
            console.log("get auth token user")
            const res = await getAuthUser({token_uid})
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return {authUser, isLoadingAuthUser, reloadAuthUser}
}


export const useLoadUserDetails = (username: string) => {
    const { updateUserData, updateLogin } = useAuthState()

    const {data: user, refetch: reloadUser,  isLoading: isLoadingUser} = useQuery({ 
        queryKey: ['user', username], 
        queryFn: async () => {
            console.log("get user details")
            const res = await getUserDetails(username)
            if (res && updateUserData !== undefined && updateLogin !== undefined){
                updateUserData(res)
                updateLogin(true)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return {user, isLoadingUser, reloadUser}
}


export const useCompleteSignIn = () => {
    // const navigate = useNavigate();

    const completeSignIn = async (email: string) => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            try {
              const result = await signInWithEmailLink(auth, email!, window.location.href);
              const user = result.user
              console.log('Successfully signed in user');
              const accessToken = await user?.getIdToken()
              localStorage.setItem('accessToken', accessToken)
              localStorage.setItem('refreshToken', user?.refreshToken)
              localStorage.removeItem('login_token');
              // TODO: get / replace access & refresh token, and convert to object, sample;
              // add user to context & update isSignedIn context & navigate to home
            //   const data = useLoadUserDetails(getEmailFromToken(localStorage.getItem('accessToken')))
            //   navigate('/');
              // You can redirect to another page or perform additional actions here
              return user
            } catch (e: any) {
              // setError(error.message);
              // error = `Error signing in with email link: ${e}`
              // error = e
              console.log(e);
              return null
            }
        }
    }

    const signOut = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('login_token');
      }

    // const completeSignIn = (email: string) => {
    //     return signInUser(email)
    // }

    const userDetails = useLoadUserDetails(getEmailFromToken(localStorage.getItem('accessToken')))


    const getSavedResourceTruths = () => {
        if (userDetails && userDetails?.user && userDetails?.user?.saved_resources){
            const savedResources = userDetails.user.saved_resources
            const newObject = savedResources.reduce((acc, item) => {
                if (item && item?.id){
                    acc[item.id] = true
                }
                return acc;
            }, {} as Record<string, boolean>);
            return newObject
        }
        return {} as Record<string, boolean>
    }

    // const [cartItems, updateCartItems] = useState<OrderItemSchema[]>((userDetails && userDetails?.user?.cart_items) ? userDetails?.user?.cart_items : [])

    const [cartItems, updateCartItems] = useState<{[key: string]: OrderItemSchema[]}>(() => {
        const storedCart = localStorage.getItem('cart');
        return (userDetails && userDetails?.user?.cart_items) ? userDetails?.user?.cart_items : (storedCart ? JSON.parse(storedCart) : []);
    });

    const updateCart = (resourceId: string, newItem: OrderItemSchema, quantity: number = 0) => {
        resourceId = resourceId || "";
        if (newItem?.item_name && resourceId) {
            var cartList: OrderItemSchema[] = cartItems[resourceId] || [];
            const itemIndex = cartList.findIndex(i => i.item_name === newItem.item_name);
    
            if (itemIndex >= 0 && quantity !== newItem.total_quantity) {
                newItem.total_quantity = quantity;
                if (quantity === 0) {
                    cartList = cartList.filter(i => i.item_name !== newItem.item_name);
                } else {
                    cartList[itemIndex] = newItem;
                }
                
                updateCartItems(prev => ({...prev, [resourceId]: cartList}));
            } else {
                updateCartItems(prev => ({
                    ...prev,
                    [resourceId]: [...(prev[resourceId] || []), newItem]
                }));
            }
        }
    };

    const clearCart = () => {
        localStorage.removeItem('cart')
        updateCartItems({});
    }

    // const clearResourceCart = (resourceId: string) => {
    //     var storedCart = localStorage.getItem('cart');
    //     storedCart = storedCart ? JSON.parse(storedCart) : []
    //     // if (storedCart && storedCart[resourceId]){
    //     // localStorage.removeItem('cart')
    //     // updateCartItems({});
    // }

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartItems));
    }, [cartItems]);

    return {completeSignIn, user: userDetails.user, cartItems, updateCart, clearCart, isLoading: userDetails.isLoadingUser, reloadUser: userDetails.reloadUser, getSavedResourceTruths, signOut}
  };


  export const useUpdateAccountDetails = (username: string) => {

    const { reloadUser } = useLoadUserDetails(username)
    
    const updateAccountMutation = useMutation({ 
        mutationFn: async (updateData: AccountSchema) => {
            console.log("update account")
            const res = await updateUserDetails(username, updateData)
            await reloadUser()
            return res
    }})

    const updateAccount = (accountUpdate: AccountSchema) => {
        updateAccountMutation.mutate(accountUpdate)
        return updateAccountMutation?.isSuccess
    }


    const saveResourceMutation = useMutation({ 
        mutationFn: async (resourceId: string) => {
            console.log("add saved resource")
            const res = await addSavedResource(username, resourceId)
            await reloadUser()
            return res
    }})

    const saveResource = (resourceId: string) => {
        saveResourceMutation.mutate(resourceId)
        return saveResourceMutation?.isSuccess
    }


    const removeResourceMutation = useMutation({ 
        mutationFn: async (resourceId: string) => {
            console.log("remove saved resource")
            const res = await removeSavedResource(username, resourceId)
            await reloadUser()
            return res
    }})

    const removeResource = (resourceId: string) => {
        removeResourceMutation.mutate(resourceId)
        return removeResourceMutation?.isSuccess
    }


    const isLoading = (
            updateAccountMutation.isPending ||
            saveResourceMutation.isPending || 
            removeResourceMutation.isPending
    )
    return { updateAccount, saveResource, removeResource, isLoading }
}



export const useAddCredential = () => {
    type mutationProps = {
        username: string
        credential: CredentialSchema
    }
    const mutation = useMutation({ 
        mutationFn: async ({username, credential}: mutationProps) => {
            console.log("add member credentials")
            const res = await addMembershipCredentials(username, credential)
            return res
    }})

    const addMemberCredential = (username: string, credential: CredentialSchema) => {
        mutation.mutate({username, credential})
        return mutation?.data ? true : false
    }

    const isLoadingCredential = mutation.isPending
    return { addMemberCredential, isLoadingCredential }
}


export const useGetCredentials = (memberUsername: string) => {
    const {data: memberCredentials, refetch: reloadCredentials, isLoading: isLoadingCredentials} = useQuery({ 
        queryKey: ['credentials', memberUsername], 
        queryFn: async () => {
            console.log("fetching credentials")
            const res = await fetchMemberCredentials(memberUsername)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return { isLoadingCredentials, memberCredentials, reloadCredentials}
}


export const useAddMembership = (communityId: string) => {

    const mutation = useMutation({ 
        mutationFn: async (membership: MembershipSchema) => {
            console.log("add member")
            const res = await addCommunityMember(communityId, membership)
            // if (res && Object.keys(res).length !== 0 ){
            //     if (res?.login_token && res?.refresh_token){
            //         localStorage.setItem('access_token', res.login_token)
            //         localStorage.setItem('refresh_token', res.refresh_token)
            //     }
            //     // updateAuthUser(res)
            //     // updateLogin(true)
            //     // if (res.auth_status){
            //     //     updateVerified(res.auth_status) 
            //     // }
            // }
            // await reloadMemberships()
            // await reloadRequests()
            return res
    }})

    const addMembership = (membershipUpdate: MembershipSchema) => {
        mutation.mutate(membershipUpdate) 
        return mutation.isSuccess
    }

    const isLoadingMembership = mutation.isPending
    const isAddMembershipError = mutation.isError
    const isAddMembershipIdle = mutation.isIdle
    const isAddMembershipSuccess = mutation.isSuccess
    return { addMembership, isLoadingMembership, isAddMembershipSuccess, isAddMembershipError, isAddMembershipIdle }
}

export const useLoadMember = (communityId: string) => {
    let { user } = useCompleteSignIn()
    const username = user?.username ? user.username : ""
    const {data: currentMember, isLoading: isLoadingCurrentMember, refetch: reloadCurrentMember} = useQuery({ 
        queryKey: ['currentMember', communityId, username], 
        queryFn: async () => {
            console.log("fetching membership by username")
            const res = await getMembership(communityId, username)
            return res
        }
    })
    return {isLoadingCurrentMember, currentMember, reloadCurrentMember}
}

export const useAddBooking = () => {
    // var { reloadBookings } = useLoadBookings()
    const mutation = useMutation({ 
        mutationFn: async (booking: ResourceBookingSchema) => {
            // console.log("add booking", booking) 
            const res = await createBooking(booking)
            // reloadBookings()
            return res
    }})

    const addNewBooking = (bookingData: ResourceBookingSchema) => {
        mutation.mutate(bookingData)
        return mutation?.data ? true : false
    }

    const isLoadingBooking = mutation.isPending
    const isAddBookingSuccess = mutation.isSuccess
    const isAddBookingError = mutation.isError
    return { addNewBooking, isLoadingBooking, isAddBookingSuccess, isAddBookingError }
}

export const useFeedPosts = () => {
    const { data: feedPosts, isLoading, isError, refetch:reloadFeedPosts } = useQuery({
        queryKey: ['feedPosts'], 
        queryFn: async () => {
            console.log("Getting feed posts");
            const response = await getFeedPosts(); 
            return response;
        },
        staleTime,
        enabled: isEnabled
    });
    return { feedPosts, isLoading, isError, reloadFeedPosts };
}

export const useAddPost = (username: string) => {
    const { reloadFeedPosts } = useFeedPosts()
    const mutation = useMutation({ 
        mutationFn: async (post: FeedPostSchema) => {
            console.log("add post") 
            const res = await newFeedPost(username, post)
            reloadFeedPosts()
            return res
    }})

    const addNewPost = (post: FeedPostSchema) => {
        mutation.mutate(post)
        return mutation?.data ? true : false
    }

    const isLoadingPost = mutation.isPending
    const isAddPostSuccess = mutation.isSuccess
    const isAddPostError = mutation.isError
    return { addNewPost, isLoadingPost, isAddPostSuccess, isAddPostError }
}

export const useLoadBookings = (username: string) => {
    const {data: bookings, refetch: reloadBookings, isLoading: isLoadingBookings} = useQuery({ 
        queryKey: ['bookings', username], 
        queryFn: async () => {
            console.log("fetching bookings")
            const res = await getUserResourceBookings(username)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return {isLoadingBookings, bookings, reloadBookings}
}

// const handleSetBrowserSession = (sessionId: string) => {
//     const newSessionId = generateUUID();
//     localStorage.setItem('sessionId', newSessionId)
// }




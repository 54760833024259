// import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ResourceOptions from '../pages/UnAuth/ResourceOptions'
import ResourceListing from '../pages/UnAuth/ResourceListing'
import ResourceDetails from '../pages/UnAuth/ResourceDetails'
import Privacy from '../pages/shared/Privacy'
import Terms from '../pages/shared/Terms'
import Disclaimer from '../pages/shared/Disclaimer'
import Eula from '../pages/shared/Eula'
import FinishSignIn from '../pages/shared/FinishSignIn';

import { SearchFilterOptionType } from "../data/types";
import { FocusGroupForm } from '../pages/UnAuth/FocusGroup'
// import Navbar from '../components/Navbar'


type UnAuthViewProps = {
  searchFilterOptions: SearchFilterOptionType
}

const UnAuthView: React.FC<UnAuthViewProps> = ({searchFilterOptions}) => {
  return (
    <Routes>
        <Route path="/focus-group" element={<FocusGroupForm />}  />
        <Route path="/kitchen" element={<ResourceListing resourceType="kitchen" searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/documentation" element={<ResourceListing resourceType="documentation" searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/training" element={<ResourceListing resourceType="training" searchFilterOptions={searchFilterOptions} />}  />
        <Route path="/funding" element={<ResourceListing resourceType="funding" searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/expert" element={<ResourceListing resourceType="expert" searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/farm" element={<ResourceListing resourceType="farm" searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/:resourceType/:resourceId" element={<ResourceDetails searchFilterOptions={searchFilterOptions}  />}  />
        <Route path="/privacy" element={<Privacy />}  />
        <Route path="/terms" element={<Terms />}  />
        <Route path="/disclaimer" element={<Disclaimer />}  />
        <Route path="/eula" element={<Eula />}  />
        <Route path="/finishSignIn" element={<FinishSignIn />} />
        <Route path="*" element={<ResourceOptions  />}  />
    </Routes>
  )
}



export default UnAuthView;
import { useEffect, useState } from "react";
import { ResourceBookingSchema, SavedResourceSchema, SearchFilterOptionType } from "../../data/types";
// import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
import SavedResourceCard from "../../components/SavedResourceCard";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Pagination from "../../components/Pagination";
import { usePagination } from "../../hooks/usePagination";

import BookedResourceCard from "../../components/BookedResourceCard";
import {
  useCompleteSignIn,
  useLoadBookings,
  useUpdateAccountDetails,
} from "../../hooks/authhooks";
import { useNavigate } from "react-router-dom";
import { useLoadResourcesFilter } from "../../hooks/apphooks";


type SavedResourceListingProps = {
  searchFilterOptions: SearchFilterOptionType
}

const SavedResourceListing: React.FC<SavedResourceListingProps> = ({ searchFilterOptions }) => {
  const { user } = useCompleteSignIn();
  const navigate = useNavigate();
  const { searchTerm, currentFilter } = searchFilterOptions;

  var allSavedResources: SavedResourceSchema[] =
    user && user?.saved_resources ? user.saved_resources : [];
  // const [filterSetting, setFilterSetting] = useState<boolean>(false);
  // const [currentSavedResourcePage, setCurrentSavedResourcePage] = useState(1);

  var filteredSavedResources = allSavedResources
    ? allSavedResources.filter(
        (resource) =>
          resource?.state?.toLowerCase() ===
            currentFilter.state.toLowerCase() ||
          resource?.state === "" ||
          currentFilter.state === ""
      )
    : [];


  var { searchSavedResources } = useLoadResourcesFilter(
    "kitchen",
    currentFilter.state,
    currentFilter.active
  );

  if (searchTerm) {
    const { searchedResources } = searchSavedResources(searchTerm, filteredSavedResources);
    filteredSavedResources = searchedResources;
  }

  const {
    currentPage,
    setCurrentPage,
    paginatedItems: paginatedResources,
    startIndex,
    endIndex,
    totalItems: numSavedResources,
    itemsPerPage
  } = usePagination(filteredSavedResources);

  // useEffect(() => {
  //   document.title = `HomeCook | Saved Resources Listing`
  // })

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | Saved Resources Listing`;
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;

  const loggedin_username = user?.username ? user?.username : "";
  const { removeResource } = useUpdateAccountDetails(loggedin_username);

  const handleRemoveSavedResource = (resource_id: string | undefined) => {
    if (resource_id) {
      removeResource(resource_id);
    }
  };

  var { bookings } = useLoadBookings(loggedin_username);
  bookings = bookings ? bookings : [];

  type savedResourcePageOptionsType = {
    id: "saved" | "booked"
    name: string;
  }
  const savedResourcePageOptions: savedResourcePageOptionsType[] = [
    {
      id: "saved",
      name: `Saved (${allSavedResources.length})`,
    },
    {
      id: "booked",
      name: `Bookings and Reservations (${bookings.length})`,
    },
  ];

  const [currentOption, setCurrentOption] = useState("saved");
  // const resourcesPerPage = 6;
  // var startIndex = 0;
  // var endIndex =
  //   numSavedResources <= resourcesPerPage
  //     ? numSavedResources
  //     : resourcesPerPage;
  // var numPages = Math.ceil(numSavedResources / resourcesPerPage);

  if (isMobile) {
    return (
      <>
        <div
          className={`w-full left-0 flex font-open-sans h-full overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#fff]`}
        >
          
          <div
            className={`flex flex-col w-full sm:w-10/12 h-${
              allSavedResources && allSavedResources.length
                ? `[1348px]`
                : `full`
            } overflow-y-scroll relative items-start py-6 bg-white`}
          >
            <div className="flex flex-col mx-auto sm:h-screen w-11/12 bg-[#FFFFFF] rounded-xl  mt-24">
              <h1 className="font-bold text-xl pt-10">Saved Resources</h1>
              {filteredSavedResources && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={numSavedResources}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  onPageChange={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                />
              )}
              {paginatedResources && paginatedResources.length > 0 ? (
                paginatedResources.map(
                  (resource: SavedResourceSchema, i: number) => (
                    <SavedResourceCard
                      key={i}
                      isMobile={isMobile}
                      resource={resource}
                      handleRemoveSavedResource={handleRemoveSavedResource}
                    />
                  )
                )
              ) : (
                <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                  No saved resources to show
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className={`w-full left-0 flex font-open-sans h-full overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#fff]`}
        >
          
          <div
            className={`flex flex-col w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 bg-white`}
          >
            
            <div className="flex flex-col mx-auto sm:h-screen sm:w-[1200px] bg-[#FFFFFF] rounded-xl  mt-10">
              <div className="flex flex-row w-full">
                <h1 className="font-bold text-xl pt-10 pl-6 mr-auto">
                  Saved & Booked Resources
                </h1>
                <h1
                  className="font-bold text-[16px] pt-10 pl-10 ml-auto text-[#8A8A8A] cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIosIcon /> Go Back
                </h1>
              </div>

              <div className="flex flex-row m-[24px] rounded-lg border-[1px] border-[#E9E9E9] h-[67px] p-2">
                {savedResourcePageOptions.map((option, i) => (
                  <div
                    key={i}
                    className={`${
                      currentOption === option.id
                        ? "border-0 bg-primaryDark-0 text-white"
                        : "cursor-pointer border-[1px] text-[#4D4D4D] border-[#E9E9E9]"
                    }  p-2 my-auto mx-6 rounded-lg`}
                    onClick={() => setCurrentOption(option.id)}
                  >
                    {option.name}
                  </div>
                ))}
              </div>
              {filteredSavedResources && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={numSavedResources}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  onPageChange={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                />
              )}

              <div className="flex flex-col mx-auto sm:h-screen sm:w-[1200px] bg-[#fff] rounded-xl">
                {
                  (paginatedResources && paginatedResources.length > 0 && currentOption === "saved") ? (
                    paginatedResources.map(
                      (resource: SavedResourceSchema, i: number) => (
                        <SavedResourceCard
                          key={i}
                          isMobile={isMobile}
                          resource={resource}
                          handleRemoveSavedResource={handleRemoveSavedResource}
                        />
                      )
                    )
                  ) : (
                    currentOption === "saved" && (
                      <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                        No {currentOption} resources to show
                      </div>
                    )
                  )
                }
              </div>

              {
                (bookings && bookings.length > 0 && currentOption === "booked")
                  ? bookings.map((booking: ResourceBookingSchema, i: number) => (
                      <BookedResourceCard
                        key={i}
                        isMobile={isMobile}
                        booking={booking}
                        // handleRemoveSavedResource={handleRemoveSavedResource}
                      />
                    ))
                  : currentOption === "booked" && (
                      <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                        No {currentOption} resources to show
                      </div>
                    )
                }
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SavedResourceListing;

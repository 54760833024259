import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { PaymentMethod } from "@stripe/stripe-js";
import CancelIcon from '@mui/icons-material/Cancel';
import { ResourceSchema, TrainingSchema, TrainingOrganizationSchema, ResourceType, ResourceBookingSchema  } from "../data/types";
import { useCompleteSignIn } from '../hooks/authhooks'
import { useGetPaymentMethods } from '../hooks/paymenthooks'
import { capitalizeString } from '../utils/helpers';
import BookingPaymentsView from "../components/BookingPaymentsView";



type ScheduleExpertFormProps = {
    resourceDetail: ResourceSchema
    handleClose: () => void;
    handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
};
  
const ScheduleExpertForm: React.FC<ScheduleExpertFormProps> = (props) => {
    var { resourceDetail, handleSubmitRequest, handleClose } = props;
    const resourceType = resourceDetail?.resource_type

    const trainingOrganizations = (resourceDetail  && resourceDetail?.resource_inventory) ? resourceDetail.resource_inventory :  {} as TrainingOrganizationSchema

    const organizations: string[] = Object.keys(trainingOrganizations)
    const [trainingOrganization, setTrainingOrganization] = useState<string>(organizations[0]);

    var availableSchedules = trainingOrganizations[trainingOrganization]
    availableSchedules = availableSchedules ? availableSchedules?.filter(date => moment(date.date).isAfter(moment().startOf('day'))) : availableSchedules

    // const [application, setApplication] = useState<ResourceBookingSchema>({} as ResourceBookingSchema);
    const [selectedDate, setSelectedDate] = useState<string>("")
    // const [selectedPayMethod, setSelectedPayMethod] = useState<string>("");
    const [timeOptions, setTimeOptions] = useState<string[]>([])
    const [selectedTime, setSelectedTime] = useState<string>("")

    const { user } = useCompleteSignIn();
    const loggedin_username = (user && user?.username) ? user.username : ''

    var { paymentMethods } = useGetPaymentMethods(loggedin_username)

        // const { user } = useCompleteSignIn();
        // const loggedin_username = (user && user?.username) ? user.username : ''
    
    // var { paymentMethods } = useGetPaymentMethods(loggedin_username)

    paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
      (paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod
    );
  


    const handleSetExpert = (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault()
      const {name, value} = e.target
      if (name === 'community_id' && value){
        setTrainingOrganization(value)
      } else if (name === 'date' && value){
        setSelectedDate(value)
        setTimeOptions(availableSchedules[parseInt(value)].times)
      } else if (name === 'payment_method' && value){
        const selectedMethod = paymentMethods.find((pm:any) => pm.id === value);
        setPaymentMethod(selectedMethod);
      }
    }
  
    const handleSubmit = () => {
      if (resourceType && selectedDate && selectedTime && availableSchedules.length > 0) {
        const application = {
          start_time: `${availableSchedules[parseInt(selectedDate)].date} ${selectedTime}`,
          community_id: trainingOrganization,
          payment_method: paymentMethod?.id,
          total_hours: availableSchedules[0]?.duration ? availableSchedules[0].duration : 0,
          total_cost: resourceDetail.total_amount ? resourceDetail.total_amount : 0
        }
        handleSubmitRequest(resourceDetail, resourceType, application);
        handleClose();
      }
    };

    useEffect(() => {
      setPaymentMethod((paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod)  
      // eslint-disable-next-line 
    }, [resourceDetail, resourceDetail.community_id])
  
    return (
      <div className="flex flex-col justify-evenly items-center h-full">
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>

        <div className='h-[4px] border-0 bg-primaryDark-400 w-11/12 my-4' />
        
        <p className='text-black font-medium text-[24px] font-raleway text-left w-11/12'>
          Schedule session with <span className='font-bold'>{resourceDetail.name}</span>
        </p>
        <p className="text-left text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">Fill out the details below to schedule with an expert.</p>

        <div className='border-[1px] border-[#E9E9E9] p-1 rounded-lg w-11/12'>
          {/* <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Pick An Expert
              </label>
              <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <select
                      className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="community_id"
                      value={trainingOrganization}
                      onChange={handleSetExpert}
                  >
                    <option value="" disabled>
                        -- Select Expert --
                    </option>
                    {
                      (organizations && organizations.length > 0) && organizations.map((data: string, i: number)=>
                        (
                          <option value={data} key={i}>
                              {data ? 'Default' : capitalizeString(data)}
                          </option>
                        )
                      )
                    }
                  </select>
              </div>
          </div> */}

          <div className="flex flex-col mx-auto w-11/12 my-4">
          {
            (availableSchedules && availableSchedules.length > 0) ? (
              <>
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Available Dates
                </label>
                <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                    <select
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                        name="date"
                        value={selectedDate}
                        onChange={handleSetExpert}
                    >
                      <option value="">
                          -- Select Date --
                      </option>
                      {
                        availableSchedules.map((date: TrainingSchema, i: number)=>
                        (
                          <option value={i} key={i}>
                              {moment(date.date).format('ddd MMM DD, YYYY')}
                          </option>
                        ))
                      }
                    </select>
                </div>
              </>
            ) : (<p className='text-[#A1A1A1] text-[14px] italic font-open-sans'>No Dates Available</p>)
          }

          </div>

          <div className="flex flex-col mx-auto w-11/12 my-4">
            {
              ((availableSchedules && availableSchedules.length > 0) && selectedDate) ? (
              <>
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Available Time
                </label>
                <div className='flex flex-row rounded-md my-2'>
                    {
                       (timeOptions && timeOptions.length > 0) ? timeOptions.map((time, i)=> {
                        return (
                          <div 
                            key={i} 
                            className={`${(selectedTime === time) ? 'bg-primaryDark-400 text-white' : 'text-black '} cursor-pointer w-2/12 mr-1 rounded-lg text-center border-[#E9E9E9] border-[1px] font-open-sans text-[16px] p-1 font-medium`}
                            onClick={()=> {
                              selectedTime === time ? setSelectedTime("") : setSelectedTime(time)
                            }}
                          >
                            {moment(time, 'HH:mm').format('hh:mm a')}
                          </div>
                        )
                        }) : (<p className='text-[#A1A1A1] text-[14px] italic font-open-sans'>No Times Available</p>)
                    }    
                </div>
              </> ) : (<></>)
            }
          </div>

          {
            (resourceDetail?.total_amount && selectedTime) ? (
              <div className="flex flex-col mx-auto w-11/12 my-4">
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    Payment Method
                  </label>

                  {(paymentMethods && paymentMethods.length > 0) && (<BookingPaymentsView paymentMethod={paymentMethod} handleSetApplication={handleSetExpert} />)}
              </div>
            ) : (<></>)
          }
        
          <div className="flex flex-row rounded-lg h-auto mx-auto w-11/12 my-2 text-black font-semibold text-[16px] font-raleway text-left">
            <p>{resourceType ? capitalizeString(resourceType) : 'Resource'} cost:</p>
            <p className='font-normal ml-1'>&nbsp;${resourceDetail?.total_amount ? resourceDetail.total_amount.toFixed(2) : 0}</p>
          </div>

        </div>
        <div className='flex flex-row my-2 w-11/12'>
          <button
              className="py-2 w-[217px] mr-auto font-bold text-[18px] font-raleway text-primaryDark-0 border-[#E9E9E9] border-[1px]"
              data-testid="cancel-button"
              onClick={handleClose}
            > 
                  Cancel  
          </button>

          <button
            className={`py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 ${(!selectedTime || (!paymentMethod?.id && resourceDetail?.total_amount ? true : false)) ? 'bg-gray-300' : 'bg-primaryDark-0'}  text-white`}
            // className={`py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 ${(!selectedTime) ? 'bg-gray-300' : 'bg-primaryDark-0'}  text-white`}
            onClick={handleSubmit}
            data-testid="submit-button"
            disabled={!selectedTime || (!paymentMethod?.id && resourceDetail?.total_amount ? true : false)}
          > 
              Submit  
          </button>
        </div>
   
      </div>
    );
};


export default ScheduleExpertForm

import React, { useState } from 'react'
import { PaymentMethod } from "@stripe/stripe-js";
import CancelIcon from '@mui/icons-material/Cancel';
import { ResourceSchema, ResourceType,  ResourceBookingSchema, ResourceInventorySchema, InventorySchema, OrderItemSchema } from "../data/types";
import { useCompleteSignIn } from '../hooks/authhooks'
import { useGetPaymentMethods } from '../hooks/paymenthooks'
// import { capitalizeString, formatDateString } from '../utils/helpers';
// import { CheckCircle, Delete } from '@mui/icons-material';
import { CartItem, EditCartItem } from '../components/CartItemCard';
import BookingPaymentsView from '../components/BookingPaymentsView';


type OrderInventoryFormProps = {
    resourceDetail: ResourceSchema
    handleClose: () => void;
    handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
};
  
const OrderInventoryForm: React.FC<OrderInventoryFormProps> = (props) => {
    var { resourceDetail, handleSubmitRequest, handleClose } = props;

    const { user, cartItems: cartItemsSaved, updateCart, clearCart } = useCompleteSignIn();
    const loggedin_username = (user && user?.username) ? user.username : ''
    var { paymentMethods } = useGetPaymentMethods(loggedin_username)
    const resourceId = (resourceDetail && resourceDetail?.id) ? resourceDetail.id : ''
    const cartItems = (resourceId && cartItemsSaved[resourceId]) ? cartItemsSaved[resourceId] : []
    const cartTotalCost = cartItems.reduce((sum, item) => sum + ((item?.unit_price ? item?.unit_price : 0) * item.total_quantity), 0);    

    // var { paymentMethods } = useGetPaymentMethods(loggedin_username)

    const [application, setApplication] = useState<ResourceBookingSchema>({} as ResourceBookingSchema);
    paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
      (paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod
    );
    // const [selectedPayMethod, setSelectedPayMethod] = useState<string>("");
    const [isEditingQty, setIsEditingQty] = useState<string>()

    const inventoryCatalogItems = (resourceDetail  && resourceDetail?.resource_inventory) ? resourceDetail.resource_inventory :  {} as ResourceInventorySchema
    const catalogOptions: string[] = Object.keys(inventoryCatalogItems)
    const catalogOption: string = catalogOptions ? catalogOptions[0] : ""
    const availableItems = inventoryCatalogItems[catalogOption]


    const handlePaymentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      if (name && value) {
        setApplication((prev => ({
          ...prev,
          [name]: value
        })));
      }

      if (name === 'payment_method' && value){
        const selectedMethod = paymentMethods.find((pm:any) => pm.id === value);
        setPaymentMethod(selectedMethod);
      }
    };
  
    const handleSubmitOrder = () => {
      const {name: resourceName, resource_type: resourceType, community_id: communityId} = resourceDetail

      if (cartItems && cartItems.length > 0 && resourceType && communityId){
        // const application: ResourceBookingSchema = {} as ResourceBookingSchema
        const userAvatar = (user && user?.avatar) ? user.avatar : ''

        application.resource_id = resourceId
        application.resource_name = resourceName
        application.resource_type = resourceType
        application.community_id = communityId
        application.user_full_name = `${user?.first_name} ${user?.last_name}`.trim()
        application.user_avatar = userAvatar
        application.order_inventory = cartItems
        application.total_cost = parseFloat(cartTotalCost.toFixed(2))
        application.payment_method = paymentMethod?.id
        // console.log('Submitting order', application)
        handleSubmitRequest(resourceDetail, resourceType as ResourceType, application)
        clearCart()
        setApplication({} as ResourceBookingSchema)
        handleClose()
      }
    };

    const isAdded = (inventoryItem: InventorySchema): boolean => {
      if (availableItems && cartItems && cartItems?.length > 0){
        return cartItems.some(item => {
          item = item as OrderItemSchema
          return (
            item.item_name === inventoryItem.item_name
          )
        })
      }
      return false
    }

    const handleAddToCart = (inventoryItem: any, quantity: number = 1)=> {
      if (inventoryItem && quantity){
        const orderItem = inventoryItem as OrderItemSchema
        orderItem.total_quantity = quantity
        orderItem.available_quantity = inventoryItem.available_quantity
        orderItem.inventory_id = inventoryItem.id
        orderItem.resource_name = resourceDetail.name
        updateCart(resourceId, orderItem)
      }
    }


    const handleUpdateCartItem = (resourceId: string, item: OrderItemSchema, quantity: number = 0)=> {
      updateCart(resourceId, item, quantity)
    }

  
    return (
      <div className="flex flex-col justify-evenly items-center h-full">
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>

        <div className='h-[4px] border-0 bg-primaryDark-400 w-11/12 my-4' />
        
        <p className='text-black font-medium text-[24px] font-raleway text-left w-11/12'>
          Get Order from <span className='font-bold'>{resourceDetail.name}</span> 
        </p>
        <p className="text-left text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">Fill out the details below to order fresh produce</p>

        <div className='border-[1px] border-[#E9E9E9] p-1 rounded-lg w-11/12'>
          <div className="flex flex-col mx-auto w-11/12 my-4">
            <InventoryItems availableItems={availableItems as InventorySchema[]} handleAddToCart={handleAddToCart} isAdded={isAdded} />
          </div>
         
          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                {(cartItems && cartItems.length > 0) ? 'Added Items' : 'Add Items to Cart'}
              </label>
              <div className='flex flex-col'>
              {
                cartItems && cartItems.length > 0  ? cartItems?.map((item, i)=>
                  (
                    (isEditingQty && isEditingQty === item.id) ? (
                      <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                    ) : ( 
                      <CartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} />
                    )
                  )
                ) : (
                  <i className='text-[13px] text-[#aaa] w-full mt-2 font-raleway'>No items to show</i>
                )
              }
              </div>
          </div>


          {
            (cartTotalCost) ? (
              <div className="flex flex-col mx-auto w-11/12 my-4">
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    Payment Method
                  </label>

                  {(paymentMethods && paymentMethods.length > 0) && (<BookingPaymentsView paymentMethod={paymentMethod} handleSetApplication={handlePaymentChange} />)}
              </div>
            ) : (<></>)
          }

          <div className="flex flex-row font-normal h-auto mx-auto w-11/12 my-2 text-black text-[16px] font-raleway text-left">
            <p className='ml-auto'>Total cost:</p>
            <p className='ml-1 font-semibold '>&nbsp;${cartTotalCost.toFixed(2)}</p>
          </div>
              
        </div>

        <div className='flex flex-row my-2 w-11/12'>
          <button
              className="py-2 w-[217px] mr-auto font-bold text-[18px] font-raleway text-primaryDark-0 border-[#E9E9E9] border-[1px]"
              data-testid="cancel-button"
              onClick={handleClose}
            > 
                  Cancel  
          </button>

          <button
              className={`py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 ${(cartItems.length < 1  || (!paymentMethod?.id && cartTotalCost > 0 ? true : false)) ? 'bg-gray-300' : 'bg-primaryDark-0'} text-white`}
              onClick={handleSubmitOrder}
              disabled={cartItems.length < 1  || (!paymentMethod?.id && cartTotalCost > 0 ? true : false)}
              data-testid="submit-button"> 
                  Submit  
          </button>
        </div>
      </div>
    );
};



type InventoryItemsProps = {
  availableItems: InventorySchema[]
  isAdded: (inventoryItem: InventorySchema)=> boolean
  handleAddToCart: (inventoryItem: InventorySchema, quantity: number) => void
}

const InventoryItems: React.FC<InventoryItemsProps> = ({availableItems, isAdded, handleAddToCart}) => {
  const [isAddingQty, setIsAddingQty] = useState<string>()
  const [qty, setQty] = useState<number>(0)
  return (
    <>
      <div className='flex flex-row space-x-2 w-full'>
        {
          (availableItems && availableItems.length > 0) && availableItems.map((item, i)=> (
            <div key={i} className='flex flex-col p-1 border-[#E9E9E9] border-[1px] h-[200px] rounded-xl w-1/3'>
                
                {
                  (item?.image_url) ? (
                    <img className='h-[80px] rounded-t-xl object-contain' src={item.image_url} alt={item.item_name} />
                  ) : (
                    <div className='h-[80px] rounded-t-xl bg-[#eee]' />
                  )
                }
                <div className='mx-auto w-11/12 '>
                  <p className='font-bold font-raleway text-black'>
                    {item.item_name}
                  </p>
                  <div className='flex flex-row w-full'>
                    <span className='text-[14px] text-black font-open-sans mr-auto font-semibold'>
                      ${item.unit_price} per {item.measure_unit} 
                    </span>
                    <span className='text-[14px] text-[#4D4D4D] font-open-sans ml-auto'>
                      {item.available_quantity} units left
                    </span>
                  </div>
                </div>

                {
                  isAdded(item) ? (
                    <button 
                      className={`border-[1px] border-[#E9E9E9] bg-white text-primaryDark-0  m-auto text-[14px] w-full h-[51px] font-bold font-raleway`}
                      // onClick={() => handleAddToCart(item)}
                      disabled={isAdded(item)}
                      >
                      Added to Cart
                    </button>
                  ) : (
                    (isAddingQty && isAddingQty === item?.id) ? (
                      <div className='flex flex-row w-full'>
                        <input 
                          name="total_quantity"
                          value={qty}
                          type='number' 
                          min={0} 
                          placeholder='0' 
                          className='border-[1px] border-[#E9E9E9] rounded-lg w-2/3 mr-1' 
                          onChange={e=>setQty(parseFloat(e.target.value))}
                        />
                        <button 
                          className={`bg-primaryDark-0 text-white m-auto text-[14px] h-[40px] font-bold font-raleway w-1/3`}
                          onClick={() => handleAddToCart(item, qty)}
                          disabled={isAdded(item)}
                          >
                          Add
                        </button>
                      </div>
                    ) : (
                      <button 
                        className={`bg-primaryDark-0 text-white m-auto text-[14px] w-full h-[51px] font-bold font-raleway`}
                        onClick={() => setIsAddingQty(item.id)}
                        disabled={isAdded(item)}
                        >
                        Add to Cart
                      </button>
                    )
                  )
                }
            </div>
          ))
        
        }
      </div>
    </>
  )
}


export default OrderInventoryForm
import { useNavigate } from 'react-router-dom';
import {useState, useEffect, useMemo} from 'react'
import moment from 'moment-timezone';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import ResourceCard from '../../components/ResourceCard'

import kitchen_bg from '../../assets/img/kitchen_bg.jpeg'
import documentation_bg from '../../assets/img/documentation_bg.jpeg'
import expert_bg from '../../assets/img/expert_bg.jpeg'
import farm_bg from '../../assets/img/farm_bg.jpeg'
import funding_bg from '../../assets/img/funding_bg.jpeg'
import training_bg from '../../assets/img/training_bg.jpeg'

import { ResourceBookingSchema, ResourceSchema, ResourceType, SearchFilterOptionType } from '../../data/types';
import { capitalizeString } from '../../utils/helpers';
import { USStatesEnum } from '../../utils/enums';

// import FilterOptions from '../../molecules/FilterOptions';
import { ApplyFundingModal, ObtainDocumentationModal, OrderInventoryModal, RegisterTrainingModal, RequestKitchenModal, ScheduleExpertModal } from '../../molecules/AppModal';
import { FilterModal } from "../../molecules/AppModal";


import { ReactComponent as KitchenIcon } from '../../icons/KitchenIcon.svg'
import { ReactComponent as CredentialIcon } from '../../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
import { ReactComponent as DocumentationIcon } from '../../icons/documentationIcon.svg'
import { ReactComponent as FundingIcon } from '../../icons/fundingIcon.svg'
import { ReactComponent as ExpertIcon } from '../../icons/expertIcon.svg'

// import { ReactComponent as Filter } from "../../icons/filter.svg";

import { useLoadResourcesFilter, resourcesPerPage } from '../../hooks/apphooks';
import { useAddBooking, useCompleteSignIn } from '../../hooks/authhooks';


type ResourceListingProps = {
  resourceType: ResourceType
  searchFilterOptions: SearchFilterOptionType
}

const ResourceListing: React.FC<ResourceListingProps> = ({resourceType, searchFilterOptions}) => {
  const navigate = useNavigate()
  const [filterSetting, setFilterSetting] = useState<boolean>(false)
  const {searchTerm, currentFilter, setCurrentFilter} = searchFilterOptions
  const [currentResourcePage, setCurrentResourcePage] = useState(1)

  // Get resources with proper dependencies in the queryKey
  const { filteredResources, getPage, searchResources, reloadResources } = useLoadResourcesFilter(
    resourceType, 
    currentFilter.state, 
    currentFilter.active,
    currentFilter.sortBy
  )

  // Reset to page 1 when resource type or filters change
  useEffect(() => {
    console.log(`ResourceType changed to: ${resourceType}`)
    setCurrentResourcePage(1)
    reloadResources() // Force data refresh
  }, [resourceType, currentFilter.state, currentFilter.active, reloadResources])

  // Apply search filtering if needed
  const resourcesFiltered = useMemo(() => {
    if (searchTerm) {
      console.log(`Applying search filter: "${searchTerm}"`)
      const { searchedResources } = searchResources(searchTerm)
      return searchedResources
    }
    return filteredResources || []
  }, [filteredResources, searchTerm, searchResources])

  // Get paginated resources using the page number
  const { 
    paginatedResources, 
    numResources, 
    numAllResources, 
    startIndex, 
    endIndex,
  } = useMemo(() => {
    const pageData = getPage(resourcesFiltered, currentResourcePage);
    console.log(`Page ${currentResourcePage}: Got ${pageData.paginatedResources.length} resources`);
    return pageData;
  }, [resourcesFiltered, currentResourcePage, getPage])

  // If current page has no resources but there are resources on other pages,
  // reset to page 1
  useEffect(() => {
    if (paginatedResources.length === 0 && numAllResources > 0 && currentResourcePage > 1) {
      console.log(`Empty page but resources exist - resetting to page 1`)
      setCurrentResourcePage(1)
    }
  }, [paginatedResources, numAllResources, currentResourcePage])

  const [width, setWidth] = useState(window.innerWidth);
  const [applyModal, toggleApplyModal] = useState<ResourceType | undefined>()
  const [applyResource, setApplyResource] = useState<ResourceSchema>({} as ResourceSchema)

  // const loggedinUsername = getEmailFromToken(localStorage.getItem('accessToken'))
  const { addNewBooking} = useAddBooking()
  const { user } = useCompleteSignIn()
  const loggedinUsername = (user && user?.username) ? user.username : ''

  const handleSubmitRequest = (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => {
    if (loggedinUsername && resourceType && resource.id){
      const userAvatar = (user && user?.avatar) ? user.avatar : ''
      
      booking.resource_id = resource.id
      booking.resource_type = resourceType
      booking.username = loggedinUsername
      booking.user_full_name = `${user?.first_name} ${user?.last_name}`.trim()
      booking.user_avatar = userAvatar
      booking.resource_name = resource.name
      booking.resource_city = resource.city
      booking.resource_county = resource.county
      booking.resource_state = resource.state
      booking.resource_address = resource.address
      booking.resource_logo = resource.logo
      booking.resource_bgPhoto = resource.bgPhoto
      booking.community_id = resource.community_id
      addNewBooking(booking)
    }
    
  }

  const closeAppModal = ()=>{
    toggleApplyModal(undefined)
    setApplyResource({} as ResourceSchema)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    // setCurrentResourcePage(1)
    reloadResources()
    document.title = `HomeCook | ${capitalizeString(resourceType)} Listing`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [resourceType, paginatedResources, reloadResources]);

  const isMobile = width < 1024;


  var ResourceIcon = OrderIcon
  var resourceTitle = ""
  var resourceDescription = ""
  var resourceBgImage = farm_bg

  switch(resourceType){
    case 'kitchen':
      resourceTitle = "Local Kitchen Spaces"
      resourceDescription = "Discover kitchen communities and food incubators in your area."
      resourceBgImage = kitchen_bg
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      resourceTitle = "Required Documentation & Compliance"
      resourceDescription = "Access essential legal documents and compliance guides."
      resourceBgImage = documentation_bg
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      resourceTitle = "Culinary Training"
      resourceDescription = "Enhance your skills with top culinary training programs."
      resourceBgImage = training_bg
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      resourceTitle = "Funding & Financial Resources"
      resourceDescription = "Discover financial options and resources for your business."
      resourceBgImage = funding_bg
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      resourceTitle = "Industry Mentors & Experts"
      resourceDescription = "Discover organizations that offer mentorship and industry insights."
      resourceBgImage = expert_bg
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      resourceTitle = "Local Fresh Food"
      resourceDescription = "Source fresh, local ingredients for your culinary creations."
      resourceBgImage = farm_bg
      ResourceIcon = OrderIcon
      break;

    default:
      resourceTitle = ""
      resourceDescription = ""
      resourceBgImage = farm_bg
      ResourceIcon = OrderIcon
  }

  var lastUpdatedDate = ''
  if (filteredResources && filteredResources.length > 0){
    lastUpdatedDate = moment(filteredResources[0].last_updated).tz('America/New_York').startOf('second').fromNow()
  }

  if (isMobile){
    return (
      <>
      <div className={`w-full flex flex-row font-open-sans h-screen overflow-y-scroll top-0 bg-gradient-to-b bg-[#FFFFFF] pb-6 pt-8 backdrop-blur-2xl`}>
        
        <div className={`flex flex-col w-full h-full relative items-start py-4 sm:py-6 ${(filterSetting) ? 'invisible sm:visible' : ''}`}>
          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] ml-[10px] mt-[140px] cursor-pointer text-[13px] font-bold" onClick={()=> navigate('/')}>
            <ArrowBackIosIcon style={{fontSize: 13}}   />  Go Back
          </span>
  
          {
              lastUpdatedDate && (
                <span className='font-raleway text-[#8A8A8A]  text-xs text-center my-2 ml-auto mx-4'>Last updated {lastUpdatedDate}</span>
              )
          }
   
          <div className='flex w-11/12 mx-auto bg-[#013847] text-center justify-center h-[180px]  rounded-xl text-[#fff] '>
            <img alt="howto"  className={`rounded-xl w-11/12 h-[180px] absolute opacity-40 object-cover`} src={resourceBgImage} />
            <div className='mt-14 h-[180px]'>
              <p className='text-white font-raleway text-[23px] font-extrabold  mx-4'>{capitalizeString(resourceTitle)}</p>
              <p className=' text-white text-[14px] font-open-sans mx-4 font-bold'>{resourceDescription}</p>
            </div>
          </div>
    
          {
            (currentFilter?.state) ? (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing <b className='font-bold text-primaryDark-0'>{resourceType}</b> results for <b className='font-bold text-primaryDark-0'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : '' }{USStatesEnum[currentFilter.state].fullName}</b>
              </p>
            ) : (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing all <b className='font-bold text-primaryDark-0'>{resourceType}</b> results
              </p>
            )
          }

          {
            (numResources && numAllResources) ? (
              <div className='flex flex-row w-full justify-between items-center mx-auto sm:w-[1200px] p-4'>
                <span className='text-[#8A8A8A] text-xs text-center'>
                  {`Showing ${startIndex + 1} - ${endIndex} of ${numAllResources}`}
                </span>
                <div className='flex space-x-4'>
                  <div onClick={() => setCurrentResourcePage(prev => (prev > 1) ? prev - 1 : prev)}>
                    <span className={`text-[12px] ${(currentResourcePage > 1) ? 'visible cursor-pointer' : 'invisible'}`}>
                      <ArrowBackIosIcon style={{ fontSize: 10 }} />&nbsp;Previous
                    </span>
                  </div>
                  <div onClick={() => setCurrentResourcePage(prev => (prev + 1 <= Math.ceil(numAllResources / resourcesPerPage)) ? prev + 1 : prev)}>
                    <span className={`text-[12px] ${(currentResourcePage + 1 <= Math.ceil(numAllResources / resourcesPerPage)) ? 'visible cursor-pointer' : 'invisible'}`}>
                      Next&nbsp;<ArrowForwardIosIcon style={{ fontSize: 10 }} />
                    </span>
                  </div>
                </div>
              </div>
            ) : (<></>)
          }

          <div className="flex flex-col mx-auto sm:h-screen  rounded-xl">
              {
                (
                  paginatedResources && paginatedResources.length > 0) ? paginatedResources.map((resource: ResourceSchema, i: number) => (
                    <ResourceCard 
                      isMobile={isMobile} 
                      key={i} 
                      resourceType={resourceType} 
                      resourceIcon={ResourceIcon} 
                      resource={resource} 
                      searchFilterOptions={searchFilterOptions} 
                      toggleApplyModal={toggleApplyModal}
                      setApplyResource={setApplyResource}
                    />
                  )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No {resourceType} to show
                  </div>
                )
              }
          </div>
        </div>
      </div>

      <RequestKitchenModal isOpen={!!loggedinUsername && applyModal==="kitchen"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
      <RegisterTrainingModal isOpen={!!loggedinUsername && applyModal==="training"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ObtainDocumentationModal isOpen={!!loggedinUsername && applyModal==="documentation"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ApplyFundingModal isOpen={!!loggedinUsername && applyModal==="funding"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ScheduleExpertModal isOpen={!!loggedinUsername && applyModal==="expert"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <OrderInventoryModal isOpen={!!loggedinUsername && applyModal==="farm"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal}  />
      </>
    )
  } else {
    return (
      <>
      {/* {
        (filterSetting) && (
          <div className={`flex font-open-sans h-screen w-11/12 sm:w-3/12 mr-auto overflow-y-scroll fixed top-0 bottom-0 justify-center from-zinc-200 pb-6 pt-8 backdrop-blur-2xl bg-white/90`}>
            <FilterOptions currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} closeFilterSetting={() => setFilterSetting(false)} />
          </div>)
      } */}
      
      {/* <div className={`${(filterSetting) ? 'w-1/12 sm:w-9/12 right-0' : 'w-full left-0'} flex font-open-sans h-screen overflow-y-scroll fixed top-0 justify-center bg-gradient-to-b bg-[#FFFFFF] pb-6 pt-8 backdrop-blur-2xl`}> */}
        {/* <img alt="howto"  className={`w-full ${(numResources <= 5) ? 'sm:h-[1048px] xs:h-[2048px] h-[1480px]' : `sm:h-[1480px]`}  object-cover absolute opacity-40`} src={bg_img} /> */}
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll fixed top-0 justify-center bg-gradient-to-b bg-[#FFFFFF] pb-6 pt-8 backdrop-blur-2xl`}>  
        <div className={`flex flex-col w-full h-full relative items-start py-4 sm:py-6 ${(filterSetting) ? 'invisible sm:visible' : ''}`}>
          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] ml-[10px] sm:ml-[250px] mt-6 cursor-pointer text-[14px] font-bold" onClick={()=> navigate('/')}>
            <ArrowBackIosIcon  />  Go Back
          </span>
  
          {
              lastUpdatedDate && (
                <span className=' font-raleway text-[#8A8A8A]  text-xs text-center lg:ml-[1300px] sm:ml-[1280px] ml-[250px] my-2 sm-my-0'>Last updated {lastUpdatedDate}</span>
              )
          }
   
          <div className=' bg-[#013847]/95 text-center justify-center w-[361px] sm:w-[1200px] h-[155px] sm:h-[200px] sm:mb-[80px] rounded-xl sm:text-xl text-[#fff] sm:my-4 mx-auto'>
            {/* <img alt="howto"  className={`rounded-xl w-[1200px] ${(numResources <= 5) ? 'sm:h-[348px] xs:h-[348px] h-[348px]' : `sm:h-[55px]`}  object-cover absolute opacity-40`} src={resourceBgImage} /> */}
        
            <img alt="howto"  className={`rounded-xl w-[361px] sm:w-[1200px] sm:h-[200px] h-[155px] absolute opacity-40 object-cover`} src={resourceBgImage} />
            <div className='mt-14 w-[1200px] sm:h-[200px] h-[155px]'>
              <p className='my-4 font-raleway text-[35px] font-extrabold sm:m-4 '>{capitalizeString(resourceTitle)}</p>
              <p className='my-4 text-white text-[15px] font-open-sans sm:m-4'>{resourceDescription}</p>
            </div>
  
            
            
          </div>
    
          {/* {(currentFilter?.state) ? (<p className='text-[#013847] w-full text-xs sm:text-[14px] text-center mx-auto sm:mb-4'>Showing <b className='font-bold'>{resourceType}</b> results for <b className='font-bold'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : '' }{USStatesEnum[currentFilter.state].fullName}</b></p>) : (<p className='text-primaryDark-300 w-full text-xs sm:text-sm text-center mx-auto my-4'>Showing all <b className='font-bold'>{resourceType}</b> results</p>)} */}
{/*   
          {
            (currentFilter?.state) ? (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing <b className='font-bold text-primaryDark-0'>{resourceType}</b> results for <b className='font-bold text-primaryDark-0'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : '' }{USStatesEnum[currentFilter.state].fullName}</b>
              </p>
            ) : (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing all <b className='font-bold text-primaryDark-0'>{resourceType}</b> results
              </p>
            )
          } */}
          <div className='flex w-full justify-center items-center flex-row mx-auto space-x-2'>
            {
              currentFilter?.state ? (
                <p className='text-primaryDark-100 text-xs sm:text-sm text-center my-4'>
                  Showing <i className='font-light'>{currentFilter.active ? 'available' : ''}</i> <b className='font-bold'>{resourceType}</b> results for <b className='font-bold'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : ''}{USStatesEnum[currentFilter.state].fullName}</b>
                </p>
              ) : (
                <p className='text-primaryDark-300 text-xs sm:text-sm text-center my-4'>
                  Showing <i className='font-light'>{currentFilter.active ? 'available' : 'all'}</i> <b className='font-bold'>{resourceType}</b> results
                </p>
              )
            }

            <FilterAltOutlinedIcon
              onClick={() => setFilterSetting(prev => !prev)}
              className={`cursor-pointer text-center rounded-full bg-white hover:bg-gray-100 hover:text-primaryDark-400/60 transition-colors duration-200 ${!filterSetting ? "text-primaryDark-300" : "text-primaryDark-100"}`}
              style={{ padding: '4px' }}
            />
          </div>
          {
            (numResources && numAllResources) ? (
              <div className='flex flex-row w-full justify-between items-center mx-auto sm:w-[1200px] p-4'>
                <span className='text-[#8A8A8A] text-xs text-center'>
                  {`Showing ${startIndex + 1} - ${endIndex} of ${numAllResources}`}
                </span>
                <div className='flex space-x-4'>
                  <div onClick={() => setCurrentResourcePage(prev => (prev > 1) ? prev - 1 : prev)}>
                    <span className={`text-[12px] ${(currentResourcePage > 1) ? 'visible cursor-pointer' : 'invisible'}`}>
                      <ArrowBackIosIcon style={{ fontSize: 10 }} />&nbsp;Previous
                    </span>
                  </div>
                  <div onClick={() => setCurrentResourcePage(prev => (prev + 1 <= Math.ceil(numAllResources / resourcesPerPage)) ? prev + 1 : prev)}>
                    <span className={`text-[12px] ${(currentResourcePage + 1 <= Math.ceil(numAllResources / resourcesPerPage)) ? 'visible cursor-pointer' : 'invisible'}`}>
                      Next&nbsp;<ArrowForwardIosIcon style={{ fontSize: 10 }} />
                    </span>
                  </div>
                </div>
              </div>
            ) : (<></>)
          }
          <div className="flex flex-col mx-auto w-full max-w-[1200px] bg-[#F5F5F5] rounded-xl px-4 py-6 sm:px-6 sm:py-8 space-y-6">
              {
                (
                  paginatedResources && paginatedResources.length > 0) ? paginatedResources.map((resource: ResourceSchema, i: number) => (
                    <div key={resource?.id ? resource?.id : i} className="w-full first:mt-0 last:mb-0">
                      <ResourceCard 
                        resourceType={resourceType} 
                        resourceIcon={ResourceIcon} 
                        resource={resource} 
                        searchFilterOptions={searchFilterOptions} 
                        toggleApplyModal={toggleApplyModal}
                        setApplyResource={setApplyResource}
                      />
                    </div>
                  )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm py-4">
                    No {resourceType} to show
                  </div>
                )
              }
          </div>
        </div>
      </div>

      <RequestKitchenModal isOpen={!!loggedinUsername && applyModal==="kitchen"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
      <RegisterTrainingModal isOpen={!!loggedinUsername && applyModal==="training"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ObtainDocumentationModal isOpen={!!loggedinUsername && applyModal==="documentation"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ApplyFundingModal isOpen={!!loggedinUsername && applyModal==="funding"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ScheduleExpertModal isOpen={!!loggedinUsername && applyModal==="expert"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <OrderInventoryModal isOpen={!!loggedinUsername && applyModal==="farm"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal}  />

      <FilterModal
        resources={filteredResources}
        isOpen={filterSetting}
        setIsOpen={setFilterSetting}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        closeAppModal={() => setFilterSetting(false)}
      />
      </>
    )
  }


}


export default ResourceListing
import { useEffect, useState} from 'react'
import moment from 'moment'
import { BrowserRouter as Router } from 'react-router-dom'
import UnAuthView from './UnAuth'
import AuthView from './Auth'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useCompleteSignIn, useLoadUserDetails } from '../hooks/authhooks'
import { useResource } from "../contexts/appcontext";
import LoadingScreen from '../components/LoadingScreen'
import { FilterType, SearchFilterOptionType } from "../data/types";
import Messages from '../components/Messages'
import { initializePendo } from '../utils/pendo';
import { getUIDFromToken } from '../utils/helpers';
// import { FilterType, SearchFilterOptionType } from "../data/types";
import config from '../config';


var appStage = config?.env


const HomeCookRouter = () => {
  const { user } = useCompleteSignIn()
  const { globalFilter } = useResource();
  const [searchTerm, changeSearchTerm] = useState<string>("");
  const [currentFilter, setCurrentFilter] = useState<FilterType>(globalFilter);
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const loggedin_username = user && user?.username ? user.username : "";

  const { reloadUser } = useLoadUserDetails(loggedin_username)

  useEffect(()=>{
    const checkUserStatus = async () => {
      setLoading(true); // Set loading to true when starting to reload user
      await reloadUser(); // Wait for the user state to be reloaded
      setLoading(false); // Set loading to false after the user is loaded
    };

    checkUserStatus();
    setCurrentFilter((user && user?.state)? {state: user.state, active: true} : globalFilter)
    
    var accountId = loggedin_username ? `${appStage}_resource_pantry_signedup_users` : `${appStage}_resource_pantry_anonymous_users`
    var visitorId = loggedin_username ? loggedin_username : localStorage.getItem("visitorId")
    
    if (!visitorId){
      var login_token: any = localStorage.getItem('login_token')
      var token_uid: string = getUIDFromToken(login_token)

      visitorId = (login_token && token_uid) ? token_uid : moment().format();
      accountId = (login_token && token_uid)  ? `${appStage}_resource_pantry_auth_users` : accountId

      localStorage.setItem("visitorId", visitorId)
    }

    initializePendo(visitorId, accountId);
    // eslint-disable-next-line
  }, [user, loggedin_username])

  const searchFilterOptions: SearchFilterOptionType = {
    searchTerm,
    currentFilter,
    signInModalIsOpen,
    changeSearchTerm,
    setCurrentFilter,
    setSignInModalIsOpen,
  }

  return (
    <Router basename="/">
        <Navbar searchFilterOptions={searchFilterOptions} loading={loading} />
        {loading? <LoadingScreen /> :
          (loggedin_username) ? ( 
            <AuthView searchFilterOptions={searchFilterOptions} /> 
          ) : (
            <UnAuthView searchFilterOptions={searchFilterOptions} />
          )
        }
        <Messages username={loggedin_username} searchFilterOptions={searchFilterOptions} />
        <Footer />
    </Router>
    )
}



export default HomeCookRouter;


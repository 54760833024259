import { useEffect, useState } from 'react'
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ReactComponent as PlanIcon }  from '../../icons/PlanIcon.svg'
import { ReactComponent as RequirementsIcon }  from '../../icons/RequirementsIcon.svg'
import { ReactComponent as LocationIcon }  from '../../icons/locationIcon.svg'
import { ReactComponent as HeartIcon } from "../../icons/HeartIcon.svg";
import { ReactComponent as HeartOutlineIcon } from "../../icons/HeartOutlineIcon.svg";
import { ReactComponent as BookingsIcon } from "../../icons/Bookings.svg";
import {  ResourceSchema, ResourceType, SearchFilterOptionType, ResourceBookingSchema, ResourceOptionType, PlanSchema, RequirementSchema, ResourceInventorySchema, InventorySchema, TrainingOrganizationSchema, TrainingSchema } from "../../data/types"
import { useLoadCurrentResource, useLoadResourceOptions } from '../../hooks/apphooks'
import { useAddBooking, useCompleteSignIn, useUpdateAccountDetails } from "../../hooks/authhooks";
import StarRating from "../../components/StarRating"
import NotFound from '../shared/notfound';
import { ApplyFundingModal, RegisterTrainingModal, ObtainDocumentationModal, RequestKitchenModal, OrderInventoryModal, ScheduleExpertModal } from "../../molecules/AppModal"

import { capitalizeString, isResourceSaved, getDirectLinkFromGoogleDriveSharedLink, getActionButtonLabel } from '../../utils/helpers';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import NewSearch from '../../components/NewSearch';


type RouteParams = { 
  resourceType: ResourceType
  resourceId: string
};


type ResourceDetailsProps = {
  searchFilterOptions: SearchFilterOptionType
}

const ResourceDetails: React.FC<ResourceDetailsProps> = ({searchFilterOptions}) => {
  var navigate = useNavigate();

  
  
  const { resourceType, resourceId } = useParams<RouteParams>()
  const [searchParams] = useSearchParams();
  const homeQuery = searchParams.get('home');

  const { setSignInModalIsOpen } = searchFilterOptions

  const { currentResource } = useLoadCurrentResource(resourceId ? resourceId : "")
  const { user } = useCompleteSignIn()
  const loggedInUsername = (user && user?.username) ? user.username : ''

  const [applyModal, toggleApplyModal] = useState<ResourceType | undefined>()

  useEffect(() => {
    document.title = `${capitalizeString(resourceType ? resourceType : '')} ${currentResource?.name ? `| ${currentResource?.name}` : ''}`
    // eslint-disable-next-line 
  }, [loggedInUsername]);

  const {getResourceIcon} = useLoadResourceOptions()
  const ResourceIcon = getResourceIcon(resourceType)

  const openInNewTab = (url: string) => {
    if (url){
      window.open(url, "_blank");
    }
  };

  const { saveResource, removeResource } = useUpdateAccountDetails(loggedInUsername)

  const handleSaveResource = () => {
    if (resourceId){
      if (!loggedInUsername){
        setSignInModalIsOpen(true)
      } else {
        saveResource(resourceId)
      }
    }
  }

  const handleRemoveSavedResource = () => {
    if (resourceId){
      removeResource(resourceId)
    }
  }

  // const resourceIsSaved = true
  const savedResources = user?.saved_resources ? user?.saved_resources : []
  var resourceIsSaved: any  = false
  if (resourceId){
     resourceIsSaved = isResourceSaved<ResourceSchema>(resourceId, savedResources)
  }
  

  var actionButtonLabel = getActionButtonLabel(resourceType as ResourceType)

const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
  setWidth(window.innerWidth);
}

useEffect(() => {
  document.title = `HomeCook | ${currentResource?.resource_type ? capitalizeString(currentResource?.resource_type) : 'Resource'} Listing`
  window.addEventListener("resize", handleWindowSizeChange);
  return () => {
    window.removeEventListener("resize", handleWindowSizeChange);
  };
}, [currentResource?.resource_type ]);

const { addNewBooking} = useAddBooking()


const handleSubmitRequest = (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => {
  if (loggedInUsername && resourceType && resource.id){
    const userAvatar = (user && user?.avatar) ? user.avatar : ''
    
    booking.resource_id = resource.id
    booking.resource_type = resourceType
    booking.username = loggedInUsername
    booking.user_full_name = `${user?.first_name} ${user?.last_name}`.trim()
    booking.user_avatar = userAvatar
    booking.resource_name = resource.name
    booking.resource_city = resource.city
    booking.resource_county = resource.county
    booking.resource_state = resource.state
    booking.resource_address = resource.address
    booking.resource_logo = resource.logo
    booking.resource_bgPhoto = resource.bgPhoto
    booking.community_id = resource.community_id
    addNewBooking(booking)
  }
  
}

const currentResourceOptions = currentResource?.resource_options as ResourceOptionType

const inventoryCatalogItems = (currentResource  && currentResource?.resource_inventory) ? currentResource.resource_inventory : {} as ResourceInventorySchema
const catalogOptions: string[] = Object.keys(inventoryCatalogItems)
const catalogOption: string = catalogOptions ? catalogOptions[0] : ""
const availableItems = inventoryCatalogItems[catalogOption]


const trainingOrganizations = (currentResource  && currentResource?.resource_inventory) ? currentResource.resource_inventory :  {} as TrainingOrganizationSchema
const organizations: string[] = Object.keys(trainingOrganizations)
const trainingOrganization: string = organizations ? organizations[0] : ""
const availableDates = trainingOrganizations[trainingOrganization]

if (availableDates){
  availableDates.sort((a, b) => {
    const dateA = moment(a.date);
    const dateB = moment(b.date);
    return dateA.diff(dateB);
  });
}


type detailsMenuNames = "description" | "plans" | "requirements" | "hours" | "inventory" | "reviews" | "schedules" | "amount"
type resourceDetailsMenuType = {
  name: detailsMenuNames
  label: string
}
var resourceDetailsMenu: resourceDetailsMenuType[] = [
  {
    name: "description",
    label: "Description"
  }
]

if (currentResource?.resource_type && ["kitchen", "farm"].includes(currentResource?.resource_type)){

  resourceDetailsMenu = [
    ...resourceDetailsMenu,
    {
      name: "hours",
      label: "Operating Hours"
    },
    {
      name: "inventory",
      label: "Inventory"
    }
  ]

  if (currentResource?.resource_type === "kitchen"){
    resourceDetailsMenu.push(
      {
        name: "plans",
        label: "Booking Plans"
      },
      {
        name: "requirements",
        label: "Requirements"
      }
    )
  }
  resourceDetailsMenu.push(
    {
      name: "reviews",
      label: "Reviews"
    }
  )

} else if (currentResource?.resource_type && ["training", "expert"].includes(currentResource?.resource_type)){
  resourceDetailsMenu = [
    ...resourceDetailsMenu,
    {
      name: "schedules",
      label: "Schedules"
    },
    {
      name: "reviews",
      label: "Reviews"
    }
  ]

}

const [currentDetailsMenu, setcurrentDetailsMenu] = useState<detailsMenuNames>("description")

const isMobile = width < 1024;

if (!currentResource){
  return <NotFound dataName="resource"  />
} else if (currentResource && !currentResource?.name){
  return (
      <div
        role="status"
        className="grid items-center justify-center h-screen md:p-[1%] w-full bg-back-400 pt-16 md:pt-16"
      >
        <svg
          aria-hidden="true"
          className="w-28 h-28 mr-2 text-gray-200 animate-spin  fill-primaryDark-400"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
  else if (isMobile && currentResource){
    return (
      <>
        <div className={`flex mx-auto w-full p-4 h-${currentResource && currentResource.description ? `[${currentResource.description.length * 3.7}px]` : 'screen'} bg-[#FFFFFF] font-work-sans`}>
            <div className='font-raleway flex flex-col w-full relative items-start mt-[180px] '>
                <>
                    {
                      (homeQuery || !resourceType) ? (
                        <></>
                      ) : (
                          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] text-[13px] font-bold mb-4 cursor-pointer" onClick={() => navigate(-1)}><ArrowBackIosIcon style={{fontSize: 13}}  />Back</span>
                      )
                    }
                    <div className='flex flex-col w-11/12 mx-auto'>
                      <div className='flex flex-row'>
                        {
                            getDirectLinkFromGoogleDriveSharedLink(currentResource?.logo) ?
                            ( 
                            <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md'>
                              <img className='h-10 aspect-auto object-contain' alt={getDirectLinkFromGoogleDriveSharedLink(currentResource.logo)} src={getDirectLinkFromGoogleDriveSharedLink(currentResource.logo)} />
                            </div>
                            ) : (
                              <div className='p-2 border-[#E9E9E9] border-[1px] rounded-md'>
                                <ResourceIcon className={'relative m-1 mx-auto w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                              </div>
                            )
                        }

                        <div className='flex flex-row my-auto text-[14px] font-semibold ml-auto '>
                          {
                            (!resourceIsSaved)? (
                              <div className='flex flex-row text-black px-3 cursor-pointer border-[#E9E9E9] border-[1px] p-2 rounded-lg' onClick={handleSaveResource}>
                                <HeartOutlineIcon className='text-black w-[16px] h-[15px] my-auto' />
                                <p className='mx-2 my-auto'>Save</p>
                              </div>
                            ) : (
                              <div className='flex flex-row text-black px-3 cursor-pointer border-[#E9E9E9] border-[1px] p-2 rounded-lg' onClick={handleRemoveSavedResource}>
                                <HeartIcon className='text-black w-[16px] h-[15px] my-auto' />
                                <p className='mx-2 my-auto'>Saved</p>
                              </div>
                            )
                          }
                        </div>
                      </div>

                      <div className='flex flex-col w-full'> 
                        <p className='flex w-full text-left mt-2 my-auto font-semibold text-[20px] text-[#000]'>
                          {currentResource ? `${currentResource.name}` : null}
                        </p>
                        {
                          currentResource?.rating ? (
                            <div className='flex flex-col my-1 sm:mx-4 mx-auto items-center w-full sm:w-auto'>
                              <li
                                className="grid grid-flow-col justify-start text-primaryDark-200 text-sm font-open-sans"
                              >
                                <div className='flex flex-row flex-start mx-2 mt-1 mb-2'>
                                  <StarRating review_rating={currentResource.rating} /> &nbsp; (1)
                                </div>
                              </li>
                            </div> ) : (<></>)
                        }
                        
                        {/* <span className='font-raleway w-full flex flex-col sm:mx-1 mx-auto p-1 items-center'> */}
                        <div className='flex flex-row text-left text-[#8A8A8A] text-[14px]'>
                          <LocationIcon className='w-[10px] h-[14px] m-1 text-[#8A8A8A]' /> &nbsp;
                          {currentResource && currentResource.city ? `${currentResource.city}, `: ``} 
                          {currentResource && currentResource.county ? `${currentResource.county}, `: ``} 
                          {currentResource && currentResource.state ? `${currentResource.state} `: ``}  
                          {currentResource && currentResource.zipcode ? `${currentResource.zipcode} `: ``}  
                          {
                            (
                              !currentResource.city && !currentResource.county && !currentResource.state && !currentResource.zipcode
                            ) ? 'Nationwide' : ''
                          }
                        </div>
                        {/* </span> */}
                      </div>
                    </div>
                      

                    <div className='flex flex-row justify-evenly w-11/12 mx-auto h-[300px] my-2'>
                        {
                                getDirectLinkFromGoogleDriveSharedLink(currentResource?.bgPhoto) ? (
                                  <img className='object-cover w-full' src={getDirectLinkFromGoogleDriveSharedLink(currentResource?.bgPhoto)} alt={currentResource?.name} /> 
                                ) : (
                                    <>
                                      <div className='h-[280px] w-full rounded-md m-1  bg-[#D9D9D9]/70' ></div>
                                    </>
                                )
                          }
                    </div>
                    
                    <div className='flex flex-col w-full m-1'>
                        <div className='col-span-2 w-full'>
                            <div className='flex flex-col mt-4 font-raleway w-11/12 mx-auto'>
                                <p className='font-semibold text-[#000000]  text-[16px] bg-[#F3F3F3] w-[110px] border-[#E9E9E9] border-[1px] rounded-lg p-2 mb-4'>Description</p>
                                <div className='text-[#000] text-[16px] border-[#E9E9E9] border-[1px] rounded-lg p-6'>
                                      {
                                        (currentResource && currentResource.description) ? (
                                          <p>{currentResource.description}</p>
                                        ) : (<></>)
                                      }

                                    </div>

                                    <button className='flex rounded-md flex-row my-2 justify-center mt-8 border-[#E9E9E9] border-[1px] px-3 text-[#013847] font-bold cursor-pointer h-[40px] text-center' onClick={() => openInNewTab(currentResource?.website ? currentResource.website : '')}>Visit Website</button>

                                    {
                                      (currentResource && currentResource.active && actionButtonLabel) ? (
                                        <button 
                                          onClick={()=> {
                                            if (!loggedInUsername){
                                                setSignInModalIsOpen(true)
                                            }
                                            toggleApplyModal(resourceType)

                                          }} 
                                          className='bg-primaryDark-0 text-white text-[16px] font-extrabold h-[40px] rounded-md  border-0'>
                                            {actionButtonLabel}
                                        </button>
                                      ) : (
                                        <></>
                                      )
                                    }
                            </div>
                            {/* {
                              (currentResource.resource_type === "kitchen") ? (
                                <></>
                              ) : (
                                <></>
                              )
                            } */}
                        </div>
                    </div>
                </>
            </div>
        </div>

        <RequestKitchenModal isOpen={!!loggedInUsername && applyModal==="kitchen"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <RegisterTrainingModal isOpen={!!loggedInUsername && applyModal==="training"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ObtainDocumentationModal isOpen={!!loggedInUsername && applyModal==="documentation"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ApplyFundingModal isOpen={!!loggedInUsername && applyModal==="funding"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ScheduleExpertModal isOpen={!!loggedInUsername && applyModal==="expert"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <OrderInventoryModal isOpen={!!loggedInUsername && applyModal==="farm"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)}  />
      </>
    )
  }
  else {
    return (
      <>
        <div className='flex mx-auto w-full p-4 h-auto bg-[#FFFFFF] font-work-sans'>
            <div className='font-raleway flex flex-col w-full relative items-start my-14'>
                <>
                    {
                      (homeQuery || !resourceType) ? (
                        <></>
                      ) : (
                          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] text-[14px] font-bold mb-4 cursor-pointer" onClick={() => navigate(-1)}><ArrowBackIosIcon />Back</span>
                      )
                    }
                    <div className='flex flex-row w-full'>
                      {
                          getDirectLinkFromGoogleDriveSharedLink(currentResource?.logo) ?
                          ( 
                          <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md'>
                            <img className='h-10 aspect-auto object-contain' alt={getDirectLinkFromGoogleDriveSharedLink(currentResource.logo)} src={getDirectLinkFromGoogleDriveSharedLink(currentResource.logo)} />
                          </div>
                          ) : (
                            <div className='p-2 border-[#E9E9E9] border-[1px] rounded-md'>
                              <ResourceIcon className={'relative m-1 mx-auto w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                            </div>
                          )
                      }
                      <div className='flex flex-col w-full sm:w-auto text-[20px] text-[#000] '> 
                        <p className='mx-2 sm:text-left text-center mt-2 sm:mt-auto my-auto font-semibold'>{currentResource ? `${currentResource.name}` : null}</p>
                      
                      
                      
                        {
                          currentResource?.rating ? (
                            <div className='flex flex-col my-1 sm:mx-4 mx-auto items-center w-full sm:w-auto'>
                              <li
                                className="grid grid-flow-col justify-start text-primaryDark-200 text-sm font-open-sans"
                              >
                                <div className='flex flex-row flex-start mx-2 mt-1 mb-2'>
                                  <StarRating review_rating={currentResource.rating} /> &nbsp; (1)
                                </div>
                              </li>
                            </div> ) : (<></>)
                        }
                        
                        <span className='font-raleway w-full sm:w-auto font-medium flex flex-col sm:flex-row sm:mx-1 mx-auto p-1 items-center'>
                          <div className='flex flex-row text-center text-[#8A8A8A] text-[14px]'>
                            <LocationIcon className='w-[10px] h-[14px] m-1 text-[#8A8A8A]' /> &nbsp;
                            {currentResource && currentResource.city ? `${currentResource.city}, `: ``} 
                            {currentResource && currentResource.county ? `${currentResource.county}, `: ``} 
                            {currentResource && currentResource.state ? `${currentResource.state} `: ``}  
                            {currentResource && currentResource.zipcode ? `${currentResource.zipcode} `: ``}  
                            {
                              (
                                !currentResource.city && !currentResource.county && !currentResource.state && !currentResource.zipcode
                              ) ? 'Nationwide' : ''
                            }
                          </div>
                        </span>
                      </div>
                      <div className='flex flex-row my-auto text-[14px] font-semibold ml-auto '>
                        {
                          (!resourceIsSaved)? (
                            <div className='flex flex-row text-black px-3 cursor-pointer' onClick={handleSaveResource}>
                              <HeartOutlineIcon className='text-black w-[16px] h-[15px] my-auto' />
                              <p className='mx-2 my-auto'>Save</p>
                            </div>
                          ) : (
                            <div className='flex flex-row text-black px-3 cursor-pointer' onClick={handleRemoveSavedResource}>
                              <HeartIcon className='text-black w-[16px] h-[15px] my-auto' />
                              <p className='mx-2 my-auto'>Saved</p>
                            </div>
                          )
                        }
                        <button className='flex rounded-md flex-row  border-[#E9E9E9] border-[1px] px-3 text-[#013847] hover:text-gray-400 cursor-pointer ml-auto' onClick={() => openInNewTab(currentResource?.website ? currentResource.website : '')}>Visit {currentResource ? `${currentResource.name}` : null}</button>
                      </div>
                    </div>
                      

                    <div className='flex flex-row justify-evenly w-full h-[300px] my-2'>
                        {
                                getDirectLinkFromGoogleDriveSharedLink(currentResource?.bgPhoto) ? (
                                  <img className='object-cover w-full' src={getDirectLinkFromGoogleDriveSharedLink(currentResource?.bgPhoto)} alt={currentResource?.name} /> 
                                ) : (
                                    <>
                                      <div className='h-[280px] w-full rounded-md m-1  bg-[#D9D9D9]/70' ></div>
                                    </>
                                )
                          }
                    </div>
                    
                    <div className='flex flex-col sm:flex-row w-full m-1'>
                        <div className='flex flex-col w-full'>
                          {
                            ((currentResource?.resource_type && ["kitchen", "farm", "training", "expert"].includes(currentResource?.resource_type))) ? (
                              <div className={`flex flex-row mt-2 font-raleway 'w-full`}>
                                {
                                  resourceDetailsMenu.map((menuOption, i)=>(
                                    <div 
                                      key={i} 
                                      className={`${(currentDetailsMenu === menuOption.name) ? 'bg-[#F3F3F3] text-[#000000] font-bold' : 'text-[#4D4D4D] cursor-pointer hover:bg-[#F3F3F3]'} text-center  text-[16px]  w-[158px] h-[43px] border-[#E9E9E9] border-[1px] rounded-lg p-2 my-auto mr-6`}
                                      onClick={()=>setcurrentDetailsMenu(menuOption.name)}
                                    >
                                        {capitalizeString(menuOption.label)}
                                    </div>
                                  ))
                                }
                              </div>
                            ) : (
                              <div 
                                className={`bg-[#F3F3F3] text-[#000000] font-semibold text-center  text-[16px]  w-[158px] h-[43px] border-[#E9E9E9] border-[1px] rounded-lg p-2 my-auto mr-6`}
                      
                              >
                                  Description
                              </div>
                            )
                          }

                          <hr className='w-full mt-3 border-[#E9E9E9]' />
                            
                          {
                            (currentDetailsMenu === "description") && (
                              <div className={`flex flex-col font-open-sans w-full h-auto`}>
                                <div className={`text-[#000] text-[16px] rounded-lg h-auto`}>
                                  {
                                    (currentResource && currentResource.description) ? (
                                      <p className='mt-2 p-2'>{capitalizeString(currentResource.description)}</p>
                                    ) : (
                                      <div className={`flex flex-col text-[14px] text-[#aaa] w-full mt-2 font-raleway`}>
                                        No description to show
                                      </div>
                                  )
                                  }
                                </div>
                              </div>
                            )
                          }

                          {
                            (currentDetailsMenu === "description" && ["documentation", "training", "expert"].includes(resourceType as ResourceType) && currentResource?.total_amount) && (
                              <div className={`flex flex-col w-full h-auto`}>
                                <div className={`flex flex-row ${(currentResource?.description) ? 'text-[#333] text-[16px] font-open-sans p-2' : 'text-[14px] text-[#aaa] font-raleway mt-2'} h-auto `}>
                                  <p>{resourceType ? capitalizeString(resourceType) : 'Resource'} cost:</p>
                                  <p className='font-semibold text-[15px]'>&nbsp;${currentResource?.total_amount?.toFixed(2)}</p>
                                </div>
                              </div>
                            )
                          }

                          {
                            (currentDetailsMenu === "description" && resourceType === "funding" && currentResource?.total_amount) && (
                              <div className={`flex flex-col font-open-sans w-full h-auto p-2`}>
                                <div className="flex flex-row text-[#333] text-[16px] rounded-lg h-auto ">
                                  <p className='my-auto'>{resourceType ? capitalizeString(resourceType) : 'Resource'} amount:</p>
                                  <p className='font-semibold text-[15px] my-auto'>&nbsp;${currentResource?.total_amount?.toFixed(2)}</p>
                                </div>
                              </div>
                            )
                          }

                          {
                            ((["farm", "kitchen"].includes(currentResource.resource_type as string))) ? (
                            <>
                              {
                                (currentDetailsMenu === "hours") && (
                                  <OperatingHoursGrid currentResourceOptions={currentResourceOptions} />
                                )
                              } 
                              {
                                (currentDetailsMenu === "inventory") && (
                                  <ResourceInventoryGrid availableItems={availableItems as InventorySchema[]} />
                                )
                              }

                              {
                                (currentDetailsMenu === "reviews") && (
                                  <ReviewsGrid reviews={[]} />
                                )
                              }
                            </>
                            ) : (<></>)
                          }  

                          {
                            ((["training", "expert"].includes(currentResource.resource_type as string))) ? (
                            <>
                              {
                                (currentDetailsMenu === "schedules") && (
                                  <ResourceScheduleGrid availableDates={availableDates as TrainingSchema[]} />
                                )
                              }
                              {
                                (currentDetailsMenu === "reviews") && (
                                  <ReviewsGrid reviews={[]} />
                                )
                              }
                            </>
                            ) : (<></>)
                          } 

                          {
                            ((currentResource.resource_type === "kitchen")) ? (
                              <>
                                {
                                  (currentDetailsMenu === "plans") && (
                                    <BookingPlanGrid currentResource={currentResource} />
                                  )
                                }

                                {
                                  (currentDetailsMenu === "requirements") && (
                                   <RequirementsGrid loggedInUsername={loggedInUsername} currentResource={currentResource} setSignInModalIsOpen={setSignInModalIsOpen} />
                                  )
                                }                   
                              </>
                            ) : (<></>)
                          }  

                          <div className='flex ml-auto mt-[24px] mx-[24px]'>
                          {
                            (currentResource && currentResource.active && actionButtonLabel) ? (
                              <button 
                                onClick={()=> {
                                  if (!loggedInUsername){
                                      setSignInModalIsOpen(true)
                                  }
                                  toggleApplyModal(resourceType)

                                }} 
                                className='bg-primaryDark-0 text-white text-[16px] font-bold w-[200px] h-[40px] rounded-md'>
                                  {actionButtonLabel}
                              </button>
                            ) : (
                              <></>
                            )
                          }     
                          </div>
                        </div>
                    </div>
                </>
            </div>
        </div>

        
        <RequestKitchenModal isOpen={!!loggedInUsername && applyModal==="kitchen"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <RegisterTrainingModal isOpen={!!loggedInUsername && applyModal==="training"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ObtainDocumentationModal isOpen={!!loggedInUsername && applyModal==="documentation"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ApplyFundingModal isOpen={!!loggedInUsername && applyModal==="funding"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <ScheduleExpertModal isOpen={!!loggedInUsername && applyModal==="expert"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
        <OrderInventoryModal isOpen={!!loggedInUsername && applyModal==="farm"} resourceDetail={currentResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)}  />
      </>
    )
  }
}


export default ResourceDetails


type BookingPlanGridProps = {
  currentResource: ResourceSchema
}

const BookingPlanGrid: React.FC<BookingPlanGridProps> = ({currentResource}) => {

  const [currentFilter, setCurrentFilter] = useState<string>("All")

  return (currentResource?.plans && currentResource?.plans?.length > 0) ? (
    <div className={`flex flex-col my-4 font-open-sans w-full h-auto`}>
      <div className='mb-4 font-raleway text-[14px] font-semibold'>
        {
          ["All", "Hourly", "Daily", "Monthly"].map((filter, i) => (
            <span key={i} onClick={()=>setCurrentFilter(filter)} className={`mr-2 ${(filter === currentFilter) ? 'text-primaryDark-300' : 'text-[#808080] cursor-pointer hover:text-primaryDark-400'} `} >
              {filter} &nbsp;
            </span>
          ))
        }
      </div>
      <div className='flex flex-row text-black rounded-lg h-auto'>
        {
           currentResource.plans.map((plan, i) => (
            <div key={i} className='w-1/3 mr-2'>
              <BookingPlanCard plan={plan} />
            </div>
          ))
        }
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No plan to show</div>
  )
} 


type BookingPlanCardProps = {
  plan: PlanSchema
}

const BookingPlanCard: React.FC<BookingPlanCardProps> = ({plan}) => {
  return (
    <div className='flex flex-row rounded-lg border-[1px] border-[#E9E9E9] p-2'>
      <div className='my-auto m-2'><PlanIcon /></div>
      <div className='flex flex-col my-auto m-2'>
          <p className='text-[18px] font-semibold text-black font-raleway'>{plan.plan_name}</p>
          <p className='text-[16px] text-[#808080] font-raleway'>${plan.plan_rate} {capitalizeString(plan?.plan_recurrence ? plan?.plan_recurrence : "")}</p>
      </div>
    </div>
  )
} 


type RequirementsGridProps = {
  currentResource: ResourceSchema
  loggedInUsername: string
  setSignInModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RequirementsGrid: React.FC<RequirementsGridProps> = ({currentResource, loggedInUsername, setSignInModalIsOpen}) => {

  const [currentFilter, setCurrentFilter] = useState<string>("All")

  return (currentResource?.requirements && currentResource?.requirements?.length > 0) ? (
    <div className={`flex flex-col my-4 font-open-sans w-full h-auto`}>
      {
        (loggedInUsername) && (<div className='mb-4 font-raleway text-[14px] font-semibold'>
          {
            ["All", "Completed", "To Do"].map((filter, i) => (
              <span key={i} onClick={()=>setCurrentFilter(filter)} className={`mr-2 ${(filter === currentFilter) ? 'text-primaryDark-300' : 'text-[#808080] cursor-pointer hover:text-primaryDark-400'} `} >
                {filter} &nbsp;
              </span>
            ))
          }
        </div>)
      }
      <div className='flex flex-row text-black rounded-lg h-auto'>
        {
          currentResource.requirements.map((requirement, i) => (
            <div key={i} className='w-1/3 mr-2'>
              <RequirementCard 
                requirement={requirement} 
                loggedInUsername={loggedInUsername}
                setSignInModalIsOpen={setSignInModalIsOpen}
              />
            </div>
          ))
        }
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No requirement to show</div>
  )
} 



type RequirementCardProps = {
  requirement: RequirementSchema
  loggedInUsername: string
  setSignInModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const RequirementCard: React.FC<RequirementCardProps> = ({requirement, loggedInUsername, setSignInModalIsOpen}) => {
  const navigate = useNavigate()
  const handleFindDocumentation = () => {
    navigate("/documentation")
  }

  const handleUploadDocumentation = () => {
    if (!loggedInUsername){
      setSignInModalIsOpen(true)
    } else {
      navigate("/profile")
    }
  }

  return (
    <div className='flex flex-col rounded-lg border-[1px] border-[#E9E9E9] p-2'>
      <div className='flex flex-row w-full m-2 my-2'>
        <div className='flex bg-[#FAFAFA] h-[48px] w-[48px] rounded-md border-[#E9E9E9] border-[1px]'>
          <RequirementsIcon className='text-[#5DB6CE] m-auto' />
        </div>
        <CheckBoxOutlineBlank className='m-2 ml-auto text-[#CFCFCF] my-auto'/>
      </div>
      <div className='flex flex-col my-auto w-full m-2'>
          <p className='text-[18px] font-semibold text-black font-raleway'>{requirement.name}</p>
          <div className='flex flex-row w-full my-4'>
            <button onClick={handleFindDocumentation} className='hover:bg-[#fcfcfc] rounded-lg border-[#E9E9E9] border-[1px] text-primaryDark-100 text-[16px] font-raleway font-semibold w-1/2'>How to get this?</button>
            <button onClick={handleUploadDocumentation} className='rounded-lg bg-primaryDark-100 text-white text-[16px] font-raleway font-semibold mx-3 w-1/2'>Upload</button>
          </div>
      </div>
    </div>
  )
} 



type ResourceInventoryGridProps = {
  availableItems: InventorySchema[]
}

const ResourceInventoryGrid: React.FC<ResourceInventoryGridProps> = ({availableItems}) => {
  const [searchTerm, changeSearchTerm] = useState<string>("")

  return  (availableItems && availableItems?.length > 0) ? (
    <div className={`flex flex-col my-4 font-open-sans w-full h-auto`}>
      <div className='mb-4 font-raleway'>
        <NewSearch 
          searchType="inventory" 
          searchTerm={searchTerm}
          filterSetting={false}
          changeSearchTerm={changeSearchTerm}
          toggleFilterSetting={()=>null}
        />
      </div>
      <div className='flex flex-row text-black rounded-lg h-auto'>
        {
          availableItems.map((inventory, i) => (
            <div key={i} className='mr-4'>
              <ResourceInventoryCard inventory={inventory as InventorySchema} />
            </div>
          ))
        }
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No inventory to show</div>
  )
} 


type ResourceInventoryCardProps = {
  inventory: InventorySchema
}

const ResourceInventoryCard: React.FC<ResourceInventoryCardProps> = ({inventory}) => {
  return (
    
    <div className='flex flex-col p-2 border-[#E9E9E9] border-[1px] w-[200px] h-[150px] rounded-xl'>      
      {
        (inventory?.image_url) ? (
          <img className='h-[80px] rounded-t-xl object-contain' src={inventory.image_url} alt={inventory.item_name} />
        ) : (
          <div className='h-[80px] rounded-t-xl bg-[#eee]' />
        )
      }
      <div className='mx-auto w-11/12 '>
        <p className='font-bold font-raleway text-black'>
          {inventory.item_name}
        </p>
        <div className='flex flex-row w-full'>
          <span className='text-[14px] text-black font-open-sans mr-auto font-semibold'>
            ${inventory.unit_price} per {inventory.measure_unit} 
          </span>
          <span className='text-[14px] text-[#4D4D4D] font-open-sans ml-auto'>
            {inventory.available_quantity} units left
          </span>
        </div>
      </div>
    </div>
  )
} 



type ResourceScheduleGridProps = {
  availableDates: TrainingSchema[]
}

const ResourceScheduleGrid: React.FC<ResourceScheduleGridProps> = ({availableDates}) => {
  return (availableDates && availableDates?.length > 0) ? (
    <div className={`flex flex-col my-4 font-open-sans w-full h-auto`}>
      <div className='flex flex-row text-black rounded-lg h-auto'>
        {
          availableDates.map((date, i) => (
            <div key={i} className='mr-4'>
              <ResourceScheduleCard availableDate={date} />
            </div>
          ))
        }
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No schedule to show</div>
  )
} 


type ResourceScheduleCardProps = {
  availableDate: TrainingSchema
}

const ResourceScheduleCard: React.FC<ResourceScheduleCardProps> = ({availableDate}) => {

  if (availableDate?.times) {
    availableDate.times.sort((a, b) => {
      const timeA = moment(a, 'HH:mm');
      const timeB = moment(b, 'HH:mm');
      return timeA.diff(timeB);
    });
  }

  const availableDay = moment(availableDate.date).format('ddd MMM DD, YYYY')
  const availableTimes = availableDate?.times

  const isAvailableDayAfterToday = (availableDay: string): boolean => {
    const today = moment().startOf('day');
    const day = moment(availableDay).startOf('day');
    return day.isAfter(today);
  };

  const isPast = !isAvailableDayAfterToday(availableDay);

  return (
    <div className={`flex flex-row h-full rounded-lg border-[1px]  p-2 ${isPast ? 'bg-red-50 border-red-100' : 'border-[#E9E9E9]'}`}>
      <div className='my-auto m-2 text-primaryDark-300 h-1/2'>
        <BookingsIcon style={{height: 30}} />
      </div>
      <div className='flex flex-col my-auto m-2 h-full'>
          <p className={`text-[16px] font-semibold font-raleway ${isPast ? 'text-red-900' : ''}`}>{availableDay}</p>
          {
            availableTimes.map((time, i) => (
                <p key={i} className={`text-[16px] font-raleway ${isPast ? 'text-red-300' : 'text-[#808080]'}`}>{moment(time, 'HH:mm').format('hh:mm a')}</p>
              )
            )
          }
      </div>
    </div>
  )
} 



type OperatingHoursGridProps = {
  currentResourceOptions: ResourceOptionType
}

const OperatingHoursGrid: React.FC<OperatingHoursGridProps> = ({currentResourceOptions}) => {
  return (Object.keys(currentResourceOptions).length > 1) ? (
    <div className={`flex flex-col my-4 font-raleway w-full h-auto`}>
      <div className='text-[#000] text-[16px] rounded-lg h-auto'>
        <div className="flex flex-row w-full text-[#555] font-open-sans">
          <div className="flex flex-col text-md font-bold p-2">
            <span className="my-2">Sun &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Mon &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Tue &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Wed &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Thu &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Fri &nbsp; &nbsp; &nbsp; </span>
            <span className="my-2">Sat &nbsp; &nbsp; &nbsp; </span>
          </div>
          <div className="flex flex-col text-md font-bold p-2">
            {
              (currentResourceOptions && currentResourceOptions?.sun) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.sun?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.sun?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

            {
              (currentResourceOptions && currentResourceOptions?.mon) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.mon?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.mon?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

            {
              (currentResourceOptions && currentResourceOptions?.tue) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.tue?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.tue?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

            {
              (currentResourceOptions && currentResourceOptions?.tue) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.wed?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.wed?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

            {
              (currentResourceOptions && currentResourceOptions?.thu) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.thu?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.thu?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

            {
              (currentResourceOptions && currentResourceOptions?.thu) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.fri?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.fri?.close_time}
                &nbsp; 
              </span>):(<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }

          {
              (currentResourceOptions && currentResourceOptions?.sat) ? (<span className="text-left font-normal my-2 text-green-900">
                {currentResourceOptions?.sat?.open_time}
                &nbsp; - &nbsp; 
                {currentResourceOptions?.sat?.close_time}
                &nbsp; 
              </span>) : (<div className="text-left font-normal my-2 text-red-900">CLOSED</div>)
            }
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No hours to show</div>
  )
} 




type ReviewsGridProps = {
  reviews: any[]
}

const ReviewsGrid: React.FC<ReviewsGridProps> = ({reviews}) => {

  const [currentFilter, setCurrentFilter] = useState<string>("All")

  return (reviews && reviews?.length > 0) ? (
    <div className={`flex flex-col my-4 font-open-sans w-full h-auto`}>
      <div className='mb-4 font-raleway text-[14px] font-semibold'>
        {
          ["All"].map((filter, i) => (
            <span key={i} onClick={()=>setCurrentFilter(filter)} className={`mr-2 ${(filter === currentFilter) ? 'text-primaryDark-300' : 'text-[#808080] cursor-pointer hover:text-primaryDark-400'} `} >
              {filter} &nbsp;
            </span>
          ))
        }
      </div>
      <div className='flex flex-row text-black rounded-lg h-auto'>
        {
           reviews.map((review, i) => (
            <div key={i} className='w-1/3 mr-2'>
              {review}
            </div>
          ))
        }
      </div>
    </div>
  ) : (
    <div className={`flex flex-col text-[14px] text-[#aaa] my-4 w-full h-auto font-raleway`}>No review to show</div>
  )
} 